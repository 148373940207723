import AbstractService from './abstractservice';
import config from '../configuration';
import { MachineCardFull, MachineCardShort } from '../pages/fleet/home/types';
import { Error } from '../pages/fleet/details/types';
import { MachineCardRegister } from '../pages/register/reducer';

export default class RegisterService extends AbstractService {
  constructor(bearerToken: string) {
    super(config.runtime.urls.apiBaseUrl, 'application/json', bearerToken);
  }

  searchCards(search, lang: string): Promise<MachineCardRegister[]> {
    return this.post(`cards/search/${window.localStorage.lang}`, null, { search }, undefined).then(
      (r) => r as unknown as MachineCardRegister[]
    );
  }

  getMyProducts(lang: string): Promise<MachineCardShort[]> {
    return this.get(`cards/my/${window.localStorage.lang}`, undefined, undefined, undefined).then(
      (r) => r as unknown as MachineCardShort[]
    );
  }

  getCard(id, lang): Promise<MachineCardFull> {
    return this.get(
      `cards/${id}/${window.localStorage.lang}`,
      undefined,
      undefined,
      undefined
    ).then((r) => r as unknown as MachineCardFull);
  }

  updateCardFile(id, file): Promise<Error> {
    return this.postfile(`cards/${id}/files`, null, null, file).then(
      (r) => r as unknown as Error
    );
  }
}
