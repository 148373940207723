import React, { useContext } from 'react'
import { CmsComponent, CmsDynamicDataProvider, CmsStaticDataProvider } from 'crownpeak-dxm-react-sdk';
import FooterRouting from '../../core/footerRouting';
import UserContext from '../../providers/user-provider';
import { Link } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react';
import Routing from '../../core/routing';
export default class FooterNavigation extends CmsComponent {
    site_nav_items: any;
    contact_nav_items: any;
    info_nav_items: any;
    social_nav_items: any;
    bottom_nav_items: any;
    right_nav_items: any;
    lower_footer_nav_items: any;
    footer_logo: any;
    footer_logo_link: any;
    assetId: any;
    jsonData: any;
    mobile_top_nav_items: any;
    site_nav_header: any;
    contact_header: any;
    social_media_header: any;
    constructor(props) {
        super(props);
        this.cmsFolder = "Global";
        this.cmsDisableDragDrop = true;
        this.assetId = FooterRouting.getLanguage(localStorage.getItem('lang') || Routing.languages.filter(item => item === window.location.pathname.split('/')[1])[0] || 'en-ww');


        this.site_nav_items = [];
        this.contact_nav_items = [];
        this.info_nav_items = [];
        this.social_nav_items = [];
        this.bottom_nav_items = [];
        this.right_nav_items = [];
        this.lower_footer_nav_items = [];
        this.mobile_top_nav_items = [];


        this.footer_logo = '';
        this.footer_logo_link = '';
        this.site_nav_header = '';
        this.contact_header = '';
        this.social_media_header = '';


        if (this.props.dataProvider instanceof CmsStaticDataProvider) {
            this.jsonData = new CmsStaticDataProvider()?.getCustomDataSync(`${this.assetId}.json`).FooterNavigationAdmin;
        }
        else {
            this.jsonData = new CmsDynamicDataProvider()?.getDynamicQuerySync(`q=${this.assetId}&fl=custom_t_json:[json]`).response.docs[0].custom_t_json.FooterNavigationAdmin;
        }

        this.site_nav_items = this.jsonData.SiteNavItems;
        this.contact_nav_items = this.jsonData.ContactNavItems;
        this.info_nav_items = this.jsonData.InfoNavItems;
        this.social_nav_items = this.jsonData.SocialNavItems;
        this.bottom_nav_items = this.jsonData.BottomNavItems;
        this.right_nav_items = this.jsonData.RightNavItems;
        this.mobile_top_nav_items = this.jsonData.MobileTopNavItems;
        this.lower_footer_nav_items = this.jsonData.LowerFooterNavItems;
        this.footer_logo = this.jsonData.Footer_Logo;
        this.footer_logo_link = this.jsonData.Footer_Logo_Link;
        this.site_nav_header = this.jsonData.Site_Nav_Header;
        this.contact_header = this.jsonData.Contact_Header;
        this.social_media_header = this.jsonData.Social_Media_Header;

    }

    render() {
        let i = 0;

        return (

            <div>
                {/* cp-scaffold
                        {footerNavigationPreview:FooterNavigationPreview}
                    else */}
                <footer id="footer" className="footer">


                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="footer-top-container">
                                    <div className='footer-logo'>
                                        {this.footer_logo_link.includes('https') ?
                                            <a href={this.footer_logo_link} target="_blank">
                                                <img src={this.footer_logo} className="footer-kvg-logo" />
                                            </a>
                                            : <Link to={this.footer_logo_link.value}>
                                                <img src={this.footer_logo} className="footer-kvg-logo" />
                                            </Link>}
                                    </div>
                                    {this.mobile_top_nav_items?.map((item, index) => {
                                        return (
                                            item.FooterMobArrowLink.Url.includes('https') ?
                                                <a className="footer-top-container_header footer-mob-arrow-link d-lg-none" href={item.FooterMobArrowLink.Url} role="menuitem" target="_blank" key={'right_nav_items' + index}>{item.FooterMobArrowLink.Title} <span className="bg-white round-arrow"><i className="arrow right "></i></span></a>
                                                : <Link className="footer-top-container_header footer-mob-arrow-link d-lg-none" to={item.FooterMobArrowLink.Url} role="menuitem" key={'right_nav_items' + index}>{item.FooterMobArrowLink.Title} <span className="bg-white round-arrow"><i className="arrow right "></i></span></Link>
                                        )
                                    })}
                                    <div className="footer-top-container_menu d-none d-lg-flex">
                                        <p className="footer-top-container_header">{this.site_nav_header}</p>

                                        <SiteNavItems navItems={this.site_nav_items} />


                                    </div>

                                    <div className="footer-top-container_menu">
                                        <p className="footer-top-container_header">{this.contact_header}</p>

                                        {this.contact_nav_items.map((item, index) => {
                                            return <p className="footer-nav-text" key={'contact_nav_items_' + index}>{item.FooterText?.Title}</p>
                                        })}


                                        <div className="footer-top-container_menu-info">

                                            {this.info_nav_items.map((item, index) => {
                                                return (
                                                    item.SecondLink.Url.includes('https') || item.SecondLink.Url.includes('tel') ?
                                                        <a href={item.SecondLink.Url} target="_blank" role="menuitem" key={'info_nav_items_' + index}>{item.SecondLink.Title}</a>
                                                        : <Link to={item.SecondLink.Url} role="menuitem" key={'info_nav_items_' + index}>{item.SecondLink.Title}</Link>)
                                            })}

                                        </div>

                                        {this.bottom_nav_items.map((item, index) => {
                                            return (
                                                item.ThirdLink.Url.includes('https') ?
                                                    <a href={item.ThirdLink.Url} target="_blank" role="menuitem" key={'bottom_nav_items_' + index}>{item.ThirdLink.Title}</a>
                                                    : <Link to={item.ThirdLink.Url} role="menuitem" key={'bottom_nav_items_' + index}>{item.ThirdLink.Title}</Link>)
                                        })}

                                    </div>
                                    <div className="footer-top-container_social">
                                        <p className="footer-top-container_header">{this.social_media_header}</p>
                                        <div className="footer_social_links">

                                            {this.social_nav_items.map((item, index) => {
                                                return (
                                                    item.SocialLink.Url.includes('https') ?
                                                        <a href={item.SocialLink.Url} target="_blank" role="menuitem" key={'social_nav_items_' + index}>
                                                            <img src={item.SocialLink.Icon} />
                                                        </a>
                                                        : <Link to={item.SocialLink.Url} role="menuitem" key={'social_nav_items_' + index}>
                                                            <img src={item.SocialLink.Icon} />
                                                        </Link>
                                                )
                                            })}

                                            {this.right_nav_items.map((item, index) => {
                                                return (
                                                    item.FooterArrowLink.Url.includes('https') ?
                                                        <a className="footer-top-container_header d-none d-lg-flex" target="_blank" href={item.FooterArrowLink.Url} role="menuitem" key={'right_nav_items' + index}>{item.FooterArrowLink.Title} <span className="bg-white round-arrow"><i className="arrow right "></i></span></a>
                                                        : <Link className="footer-top-container_header d-none d-lg-flex" to={item.FooterArrowLink.Url} role="menuitem" key={'right_nav_items' + index}>{item.FooterArrowLink.Title} <span className="bg-white round-arrow"><i className="arrow right "></i></span></Link>
                                                )
                                            })}

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </footer>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="footer-bottom_container">

                                    {this.lower_footer_nav_items.map((item, index) => {
                                        return (
                                            item.FourthLink.Url.includes('https') ?
                                                <a href={item.FourthLink.Url} target="_blank" role="menuitem" key={'lower_footer_nav_items_' + index}>{item.FourthLink.Title}</a>
                                                : <Link to={item.FourthLink.Url} role="menuitem" key={'lower_footer_nav_items_' + index}>{item.FourthLink.Title}</Link>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /cp-scaffold */}
            </div>


        )
    }
}


function SiteNavItems({ navItems }) {
    const isAuthenticated = useIsAuthenticated();
    const { user } = useContext(UserContext);
    return (
        navItems.map((item, index) => {
            if(item.Link.Url.includes('#b2c-edit-profile'))
            {
                return (<a href={item.Link.Url} role="menuitem" onClick={(e) => { e.preventDefault(); user.editProfile(window.localStorage.lang) }}>{item.Link.Title}</a>)
            }
            else if(item.Link.Url.includes('#b2c-edit_password'))
            {
                return (<a href={item.Link.Url} role="menuitem" onClick={(e) => { e.preventDefault(); user.editPassword(window.localStorage.lang) }}>{item.Link.Title}</a>)
            }
            else if (item.Link.Url.includes('#b2c-edit_email')) {
                return (<a href={item.Link.Url} role="menuitem" onClick={(e) => { e.preventDefault(); user.editEmail(window.localStorage.lang) }}>{item.Link.Title}</a>)
            }
            else if (item.Link.Url.includes('https')){
                return (<a href={item.Link.Url} role="menuitem" target="_blank" key={'site_nav_items_' + index}>{item.Link.Title}</a>)
            }
            else{
                return (<Link to={item.Link.Url} role="menuitem" key={'site_nav_items_' + index}>{item.Link.Title}</Link>)
            }
        })
    )
}