import React, { useState, useContext } from "react";
import {
  CmsComponent,
  CmsDataCache,
  CmsField,
  CmsFieldTypes,
} from "crownpeak-dxm-react-sdk";
import { Link } from "react-router-dom";
import FieldSelect from "../form/field-select";
import UserContext from "../../providers/user-provider";
import { Modal } from "../functional/Modal.js";
import config from "../../configuration";
import UserService from "../../services/user-service";
import { RedeemRequest } from "../../types";

export default class RedeemForm extends CmsComponent {
  formId: any;
  recepientEmail: any;

  firstTextFieldLabel: any;
  secondTextFieldLabel: any;

  emailFieldLabel: any;
  emailInvalidError: any;

  uniqueIDInvalidError: any;
  telInvalidError: any;
  telFieldLabel: any;

  dropdownFieldLabel: any;
  dropdownCaptionText: any;
  dropdownDefaultText: any;

  uniqueIDTextFieldLabel: any;
  uniqueIDTextFieldAlt: any;
  uniqueIDImg: any;
  textAreaFieldLabel: any;

  optionsList: any;
  submitCtaText: any;
  successAlertImg: any;
  successAlertImgAlt: any;
  successAlertText: any;
  requiredFieldError: any;
  acceptTermsLabel: any;

  constructor(props) {
    super(props);
    this.cmsFolder = "Section";
    this.cmsDisableDragDrop = false;
    this.cmsZones = ["droppable", "DragDropZone"];

    this.firstTextFieldLabel = new CmsField(
      "First_Text_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.First_Text_Field_Label : null
    );
    this.secondTextFieldLabel = new CmsField(
      "Second_Text_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Second_Text_Field_Label : null
    );

    this.emailFieldLabel = new CmsField(
      "Email_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Email_Field_Label : null
    );
    this.emailInvalidError = new CmsField(
      "Email_Invalid_Error_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Email_Invalid_Error_Text : null
    );

    this.telFieldLabel = new CmsField(
      "Telephone_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Telephone_Field_Label : null
    );
    this.telInvalidError = new CmsField(
      "Telephone_Invalid_Error_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Telephone_Invalid_Error_Text : null
    );

    this.dropdownFieldLabel = new CmsField(
      "Dropdown_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Dropdown_Field_Label : null
    );

    this.uniqueIDTextFieldLabel = new CmsField(
      "UniqueID_Text_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.UniqueID_Text_Field_Label : "null"
    );
    this.uniqueIDTextFieldAlt = new CmsField(
      "UniqueID_Text_Field_Alt",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.UniqueID_Text_Field_Alt : "null"
    );
    this.uniqueIDInvalidError = new CmsField(
      "UniqueID_Invalid_Error_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.UniqueID_Invalid_Error_Text : null
    );

    this.uniqueIDImg = new CmsField(
      "UniqueID_Image",
      CmsFieldTypes.IMAGE,
      props && props.data ? props.data.UniqueID_Image : null
    );

    this.textAreaFieldLabel = new CmsField(
      "Text_Area_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Text_Area_Field_Label : null
    );
    this.formId = new CmsField(
      "Form_ID",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Form_ID : null
    );
    this.recepientEmail = new CmsField(
      "Recepient_Email",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Recepient_Email : null
    );
    this.submitCtaText = new CmsField(
      "Submit_Cta_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Submit_Cta_Text : null
    );

    this.optionsList = new CmsField(
      "Options_List",
      "Options_List_Item",

      CmsDataCache.get(CmsDataCache.cmsAssetId).RedeemForm?.OptionsList ||
        CmsDataCache.get(CmsDataCache.cmsAssetId).DropZones.droppable[0]
          ?.RedeemForm?.OptionsList ||
        CmsDataCache.get(CmsDataCache.cmsAssetId).DropZones.droppable[1]
          ?.RedeemForm?.OptionsList ||
        CmsDataCache.get(CmsDataCache.cmsAssetId).DropZones.DragDropZone[0]
          ?.RedeemForm?.OptionsList ||
        []
    );

    this.successAlertText = new CmsField(
      "Success_Alert_Text",
      CmsFieldTypes.WYSIWYG,
      props && props.data ? props.data.Success_Alert_Text : null
    );
    this.successAlertImg = new CmsField(
      "Success_Alert_Image",
      CmsFieldTypes.IMAGE,
      props && props.data ? props.data.Success_Alert_Image : null
    );
    this.successAlertImgAlt = new CmsField(
      "Success_Alert_Image_Alt",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Success_Alert_Image_Alt : null
    );

    this.requiredFieldError = new CmsField(
      "Required_Field_Error_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Required_Field_Error_Text : null
    );
    this.dropdownCaptionText = new CmsField(
      "Dropdown_Caption_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Dropdown_Caption_Text : null
    );
    this.dropdownDefaultText = new CmsField(
      "Dropdown_Default_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Dropdown_Default_Text : null
    );

    if (this.dropdownDefaultText && !this.dropdownDefaultText.value) {
      if (this.dropdownCaptionText && this.dropdownCaptionText.value) {
        this.dropdownDefaultText.value = this.dropdownCaptionText.value;
      } else {
        this.dropdownDefaultText.value = "------";
      }
    }

    this.acceptTermsLabel = new CmsField(
      "AcceptTerms_Checkbox_Label",
      CmsFieldTypes.WYSIWYG,
      props && props.data ? props.data.AcceptTerms_Checkbox_Label : null
    );
  }

  render() {
    return (
      <div className="contact">
        {/* cp-scaffold
                    {Form_ID:Text}
                    {Recepient_Email:Text}

                    {First_Text_Field_Label:Text}
                    {Second_Text_Field_Label:Text}
                    
                    {Email_Field_Label:Text}
                    {Email_Invalid_Error_Text:Text}
                    
                    {Telephone_Field_Label:Text}
                    {Telephone_Invalid_Error_Text:Text}
                    
                    {Dropdown_Field_Label:Text}
                    {Dropdown_Caption_Text:Text}
                    {Dropdown_Default_Text:Text}

                    {UniqueID_Text_Field_Label:Text}
                    {UniqueID_Text_Field_Alt:Text}
                    {UniqueID_Image:Src}
                    {UniqueID_Invalid_Error_Text:Text}


                    {Text_Area_Field_Label:Text}
                    {Submit_Cta_Text:Text}
                    {Success_Alert_Text:Text}
                    {Success_Alert_Image:Src}
                    {Success_Alert_Image_Alt:Text}
                    {Required_Field_Error_Text:Text}

                    <div className="field field-checkbox">
                        <label>
                            <span>{AcceptTerms_Checkbox_Label:Wysiwyg}</span>
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                /cp-scaffold */}

        {/* <List name="OptionsList" type="OptionsListItem"> */}
        <Form
          formId={this.formId}
          recepientEmail={this.recepientEmail}
          firstTextFieldLabel={this.firstTextFieldLabel}
          secondTextFieldLabel={this.secondTextFieldLabel}
          emailFieldLabel={this.emailFieldLabel}
          emailInvalidError={this.emailInvalidError}
          telFieldLabel={this.telFieldLabel}
          telInvalidError={this.telInvalidError}
          dropdownFieldLabel={this.dropdownFieldLabel}
          dropdownCaptionText={this.dropdownCaptionText}
          dropdownDefaultText={this.dropdownDefaultText}
          uniqueIDTextFieldLabel={this.uniqueIDTextFieldLabel}
          uniqueIDTextFieldAlt={this.uniqueIDTextFieldAlt}
          uniqueIDImg={this.uniqueIDImg}
          uniqueIDInvalidError={this.uniqueIDInvalidError}
          textAreaFieldLabel={this.textAreaFieldLabel}
          optionsList={this.optionsList}
          submitCtaText={this.submitCtaText}
          successAlertImg={this.successAlertImg}
          successAlertImgAlt={this.successAlertImgAlt}
          successAlertText={this.successAlertText}
          requiredFieldError={this.requiredFieldError}
          acceptTermsLabel={this.acceptTermsLabel}
        />
        {/* </List> */}
      </div>
    );
  }
}

function Form({
  formId,
  recepientEmail,

  firstTextFieldLabel,
  secondTextFieldLabel,
  emailFieldLabel,
  emailInvalidError,

  telFieldLabel,
  telInvalidError,

  dropdownFieldLabel,
  dropdownCaptionText,
  dropdownDefaultText,

  uniqueIDTextFieldLabel,
  uniqueIDTextFieldAlt,
  uniqueIDImg,
  uniqueIDInvalidError,

  textAreaFieldLabel,
  submitCtaText,
  optionsList,
  successAlertImg,
  successAlertImgAlt,
  successAlertText,
  requiredFieldError,
  acceptTermsLabel,
}) {
  const { accounts, accessToken } = useContext(UserContext);
  const tokenClaims = accounts.length > 0 ? accounts[0]["idTokenClaims"] : null;

  const [state, setState] = useState({
    fields: {
      Email: tokenClaims != null ? tokenClaims["preferred_username"] : "",
      FirstName: tokenClaims != null ? tokenClaims["given_name"] : "",
      LastName: tokenClaims != null ? tokenClaims["family_name"] : null,
      dropdown: dropdownDefaultText,
    },
    errors: {
      FirstName: "",
      LastName: "",
      Email: "",
      Tel: "",
      dropdown: "",
      UniqueID: "",
      Message: "",
      acceptTerms: "",
    },
  });

  const [formDone, setFormDone] = useState(false);
  const [buttondisable, setbuttondisable] = useState(false);

  function handleChange(e, fieldId, field) {
    let fields = state.fields;
    if (field === "dropdown") {
      fields["dropdown"] = e.target.innerText;
    } else if (e.target.type === "checkbox") {
      fields[field] = e.target.checked;
    } else {
      fields[field] = e.target.value;
    }

    setState({
      ...state,
      fields,
    });
  }

  function handleValidation(e) {
    let fields = state.fields;
    let errors = {
      FirstName: "",
      LastName: "",
      Email: "",
      Tel: "",
      dropdown: "",
      UniqueID: "",
      Message: "",
      acceptTerms: "",
    };
    let formIsValid = true;

    //Checkboxes
    if (!fields["FirstName"]) {
      formIsValid = false;
      errors["FirstName"] = requiredFieldError;
    }

    if (!fields["LastName"]) {
      formIsValid = false;
      errors["LastName"] = requiredFieldError;
    }
    if (!fields["Email"]) {
      formIsValid = false;
      errors["Email"] = requiredFieldError;
    }
    if (!fields["Tel"]) {
      formIsValid = false;
      errors["Tel"] = requiredFieldError;
    }

    if (!fields["acceptTerms"]) {
      formIsValid = false;
      errors["acceptTerms"] = requiredFieldError;
    }

    if (!fields["dropdown"] || fields["dropdown"] === dropdownCaptionText) {
      formIsValid = false;
      errors["dropdown"] = requiredFieldError;
    }
    // if (!fields["UniqueID"]) {
    //     formIsValid = false;
    //     errors["UniqueID"] = requiredFieldError;
    // }
    // if (!fields["Message"]) {
    //     formIsValid = false;
    //     errors["Message"] = requiredFieldError;
    // }

    //Email
    if (!fields["Email"]) {
      formIsValid = false;
      errors["Email"] = requiredFieldError;
    }
    if (typeof fields["Email"] !== "undefined") {
      let lastAtPos = fields["Email"].lastIndexOf("@");
      let lastDotPos = fields["Email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["Email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["Email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["Email"] = emailInvalidError;
      }
    }

    //Tel
    if (typeof fields["Tel"] !== "undefined") {
      var pattern = new RegExp(/[^+]{1}[0-9\b]+$/);
      if (!pattern.test(fields["Tel"])) {
        formIsValid = false;
        errors["Tel"] = telInvalidError;
      }
    }

    if (typeof fields["UniqueID"] !== "undefined") {
      var pattern = new RegExp(/[^+]{1}[0-9\b]+$/);
      if (!pattern.test(fields["UniqueID"])) {
        formIsValid = false;
        errors["UniqueID"] = uniqueIDInvalidError ?? "NotValid";
      }
    }

    setState({
      ...state,
      errors: errors,
    });

    return formIsValid;
  }

  function objectifyForm(formArray) {
    //serialize data function
    var formdata = new FormData();
    for (var i = 0; i < formArray.length; i++) {
      if (
        formArray[i]["name"] &&
        formArray[i]["name"] !== "" &&
        formArray[i]["name"] !== "fieldsubmit"
      ) {
        formdata.append(formArray[i]["name"], formArray[i]["value"]);
      }
    }
    formdata.append(
      "Country",
      document.querySelector(".contact-form .field-select .selected-text")!
        .textContent!
    );

    return formdata;
  }

  const [showModal, setShowModal] = useState(false);
  function handleUniqueIDImg_Show(e) {
    e.preventDefault();
    setShowModal(true);
  }

  function handleUniqueIDImg_Hide(e) {
    e.preventDefault();
    setShowModal(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (handleValidation(e)) {
      var form = document.querySelector("#redeemForm");
      let formData = objectifyForm(form) as BodyInit;

      var requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow" as RequestRedirect,
      };

      console.log(recepientEmail);
      let fields = state.fields;

      var userservice = new UserService(accessToken);

      var root = document.getElementById("root");
      setbuttondisable(true);
      var x: RedeemRequest = {
        firstName: fields["FirstName"],
        lastName: fields["LastName"],
        email: fields["Email"],
        phone: fields["Tel"],
        country: fields["dropdown"].value,
        uniqueId: fields["UniqueID"],
        message: fields["Message"],
        language: window.location.pathname.split("/")[1],
        recepientEmail: recepientEmail["value"].slice(2, -2),
        header: "Redeem your Reward",
        source:
          root == null
            ? "Kverneland"
            : root.getAttribute("data-cms-wrapper-name"),
      };
      console.log("XXXXX", x);
      fetch(`${config.runtime.urls.apiBaseUrl}user/redeem`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "Application/Json",
        },
        body: JSON.stringify(x),
      })
        .then((x) => {
          if (x.status === 200) {
            setFormDone(true);
          }
        })

        .catch((e) => setFormDone(false));

      // fetch("https://snippet.omm.crownpeak.com/p/17792bf3-8ee0-46a0-8aa7-5b013cf230b4", requestOptions)
      //     .then(response => {
      //         response.text();
      //         if (response.status === 200) {
      //             setFormDone(true)
      //         } else {
      //             setFormDone(false)
      //         }
      //     })
      //     .then(result => { })
      //     .catch(error => { });
    }
  }

  return !formDone ? (
    <form className="contact-form" id="redeemForm">
      <div className="grid-fields">
        <div className="field field-text">
          <label htmlFor="FirstName">
            {firstTextFieldLabel}
            {state.errors.FirstName ? (
              <span className="field-error">{state.errors.FirstName}</span>
            ) : null}
          </label>
          <input
            type="text"
            value={state.fields.FirstName}
            name="FirstName"
            onChange={(e) => {
              handleChange(e, e.target.id, "FirstName");
            }}
          />
        </div>

        <div className="field field-text">
          <label htmlFor="LastName">
            {secondTextFieldLabel}
            {state.errors.LastName ? (
              <span className="field-error">{state.errors.LastName}</span>
            ) : null}
          </label>
          <input
            type="text"
            name="LastName"
            value={state.fields.LastName}
            onChange={(e) => {
              handleChange(e, e.target.id, "LastName");
            }}
          />
        </div>
      </div>

      <div className="grid-fields">
        <div className="field field-email">
          <label htmlFor="Email">
            {emailFieldLabel}
            {state.errors.Email ? (
              <span className="field-error">{state.errors.Email}</span>
            ) : null}
          </label>
          <input
            type="email"
            name="Email"
            value={state.fields.Email}
            onChange={(e) => {
              handleChange(e, e.target.id, "Email");
            }}
          />
        </div>
        <div className="field field-tel">
          <label htmlFor="Tel">
            {telFieldLabel}
            {state.errors.Tel ? (
              <span className="field-error">{state.errors.Tel}</span>
            ) : null}
          </label>
          <input
            type="tel"
            name="Tel"
            onChange={(e) => {
              handleChange(e, e.target.id, "Tel");
            }}
          />
        </div>
      </div>

      <div className="field field-select">
        <label htmlFor="dropdown">
          {dropdownFieldLabel}
          {state.errors.dropdown ? (
            <span className="field-error">{state.errors.dropdown}</span>
          ) : null}
        </label>
        <FieldSelect
          defaultText={dropdownDefaultText}
          optionsList={optionsList.value}
          onChange={handleChange}
        />
      </div>
      <Modal
        show={showModal}
        width={"auto"}
        handleClose={handleUniqueIDImg_Hide}
      >
        <img src={uniqueIDImg} />
      </Modal>
      <div className="field field-text">
        <label htmlFor="UniqueID">
          <label htmlFor="UniqueID" style={{ justifyContent: "start" }}>
            {uniqueIDTextFieldLabel}
            {uniqueIDImg ? (
              <a
                className="qhelp-circle"
                href=""
                onClick={handleUniqueIDImg_Show}
                title={uniqueIDTextFieldAlt}
              >
                ?
              </a>
            ) : (
              <></>
            )}
          </label>
          {state.errors.UniqueID ? (
            <span className="field-error">{state.errors.UniqueID}</span>
          ) : null}
        </label>
        <input
          type="text"
          name="UniqueID"
          onChange={(e) => {
            handleChange(e, e.target.id, "UniqueID");
          }}
        />
      </div>

      <div className="field field-textarea">
        <label htmlFor="Message">
          {textAreaFieldLabel}
          {/* {state.errors.Message ? <span className='field-error'>{state.errors.Message}</span> : null} */}
        </label>
        <textarea
          name="Message"
          onChange={(e) => {
            handleChange(e, e.target.id, "Message");
          }}
        ></textarea>
      </div>

      <div className="field field-checkbox">
        <label>
          {state.errors.acceptTerms ? (
            <span className="field-error">{state.errors.acceptTerms}</span>
          ) : null}
          <span dangerouslySetInnerHTML={{ __html: acceptTermsLabel }}></span>
          <input
            type="checkbox"
            onChange={(e) => {
              handleChange(e, e.target.id, "acceptTerms");
            }}
            defaultChecked={false}
          />
          <span className="checkmark"></span>
        </label>
      </div>

      <div className="field field-submit">
        <input
          id="EmailRecipients"
          name="EmailRecipients"
          type="hidden"
          value={recepientEmail}
        />
        <input id="WcoFormId" name="WcoFormId" type="hidden" value={formId} />
        <button
          type="submit"
          disabled={buttondisable}
          name="fieldsubmit"
          onClick={handleSubmit}
        >
          {submitCtaText}
        </button>
      </div>
    </form>
  ) : (
    <div className="alert-wrapper register-alert-wrapper">
      <div className="contact-form-success">
        <img src={successAlertImg} alt={successAlertImgAlt} />
      </div>
      <p dangerouslySetInnerHTML={{ __html: successAlertText }}></p>
    </div>
  );
}
