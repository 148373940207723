/* eslint-disable import/no-anonymous-default-export */
import * as ActionTypes from './actions';
import { ContactState, ContactAction } from './types';

const createInitialState = (): ContactState => ({
  loading: false,
  entries: []
});

export default function (
  state = createInitialState(),
  action: ContactAction
): ContactState {
  switch (action.type) {
    default: {
      return state;
    }
    case ActionTypes.CONTACT_ENTRIES_LOADING: {
      return { ...state, loading: true };
    }
    case ActionTypes.CONTACT_ENTRIES_LOADED: {
      return { ...state, loading: false, entries: action.entries };
    }
  }
}
