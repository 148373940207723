import { createStore, applyMiddleware, compose } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Dispatch } from 'react';
import { useDispatch as _useDispatch } from 'react-redux';
import reducer from './reducer';
import { GlobalAction } from '../global-types';
import { EventsAction } from '../pages/events/types';
import { ContactAction } from '../pages/contact/types';
import { PartsActions } from '../pages/parts/reducer';
import { FleetDetailAction } from '../pages/fleet/details/types';
import { FleetAction } from '../pages/fleet/home/types';
import { RegisterAction } from '../pages/register/reducer';

const middleware = [applyMiddleware(thunk)];

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const store = createStore(reducer, composeWithDevTools(...middleware));

if ((module as any).hot) {
  (module as any).hot.accept('./reducer', () => {
    const nextRootReducer = require('./reducer').default;
    store.replaceReducer(nextRootReducer);
  });
}

export type RootState = ReturnType<typeof reducer>;

type LegalActions = Parameters<typeof store.dispatch>;
type AllActions =
  | GlobalAction
  | EventsAction
  | ContactAction
  | PartsActions
  | FleetDetailAction
  | FleetAction
  | RegisterAction;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AllActions
>;

export type AppDispatch = typeof store.dispatch;

export function useDispatch(): Dispatch<AllActions | AppThunk> {
  const dispatch = _useDispatch();
  return (event: AllActions | AppThunk): Dispatch<AllActions | AppThunk> => {
    const x = dispatch(event);

    return x as Dispatch<AllActions | AppThunk>;
  };
}
