import * as ActionTypes from './actions';
import { Section } from '../../global-types';

export interface PartsState {
  loading: boolean;
  entries: Section[];
}

export type PartsActions =
  | { type: typeof ActionTypes.PARTS_ENTRIES_LOADING }
  | { type: typeof ActionTypes.PARTS_ENTRIES_LOADED; entries: Section[] };

const createInitialState = (): PartsState => ({
  loading: true,
  entries: []
});

export default function (
  state = createInitialState(),
  action: PartsActions
): PartsState {
  switch (action.type) {
    default: {
      return state;
    }
    case ActionTypes.PARTS_ENTRIES_LOADING: {
      return { ...state, loading: true };
    }
    case ActionTypes.PARTS_ENTRIES_LOADED: {
      return { ...state, loading: false, entries: action.entries };
    }
  }
}
