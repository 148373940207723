import { CommonWebService } from '../../services';
import { AppThunk } from '../../controllers/store';
import config from '../../configuration';
export const CONTACT_ENTRIES_LOADED = 'CONTACT_ENTRIES_LOADED';
export const CONTACT_ENTRIES_LOADING = 'CONTACT_ENTRIES_LOADING';

// export const load = (site): AppThunk => {
//   return async (dispatch): Promise<void> => {
//     dispatch({ type: CONTACT_ENTRIES_LOADING });
//     const { runMode } = config;
//     const app = runMode === 'kvg' ? 'mykverneland' : 'myvicon';

//     const service = new CommonWebService();

//     // const entries = await service.getSection(`${app}-contact`, site);
//     // dispatch({ type: CONTACT_ENTRIES_LOADED, entries });
//   };
// };
