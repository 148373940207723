import React from 'react';
import { CmsComponent, CmsDynamicDataProvider, CmsField, CmsFieldTypes, CmsStaticDataProvider } from 'crownpeak-dxm-react-sdk';
export default class SidebarTopHeader extends CmsComponent {
    mainHeader: CmsField;
    jsonData: any;
    constructor(props: any) {
        super(props);
        this.cmsFolder = "Global";
        this.cmsDisableDragDrop = true;

        this.mainHeader = new CmsField("Main_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Main_Header : null);

        if (this.props.dataProvider instanceof CmsStaticDataProvider) {
            this.jsonData = new CmsStaticDataProvider()?.getCustomDataSync(`${this.props.assetId}.json`).SidebarTopHeader;
        }
        else {
            this.jsonData = new CmsDynamicDataProvider()?.getDynamicQuerySync(`q=${this.props.assetId}&fl=custom_t_json:[json]`).response.docs[0]?.custom_t_json.SidebarTopHeader;
        }
    }


    render() {
        return (
            <React.Fragment>
                {/* cp-scaffold 
                    <p className="sidebar-header">{Main_Header:Text}</p>
                else */}
                <p className="sidebar-header">{this.jsonData.Main_Header}</p>
                {/* /cp-scaffold */}
            </React.Fragment>
        )
    }
}
