import React from 'react';
import { CmsComponent, CmsDynamicDataProvider, CmsField, CmsFieldTypes, CmsStaticDataProvider } from 'crownpeak-dxm-react-sdk';

export default class SidebarTopImage extends CmsComponent {
    mainImg: any;
    jsonData: any;
    constructor(props: any) {
        super(props);
        this.cmsFolder = "Global";
        this.cmsDisableDragDrop = true;

        this.mainImg = new CmsField("Main_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Main_Image : null);

        if (this.props.dataProvider instanceof CmsStaticDataProvider) {
            this.jsonData = new CmsStaticDataProvider()?.getCustomDataSync(`${this.props.assetId}.json`).SidebarTopImage;
        } else {
            this.jsonData = new CmsDynamicDataProvider()?.getDynamicQuerySync(`q=${this.props.assetId}&fl=custom_t_json:[json]`).response.docs[0]?.custom_t_json.SidebarTopImage;
        }
    }
    render() {
        return (
            <React.Fragment>
                {/* cp-scaffold
                    <img src="{Main_Image:Src}" />
                else */}
                <img src={this.jsonData.Main_Image} />
                {/* /cp-scaffold */}
            </React.Fragment>
        )
    }
}
