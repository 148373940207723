import React, { useContext } from 'react'
import { CmsComponent, CmsDynamicDataProvider, CmsStaticDataProvider } from 'crownpeak-dxm-react-sdk';
import UserContext, { UserProvider } from '../../providers/user-provider';
import HeaderRouting from '../../core/headerRouting';
import Routing from '../../core/routing';
import { useIsAuthenticated } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import globeIcon from '../../global.png'
const CountryList = (props) => {
    const { showModal, setLanguageLocal } = useContext(UserContext);
    let assetId = HeaderRouting.getLanguage(localStorage.getItem('lang') || Routing.languages.filter(item => item === window.location.pathname.split('/')[1])[0] || 'en-ww');
    let jsonData;
    if (props.dataProvider instanceof CmsStaticDataProvider) {
        jsonData = new CmsStaticDataProvider()?.getCustomDataSync(`${assetId}.json`).HeaderNavigationAdmin;
    }
    else {
        jsonData = new CmsDynamicDataProvider()?.getDynamicQuerySync(`q=${assetId}&fl=custom_t_json:[json]`).response.docs[0].custom_t_json.HeaderNavigationAdmin;
    }

    let countries_nav_items = jsonData.CountriesNavItems;
    let country_list_header = jsonData.Country_List_Header;


    return (
        showModal? <React.Fragment>
            <div className="modal country-list-modal" id="countryListModal" tab-index="-1" role="dialog" aria-labelledby="countryListModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='country-list-header'>{country_list_header}</div>
                            <div className='country-list'>
                                {countries_nav_items.map((item, index) => {
                                    return (
                                        <Link className="dropdown-item" to={item.DropdownLink?.Url} role="menuitem" key={'country_nav_items_' + index} onClick={(e) => { e.preventDefault(); setLanguageLocal(item.DropdownLink?.Url); }}>
                                            {!item.DropdownLink?.Country_Code?  
                                                <img src={globeIcon}/>
                                            :
                                            <ReactCountryFlag countryCode={item.DropdownLink?.Country_Code} svg className='emojiFlag' style={{
                                                fontSize: '25px',
                                                lineHeight: '25px',
                                            }} />}
                                            <span>{item.DropdownLink?.Title}</span>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-overlay'></div>
        </React.Fragment>
        : null
    )

}

export default CountryList