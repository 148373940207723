import './modal.css';


export const Modal = ({ handleClose, show, width, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main" style={{ width: width }}>
        {children}
        <a href='' className='modal-close' onClick={handleClose}>X</a>
      </section>
    </div>
  );
};
