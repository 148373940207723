import React, { useState, useEffect, useContext } from "react";
import {
  CmsComponent,
  CmsDataCache,
  CmsField,
  CmsFieldTypes,
} from "crownpeak-dxm-react-sdk";
import { Link } from "react-router-dom";
import FieldSelect from "../form/field-select";

import { RootState } from "../../controllers/store";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import UserContext from "../../providers/user-provider";
import { AppThunk } from "../../controllers/store";
import { RequestHelpFormDto } from "../../types";
// import { UserService } from "../../services";
import config from "../../configuration";

export default class ProductRegisterHelpForm extends CmsComponent {
  image: any;
  alt: any;
  header: any;
  description: any;

  contactDetailsTextLabel: any;
  machineDetailsTextLabel: any;
  uploadDetailsTextLabel: any;
  firstNameTextLabel: any;
  lastNameTextLabel: any;

  addressTextLabel: any;
  postalCodeTextLabel: any;

  countrySelectLabel: any;
  cityTextLabel: any;

  phoneNumberTextLabel: any;
  emailAddressTextLabel: any;

  brandTextLabel: any;
  modelTypeTextLabel: any;
  productionYearTextLabel: any;
  serialNumberTextLabel: any;

  uploadNote: any;
  uploadMachinePlateLabel: any;
  uploadMachinePlateAreaDesktopText: any;
  uploadMachinePlateAreaMobileText: any;
  uploadMachinePlateBtnText: any;
  uploadMachinePlateImage: any;
  uploadMachinePlateAlt: any;

  uploadMachinePlateSuccessAlertImg: any;
  uploadMachinePlateSuccessAlertImgAlt: any;
  uploadMachinePlateErrorAlertImg: any;
  uploadMachinePlateErrorAlertImgAlt: any;

  uploadMachineLabel: any;
  uploadMachineAreaDesktopText: any;
  uploadMachineAreaMobileText: any;
  uploadMachineBtnText: any;
  uploadMachineImage: any;
  uploadMachineAlt: any;

  uploadMachineSuccessAlertImg: any;
  uploadMachineSuccessAlertImgAlt: any;
  uploadMachineErrorAlertImg: any;
  uploadMachineErrorAlertImgAlt: any;

  subjectLineTextLabel: any;
  messageTextLabel: any;

  formId: any;
  countryOptionsList: any;
  submitCtaText: any;
  recepientEmail: any;
  successAlertImg: any;
  successAlertImgAlt: any;
  successAlertText: any;
  requiredFieldError: any;
  formValidError: any;
  emailInvalidError: any;
  countryDefaultText: any;
  phoneNumberInvalidError: any;
  acceptTermsLabel: any;


  constructor(props) {
    super(props);
    this.cmsFolder = "Section";
    this.cmsDisableDragDrop = false;
    this.cmsZones = ["droppable", "DragDropZone"];

    this.image = new CmsField(
      "Image",
      CmsFieldTypes.IMAGE,
      props && props.data ? props.data.Image : null
    );
    this.alt = new CmsField(
      "Alt",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Alt : null
    );
    this.header = new CmsField(
      "Header",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Header : null
    );
    this.description = new CmsField(
      "Description",
      CmsFieldTypes.WYSIWYG,
      props && props.data ? props.data.Description : null
    );

    this.contactDetailsTextLabel = new CmsField(
      "ContactDetails_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.ContactDetails_Text_Label : null
    );

    this.firstNameTextLabel = new CmsField(
      "FirstName_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.FirstName_Text_Label : null
    );
    this.lastNameTextLabel = new CmsField(
      "LastName_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.LastName_Text_Label : null
    );

    this.addressTextLabel = new CmsField(
      "Address_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Address_Text_Label : null
    );
    this.postalCodeTextLabel = new CmsField(
      "PostalCode_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.PostalCode_Text_Label : null
    );

    this.countrySelectLabel = new CmsField(
      "Country_Select_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Country_Select_Label : null
    );
    this.cityTextLabel = new CmsField(
      "City_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.City_Text_Label : null
    );

    this.phoneNumberTextLabel = new CmsField(
      "PhoneNumber_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.PhoneNumber_Text_Label : null
    );
    this.emailAddressTextLabel = new CmsField(
      "EmailAddress_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.EmailAddress_Text_Label : null
    );

    this.machineDetailsTextLabel = new CmsField(
      "MachineDetails_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.MachineDetails_Text_Label : null
    );

    this.uploadDetailsTextLabel = new CmsField(
      "UploadDetails_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.UploadDetails_Text_Label : null
    );

    this.uploadNote = new CmsField(
      "Upload_Note",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Upload_Note : null
    );


    this.brandTextLabel = new CmsField(
      "Brand_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Brand_Text_Label : null
    );
    this.modelTypeTextLabel = new CmsField(
      "ModelType_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.ModelType_Text_Label : null
    );
    this.productionYearTextLabel = new CmsField(
      "ProductionYear_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.ProductionYear_Text_Label : null
    );
    this.serialNumberTextLabel = new CmsField(
      "SerialNumber_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.SerialNumber_Text_Label : null
    );

    this.uploadMachinePlateLabel = new CmsField(
      "Upload_MachinePlateLabel",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Upload_MachinePlateLabel : null
    );
    this.uploadMachinePlateAreaDesktopText = new CmsField(
      "Upload_MachinePlateArea_Desktop_Text",
      CmsFieldTypes.TEXT,
      props && props.data
        ? props.data.Upload_MachinePlateArea_Desktop_Text
        : null
    );
    this.uploadMachinePlateAreaMobileText = new CmsField(
      "Upload_MachinePlateArea_Mobile_Text",
      CmsFieldTypes.TEXT,
      props && props.data
        ? props.data.Upload_MachinePlateArea_Mobile_Text
        : null
    );
    this.uploadMachinePlateBtnText = new CmsField(
      "Upload_MachinePlateButton_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Upload_MachinePlateButton_Text : null
    );
    this.uploadMachinePlateImage = new CmsField(
      "Upload_MachinePlateImage",
      CmsFieldTypes.IMAGE,
      props && props.data ? props.data.Upload_MachinePlateImage : null
    );
    this.uploadMachinePlateAlt = new CmsField(
      "Upload_MachinePlateImage_Alt",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Upload_MachinePlateImage_Alt : null
    );

    this.uploadMachinePlateSuccessAlertImg = new CmsField(
      "Upload_MachinePlateSuccess_Alert_Image",
      CmsFieldTypes.IMAGE,
      props && props.data
        ? props.data.Upload_MachinePlateSuccess_Alert_Image
        : null
    );
    this.uploadMachinePlateSuccessAlertImgAlt = new CmsField(
      "Upload_MachinePlateSuccess_Alert_Image_Alt",
      CmsFieldTypes.TEXT,
      props && props.data
        ? props.data.Upload_MachinePlateSuccess_Alert_Image_Alt
        : null
    );
    this.uploadMachinePlateErrorAlertImg = new CmsField(
      "Upload_MachinePlateError_Alert_Image",
      CmsFieldTypes.IMAGE,
      props && props.data
        ? props.data.Upload_MachinePlateError_Alert_Image
        : null
    );
    this.uploadMachinePlateErrorAlertImgAlt = new CmsField(
      "Upload_MachinePlateError_Alert_Image_Alt",
      CmsFieldTypes.TEXT,
      props && props.data
        ? props.data.Upload_MachinePlateError_Alert_Image_Alt
        : null
    );

    this.uploadMachineLabel = new CmsField(
      "Upload_MachineLabel",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Upload_MachineLabel : null
    );
    this.uploadMachineAreaDesktopText = new CmsField(
      "Upload_MachineArea_Desktop_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Upload_MachineArea_Desktop_Text : null
    );
    this.uploadMachineAreaMobileText = new CmsField(
      "Upload_MachineArea_Mobile_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Upload_MachineArea_Mobile_Text : null
    );
    this.uploadMachineBtnText = new CmsField(
      "Upload_MachineButton_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Upload_MachineButton_Text : null
    );
    this.uploadMachineImage = new CmsField(
      "Upload_MachineImage",
      CmsFieldTypes.IMAGE,
      props && props.data ? props.data.Upload_MachineImage : null
    );
    this.uploadMachineAlt = new CmsField(
      "Upload_MachineImage_Alt",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Upload_MachineImage_Alt : null
    );

    this.uploadMachineSuccessAlertImg = new CmsField(
      "Upload_MachineSuccess_Alert_Image",
      CmsFieldTypes.IMAGE,
      props && props.data ? props.data.Upload_MachineSuccess_Alert_Image : null
    );
    this.uploadMachineSuccessAlertImgAlt = new CmsField(
      "Upload_MachineSuccess_Alert_Image_Alt",
      CmsFieldTypes.TEXT,
      props && props.data
        ? props.data.Upload_MachineSuccess_Alert_Image_Alt
        : null
    );
    this.uploadMachineErrorAlertImg = new CmsField(
      "Upload_MachineError_Alert_Image",
      CmsFieldTypes.IMAGE,
      props && props.data ? props.data.Upload_MachineError_Alert_Image : null
    );
    this.uploadMachineErrorAlertImgAlt = new CmsField(
      "Upload_MachineError_Alert_Image_Alt",
      CmsFieldTypes.TEXT,
      props && props.data
        ? props.data.Upload_MachineError_Alert_Image_Alt
        : null
    );

    this.subjectLineTextLabel = new CmsField(
      "SubjectLine_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.SubjectLine_Text_Label : null
    );
    this.messageTextLabel = new CmsField(
      "Message_Text_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Message_Text_Label : null
    );

    this.formId = new CmsField(
      "Form_ID",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Form_ID : null
    );
    this.recepientEmail = new CmsField(
      "Recepient_Email",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Recepient_Email : null
    );
    this.submitCtaText = new CmsField(
      "Submit_Cta_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Submit_Cta_Text : null
    );
    this.countryOptionsList = new CmsField(
      "CountryOptions_List",
      "Options_List_Item",
      CmsDataCache.get(CmsDataCache.cmsAssetId).ProductRegisterHelpForm
        ?.CountryOptionsList ||
        CmsDataCache.get(CmsDataCache.cmsAssetId).DropZones.droppable[0]
          ?.ProductRegisterHelpForm?.CountryOptionsList ||
        CmsDataCache.get(CmsDataCache.cmsAssetId).DropZones.DragDropZone[0]
          ?.ProductRegisterHelpForm?.CountryOptionsList ||
        []
    );
    this.successAlertText = new CmsField(
      "Success_Alert_Text",
      CmsFieldTypes.WYSIWYG,
      props && props.data ? props.data.Success_Alert_Text : null
    );
    this.successAlertImg = new CmsField(
      "Success_Alert_Image",
      CmsFieldTypes.IMAGE,
      props && props.data ? props.data.Success_Alert_Image : null
    );
    this.successAlertImgAlt = new CmsField(
      "Success_Alert_Image_Alt",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Success_Alert_Image_Alt : null
    );

    this.requiredFieldError = new CmsField(
      "Required_Field_Error_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Required_Field_Error_Text : null
    );
    this.formValidError = new CmsField(
      "Form_Valid_Error_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Form_Valid_Error_Text : null
    );

    this.emailInvalidError = new CmsField(
      "Email_Invalid_Error_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Email_Invalid_Error_Text : null
    );
    this.phoneNumberInvalidError = new CmsField(
      "PhoneNumber_Invalid_Error_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.PhoneNumber_Invalid_Error_Text : null
    );

    this.countryDefaultText = new CmsField(
      "Country_Default_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Country_Default_Text : null
    );

    this.acceptTermsLabel = new CmsField(
      "AcceptTerms_Checkbox_Label",
      CmsFieldTypes.WYSIWYG,
      props && props.data ? props.data.AcceptTerms_Checkbox_Label : null
    );
  }

  render() {
    return (
      <div className="contact">
        {/* cp-scaffold
                    {ContactDetails_Text_Label:Text}
                    {FirstName_Text_Label:Text}
                    {LastName_Text_Label:Text}
                    {Address_Text_Label:Text}
                    {PostalCode_Text_Label:Text}
                    {Country_Select_Label:Text}
                    {City_Text_Label:Text}

                    {PhoneNumber_Text_Label:Text}
                    {EmailAddress_Text_Label:Text}


                    {MachineDetails_Text_Label:Text}
                    {Brand_Text_Label:Text}
                    {ModelType_Text_Label:Text}
                    {ProductionYear_Text_Label:Text}
                    {SerialNumber_Text_Label:Text}

                    {SubjectLine_Text_Label:Text}
                    {Message_Text_Label:Text}

                    {Submit_Cta_Text:Text}
                    {Form_ID:Text}
                    {Recepient_Email:Text}
                    {Success_Alert_Text:Text}
                    {Success_Alert_Image:Src}
                    {Success_Alert_Image_Alt:Text}
                    {Required_Field_Error_Text:Text}
                    {Form_Valid_Error_Text:Text}
                    {Email_Invalid_Error_Text:Text}
                    {Country_Default_Text:Text}

                    {PhoneNumber_Invalid_Error_Text:Text}

                    <div className="large-card-main-content">
                        <div>
                            <img src="{Image:Src}" alt="{Alt:Text}" />
                        </div>
                        <div className="large-card-text">
                            <p className="large-card-text-header">{Header:Text}</p>
                            <p className="large-card-text-desc">{Description:Wysiwyg}</p>
                        </div>
                    </div>

                    {UploadDetails_Text_Label:Text}
                    {Upload_Note:Text}
                    <FileUpload
                            uploadLabel={Upload_MachinePlateLabel:Text}
                            uploadImage={Upload_MachinePlateImage:Src}
                            uploadAlt={Upload_MachinePlateImage_Alt:Text}
                            uploadBtnText={Upload_MachinePlateButton_Text:Text}
                            uploadSuccessAlertImg={Upload_MachinePlateSuccess_Alert_Image:Src}
                            uploadSuccessAlertImgAlt={Upload_MachinePlateSuccess_Alert_Image_Alt:Text}
                            uploadErrorAlertImg={Upload_MachinePlateError_Alert_Image:Src}
                            uploadErrorAlertImgAlt={Upload_MachinePlateError_Alert_Image_Alt:Text}
                            uploadAreaDesktopText={Upload_MachinePlateArea_Desktop_Text:Text}
                            uploadAreaMobileText={Upload_MachinePlateArea_Mobile_Text:Text}
                        />

                    <FileUpload
                            uploadLabel={Upload_MachineLabel:Text}
                            uploadImage={Upload_MachineImage:Src}
                            uploadAlt={Upload_MachineImage_Alt:Text}
                            uploadBtnText={Upload_MachineButton_Text:Text}
                            uploadSuccessAlertImg={Upload_MachineSuccess_Alert_Image:Src}
                            uploadSuccessAlertImgAlt={Upload_MachineSuccess_Alert_Image_Alt:Text}
                            uploadErrorAlertImg={Upload_MachineError_Alert_Image:Src}
                            uploadErrorAlertImgAlt={Upload_MachineError_Alert_Image_Alt:Text}
                            uploadAreaDesktopText={Upload_MachineArea_Desktop_Text:Text}
                            uploadAreaMobileText={Upload_MachineArea_Mobile_Text:Text}
                        />

                    <div className="field field-checkbox">
                      <label>
                          <span>{AcceptTerms_Checkbox_Label:Wysiwyg}</span>
                          <input type="checkbox"/>
                          <span className="checkmark"></span>
                      </label>
                    </div>

                /cp-scaffold */}
        
        <div className="large-card-main-content">
          <div>
            <img src={this.image} alt={this.alt} />
          </div>
          <div className="large-card-text">
            <p className="large-card-text-header">{this.header}</p>
            <p
              className="large-card-text-bold-desc"
              dangerouslySetInnerHTML={{ __html: this.description }}
            ></p>
          </div>
        </div>

        {/* <List name="CountryOptionsList" type="OptionsListItem"> */}
        <Form
          contactDetailsTextLabel={this.contactDetailsTextLabel}
          firstNameTextLabel={this.firstNameTextLabel}
          lastNameTextLabel={this.lastNameTextLabel}
          addressTextLabel={this.addressTextLabel}
          postalCodeTextLabel={this.postalCodeTextLabel}
          countrySelectLabel={this.countrySelectLabel}
          cityTextLabel={this.cityTextLabel}
          phoneNumberTextLabel={this.phoneNumberTextLabel}
          emailAddressTextLabel={this.emailAddressTextLabel}

          machineDetailsTextLabel={this.machineDetailsTextLabel}
          brandTextLabel={this.brandTextLabel}
          modelTypeTextLabel={this.modelTypeTextLabel}
          productionYearTextLabel={this.productionYearTextLabel}
          serialNumberTextLabel={this.serialNumberTextLabel}
          subjectLineTextLabel={this.subjectLineTextLabel}
          messageTextLabel={this.messageTextLabel}
          formId={this.formId}
          recepientEmail={this.recepientEmail}
          countryOptionsList={this.countryOptionsList}
          submitCtaText={this.submitCtaText}
          countryDefaultText={this.countryDefaultText}
          successAlertImg={this.successAlertImg}
          successAlertImgAlt={this.successAlertImgAlt}
          successAlertText={this.successAlertText}
          requiredFieldError={this.requiredFieldError}
          formValidError={this.formValidError}
          emailInvalidError={this.emailInvalidError}
          phoneNumberInvalidError={this.phoneNumberInvalidError}

          uploadNote={this.uploadNote}

          uploadDetailsTextLabel={this.uploadDetailsTextLabel}
          uploadMachinePlateLabel={this.uploadMachinePlateLabel}
          uploadMachinePlateAreaDesktopText={this.uploadMachinePlateAreaDesktopText}
          uploadMachinePlateAreaMobileText={this.uploadMachinePlateAreaMobileText}
          uploadMachinePlateBtnText={this.uploadMachinePlateBtnText}
          uploadMachinePlateImage={this.uploadMachinePlateImage}
          uploadMachinePlateAlt={this.uploadMachinePlateAlt}
          uploadMachinePlateSuccessAlertImg={this.uploadMachinePlateSuccessAlertImg}
          uploadMachinePlateSuccessAlertImgAlt={this.uploadMachinePlateSuccessAlertImgAlt}
          uploadMachinePlateErrorAlertImg={this.uploadMachinePlateErrorAlertImg}
          uploadMachinePlateErrorAlertImgAlt={this.uploadMachinePlateErrorAlertImgAlt}

          uploadMachineLabel={this.uploadMachineLabel}
          uploadMachineAreaDesktopText={this.uploadMachineAreaDesktopText}
          uploadMachineAreaMobileText={this.uploadMachineAreaMobileText}
          uploadMachineBtnText={this.uploadMachineBtnText}
          uploadMachineImage={this.uploadMachineImage}
          uploadMachineAlt={this.uploadMachineAlt}
          uploadMachineSuccessAlertImg={this.uploadMachineSuccessAlertImg}
          uploadMachineSuccessAlertImgAlt={this.uploadMachineSuccessAlertImgAlt}
          uploadMachineErrorAlertImg={this.uploadMachineErrorAlertImg}
          uploadMachineErrorAlertImgAlt={this.uploadMachineErrorAlertImgAlt}
          header={this.header}
          acceptTermsLabel={this.acceptTermsLabel}
        />
        {/* </List> */}
      </div>
    );
  }
}

function Form({
  contactDetailsTextLabel,
  firstNameTextLabel,
  lastNameTextLabel,
  addressTextLabel,
  postalCodeTextLabel,
  countrySelectLabel,
  cityTextLabel,

  phoneNumberTextLabel,
  emailAddressTextLabel,

  machineDetailsTextLabel,
  brandTextLabel,
  modelTypeTextLabel,
  productionYearTextLabel,
  serialNumberTextLabel,

  subjectLineTextLabel,
  messageTextLabel,

  formId,
  recepientEmail,
  submitCtaText,
  countryOptionsList,

  countryDefaultText,
  successAlertImg,
  successAlertImgAlt,
  successAlertText,
  requiredFieldError,
  formValidError,
  emailInvalidError,
  phoneNumberInvalidError,

  uploadNote,
  uploadDetailsTextLabel,
  uploadMachinePlateLabel,
  uploadMachinePlateAreaDesktopText,
  uploadMachinePlateAreaMobileText,
  uploadMachinePlateBtnText,
  uploadMachinePlateImage,
  uploadMachinePlateAlt,

  uploadMachinePlateSuccessAlertImg,
  uploadMachinePlateSuccessAlertImgAlt,
  uploadMachinePlateErrorAlertImg,
  uploadMachinePlateErrorAlertImgAlt,

  uploadMachineLabel,
  uploadMachineAreaDesktopText,
  uploadMachineAreaMobileText,
  uploadMachineBtnText,
  uploadMachineImage,
  uploadMachineAlt,

  uploadMachineSuccessAlertImg,
  uploadMachineSuccessAlertImgAlt,
  uploadMachineErrorAlertImg,
  uploadMachineErrorAlertImgAlt,
  header,
  acceptTermsLabel,
}) {
  const { accounts, accessToken } = useContext(UserContext);
  const tokenClaims = accounts.length > 0 ? accounts[0]["idTokenClaims"] : null;

  const { helpFiles } = useSelector(
    (state: RootState) => ({
      helpFiles: state.fleet.details.helpFiles,
    }),
    shallowEqual
  );

  const [state, setState] = useState({
    fields: {
      EmailAddress:
        tokenClaims != null ? tokenClaims["preferred_username"] : "",
      FirstName: tokenClaims != null ? tokenClaims["given_name"] : "",
      LastName: tokenClaims != null ? tokenClaims["family_name"] : null,
      SubjectLine: "Product registration Help",
    },
    errors: {
      LastName: "",
      FirstName: "",
      PostalCode: "",
      Country: "",
      // City: "",
      // Address: "",

      PhoneNumber: "",
      EmailAddress: "",

      SubjectLine: "",
      Message: "",
      acceptTerms: "",

      formValid: ""
    },
  });
  
  const [formDone, setFormDone] = useState(false);
  const [buttondisable, setbuttondisable] = useState(false);

  function handleChange(e, fieldId, field) {

    let fields = state.fields;
    if (field === "Country") {
      fields[field] = e.target.innerText;
    }
    else if(e.target.type == 'checkbox') {
      fields[field] = e.target.checked;
    }
    else {
      fields[field] = e.target.value;
    }

    setState({
      ...state,
      fields,
    });

    let errors = state.errors;
    if(errors.formValid){
      handleValidation(e);
    }
  }

  function handleValidation(e) {
    let fields = state.fields;
    let errors = {
      LastName: "",
      FirstName: "",
      Country: "",
      // City: "",
      // Address: "",
      PostalCode: "",

      PhoneNumber: "",
      EmailAddress: "",

      SubjectLine: "",
      Message: "",
      acceptTerms: "",
      formValid: "",
    };
    let formIsValid = true;
    
    if (!fields["FirstName"]) {
      formIsValid = false;
      errors["FirstName"] = requiredFieldError;
    }
    if (!fields["LastName"]) {
      formIsValid = false;
      errors["LastName"] = requiredFieldError;
    }
    // if (!fields["Address"]) {
    //   formIsValid = false;
    //   errors["Address"] = requiredFieldError;
    // }
    if (!fields["PostalCode"]) {
      formIsValid = false;
      errors["PostalCode"] = requiredFieldError;
    }

    if (!fields["Country"] || fields["Country"] === countryDefaultText) {
      formIsValid = false;
      errors["Country"] = requiredFieldError;
    }
    // if (!fields["City"]) {
    //   formIsValid = false;
    //   errors["City"] = requiredFieldError;
    // }

    if (!fields["SubjectLine"]) {
      formIsValid = false;
      errors["SubjectLine"] = requiredFieldError;
    }

    // if (!fields["Message"]) {
    //   formIsValid = false;
    //   errors["Message"] = requiredFieldError;
    // }

    //Email
    if (!fields["EmailAddress"]) {
      formIsValid = false;
      errors["EmailAddress"] = requiredFieldError;
    }
    if (typeof fields["EmailAddress"] !== "undefined") {
      let lastAtPos = fields["EmailAddress"].lastIndexOf("@");
      let lastDotPos = fields["EmailAddress"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["EmailAddress"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["EmailAddress"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["EmailAddress"] = emailInvalidError;
      }
    }

    //PhoneNumber
    if (typeof fields["PhoneNumber"] !== "undefined") {
      var pattern = new RegExp(/[^+]{1}[0-9\b]+$/);
      if (!pattern.test(fields["PhoneNumber"])) {
        formIsValid = false;
        errors["PhoneNumber"] = phoneNumberInvalidError;
      }
    }
    else{
      formIsValid = false;
      errors["PhoneNumber"] = requiredFieldError;
    }

    if (!fields["acceptTerms"]) {
      formIsValid = false;
      errors["acceptTerms"] = requiredFieldError;
    }

    if(!formIsValid){
      errors["formValid"] = formValidError;
    }

    setState({
      ...state,
      errors: errors,
    });

    return formIsValid;
  }

  function objectifyForm(formArray) {
    //serialize data function
    var formdata = new FormData();
    for (var i = 0; i < formArray.length; i++) {
      if (formArray[i]["name"] !== "fieldsubmit") {
        formdata.append(formArray[i]["name"], formArray[i]["value"]);
      }
    }
    formdata.append(
      "Country",
      document.querySelector(
        ".product-register-help-form .countrySelect .selected-text"
      )!.textContent!
    );

    return formdata;
  }
  const { user, userid } = useContext(UserContext);
  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();
    
    if (handleValidation(e)) {
      var form = document.querySelector("#productRegisterHelpForm");
      let formData = objectifyForm(form) as BodyInit;

      var requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow" as RequestRedirect,
      };
      console.log(recepientEmail);
      let fields = state.fields;

      var Fformdata: any = new FormData();

      //var userservice = new UserService(accessToken);

      helpFiles.forEach((file, i) => Fformdata.append(`Files`, file));

      var root = document.getElementById("root");
      setbuttondisable(true);
      var requestData: RequestHelpFormDto = {
        firstName: fields["FirstName"],
        lastName: fields["LastName"],
        email: fields["EmailAddress"],
        phone: fields["PhoneNumber"],
        country: fields["Country"],
        // subject: fields["SubjectLine"],
        subject: "Product registration Help",
        message: fields["Message"],
        language: window.location.pathname.split("/")[1],
        recepientEmail: recepientEmail["value"].slice(2, -2),
        header: header.value,
        source:
          root == null
            ? "Kverneland"
            : root.getAttribute("data-cms-wrapper-name"),
        Address: fields["Address"],
        postalCode: fields["PostalCode"],
        city: fields["City"],
        brand: fields["Brand"] ?? "",
        modelType: fields["modelType"] ?? "",
        productionYear: fields["productionYear"] ?? "",
        serialNumber: fields["serialNumber"] ?? "",
        files: helpFiles,
      };

      Object.keys(requestData).forEach((x) =>
        Fformdata.append(x, requestData[x])
      );

      fetch(`${config.runtime.urls.apiBaseUrl}User/RegisterHelpForm`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: Fformdata,
      })
        .then((x) => {
          if (x.status === 200) {
            setFormDone(true);
          }
        })

        .catch((e) => setFormDone(false));
    }
  }

  return !formDone ? (
    <form className="product-register-help-form" id="productRegisterHelpForm">
      <div>
        <p className="form-subheader">{contactDetailsTextLabel}</p>
      </div>

      <div className="grid-fields">
        <div className="field field-text">
          <label htmlFor="FirstName">
            {firstNameTextLabel}
            {state.errors.FirstName ? (
              <span className="field-error">{state.errors.FirstName}</span>
            ) : null}
          </label>
          <input
            type="text"
            name="FirstName"
            value={state["fields"]["FirstName"]}
            onChange={(e) => {
              handleChange(e, e.target.id, "FirstName");
            }}
          />
        </div>

        <div className="field field-text">
          <label htmlFor="LastName">
            {lastNameTextLabel}
            {state.errors.LastName ? (
              <span className="field-error">{state.errors.LastName}</span>
            ) : null}
          </label>
          <input
            type="text"
            value={state["fields"]["LastName"]}
            name="LastName"
            onChange={(e) => {
              handleChange(e, e.target.id, "LastName");
            }}
          />
        </div>
      </div>

      <div className="grid-fields">
        
        <div className="field field-select countrySelect">
          <label htmlFor="Country">
            {countrySelectLabel}
            {state.errors.Country ? (
              <span className="field-error">{state.errors.Country}</span>
            ) : null}
          </label>
          <FieldSelect
            name="Country"
            defaultText={countryDefaultText}
            optionsList={countryOptionsList.value}
            onChange={handleChange}
          />
        </div>

        <div className="field field-text">
          <label htmlFor="City">
            {cityTextLabel}
            {/* {state.errors.City ? (<span className="field-error">{state.errors.City}</span>) : null} */}
          </label>
          <input
            type="text"
            name="City"
            onChange={(e) => { handleChange(e, e.target.id, "City"); } }
          />
        </div>
      </div>

      <div className="grid-fields">
        <div className="field field-text">
          <label htmlFor="Address">
            {addressTextLabel}
            {/* {state.errors.Address ? (<span className="field-error">{state.errors.Address}</span>) : null} */}
          </label>
          <input
            type="text"
            name="Address"
            onChange={(e) => {
              handleChange(e, e.target.id, "Address");
            }}
          />
        </div>
        
        
        <div className="field field-text">
          <label htmlFor="PostalCode">
            {postalCodeTextLabel}
            {state.errors.PostalCode ? (
              <span className="field-error">{state.errors.PostalCode}</span>
            ) : null}
          </label>
          <input
            type="text"
            name="PostalCode"
            onChange={(e) => {
              handleChange(e, e.target.id, "PostalCode");
            }}
          />
        </div>
      </div>

      
      <div className="grid-fields">
        <div className="field field-tel">
          <label htmlFor="PhoneNumber">
            {phoneNumberTextLabel}
            {state.errors.PhoneNumber ? (<span className="field-error">{state.errors.PhoneNumber}</span>) : null}
          </label>
          <input
            type="tel"
            name="PhoneNumber"
            onChange={ (e) => { handleChange(e, e.target.id, "PhoneNumber"); }}
          />
        </div>
        {/* </div>
      <div className="grid-fields"> */}
        <div className="field field-email">
          <label htmlFor="EmailAddress">
            {emailAddressTextLabel}
            {state.errors.EmailAddress ? (
              <span className="field-error">{state.errors.EmailAddress}</span>
            ) : null}
          </label>
          <input
            type="email"
            name="EmailAddress"
            value={state["fields"]["EmailAddress"]}
            onChange={(e) => {
              handleChange(e, e.target.id, "EmailAddress");
            }}
          />
        </div>
      </div>
      <div className="mt-20">
        <p className="form-subheader">{machineDetailsTextLabel}</p>
      </div>
      <div className="grid-fields">
        <div className="field field-text">
          <label htmlFor="Brand">{brandTextLabel}</label>
          <input
            type="text"
            name="Brand"
            onChange={(e) => {
              handleChange(e, e.target.id, "Brand");
            }}
          />
        </div>
        <div className="field field-text">
          <label htmlFor="ModelType">{modelTypeTextLabel}</label>
          <input
            type="text"
            name="ModelType"
            onChange={(e) => {
              handleChange(e, e.target.id, "ModelType");
            }}
          />
        </div>
      </div>
      <div className="grid-fields">
        <div className="field field-text">
          <label htmlFor="ProductionYear">{productionYearTextLabel}</label>
          <input
            type="text"
            name="ProductionYear"
            onChange={(e) => {
              handleChange(e, e.target.id, "ProductionYear");
            }}
          />
        </div>
        <div className="field field-text">
          <label htmlFor="SerialNumber">{serialNumberTextLabel}</label>
          <input
            type="text"
            name="SerialNumber"
            onChange={(e) => {
              handleChange(e, e.target.id, "SerialNumber");
            }}
          />
        </div>
      </div>

      {/* <div className="field field-text">
        <label htmlFor="SubjectLine">
          {subjectLineTextLabel}
          {state.errors.SubjectLine ? (
            <span className="field-error">{state.errors.SubjectLine}</span>
          ) : null}
        </label>
        <input
          type="text"
          name="SubjectLine"
          onChange={(e) => {
            handleChange(e, e.target.id, "SubjectLine");
          }}
        />
      </div> */}
      <div className="mt-20">
        <p className="form-subheader2">{uploadDetailsTextLabel}</p>
      </div>
      <div className="field field-textarea">
        <FileUpload
          uploadLabel={uploadMachinePlateLabel}
          uploadImage={uploadMachinePlateImage}
          uploadAlt={uploadMachinePlateAlt}
          uploadBtnText={uploadMachinePlateBtnText}
          uploadSuccessAlertImg={uploadMachinePlateSuccessAlertImg}
          uploadSuccessAlertImgAlt={uploadMachinePlateSuccessAlertImgAlt}
          uploadErrorAlertImg={uploadMachinePlateErrorAlertImg}
          uploadErrorAlertImgAlt={uploadMachinePlateErrorAlertImgAlt}
          uploadAreaDesktopText={uploadMachinePlateAreaDesktopText}
          uploadAreaMobileText={uploadMachinePlateAreaMobileText}
          uploadNote={uploadNote}
        />

        <FileUpload
          uploadLabel={uploadMachineLabel}
          uploadImage={uploadMachineImage}
          uploadAlt={uploadMachineAlt}
          uploadBtnText={uploadMachineBtnText}
          uploadSuccessAlertImg={uploadMachineSuccessAlertImg}
          uploadSuccessAlertImgAlt={uploadMachineSuccessAlertImgAlt}
          uploadErrorAlertImg={uploadMachineErrorAlertImg}
          uploadErrorAlertImgAlt={uploadMachineErrorAlertImgAlt}
          uploadAreaDesktopText={uploadMachineAreaDesktopText}
          uploadAreaMobileText={uploadMachineAreaMobileText}
          uploadNote={uploadNote}
        />

        <div className="field field-textarea">
          <label htmlFor="Message">
            {messageTextLabel}
            {state.errors.Message ? (<span className="field-error">{state.errors.Message}</span>) : null}
          </label>
          <textarea name="Message" onChange={(e) => { handleChange(e, e.target.id, "Message"); }}></textarea>
        </div>
      </div>
      <div className="field field-submit">
        <input id="EmailRecipients" name="EmailRecipients" type="hidden" value={recepientEmail} />
        <input id="WcoFormId" name="WcoFormId" type="hidden" value={formId} />
        
        <div className="field field-checkbox">
          <label>
            {state.errors.acceptTerms ? <span className='field-error'>{state.errors.acceptTerms}</span> : null}
            <span dangerouslySetInnerHTML={{ __html: acceptTermsLabel }}></span>
            <input type="checkbox" onChange={(e) => { handleChange(e, e.target.id, "acceptTerms"); }} />
            <span className="checkmark"></span>
          </label>
        </div>
        
        {state.errors.formValid ? <span className='field-error'>{state.errors.formValid}</span> : null}
        <button type="submit" name="fieldsubmit" onClick={handleSubmit} disabled={buttondisable}>
          {submitCtaText}
        </button>

      </div>
    </form>
  ) : (
    <div className="alert-wrapper register-alert-wrapper">
      <div className="product-register-help-form-success">
        <img src={successAlertImg} alt={successAlertImgAlt} />
      </div>
      <p dangerouslySetInnerHTML={{ __html: successAlertText }}></p>
    </div>
  );
}

export const PRODUCT_FILE_SET = "PRODUCT_FILE_SET";
export const PRODUCT_HELP_FILES = "PRODUCT_HELP_FILES";
export const PRODUCT_FILE_SUBMIT = "PRODUCT_FILE_SUBMIT";
export const PRODUCT_FILE_SUBMITTED = "PRODUCT_FILE_SUBMITTED";

export const setFile = (file: File) => {
  return { type: PRODUCT_FILE_SET, file };
};
export const AddHelpFiles = (file: File) => {
  return { type: PRODUCT_HELP_FILES, file };
};

export const submitFile = (
  bearerToken: string,
  id: string
): AppThunk<Promise<void>> => {
  return async (dispatch, getState): Promise<void> => {
    dispatch({ type: PRODUCT_FILE_SUBMIT });
    const { file } = getState().fleet.details;
    try {
      // const cardService = new CardService(bearerToken);
      // const result = await cardService.updateCardFile(id, file);
      //dispatch({ type: PRODUCT_FILE_SUBMITTED, result });
    } catch (err: any) {
      dispatch({ type: PRODUCT_FILE_SUBMITTED, result: err.data.serverError });
    }
  };
};

export const FileUpload = ({
  uploadNote,
  uploadLabel,
  uploadImage,
  uploadAlt,
  uploadBtnText,
  uploadAreaDesktopText,
  uploadAreaMobileText,
  uploadSuccessAlertImg,
  uploadSuccessAlertImgAlt,
  uploadErrorAlertImg,
  uploadErrorAlertImgAlt,
}) => {

  const [fileHasError, setFileHasError] = useState(false);

  const [field, setField] = useState({
    uploadField: uploadAreaMobileText,
    dropzoneField: uploadAreaDesktopText,
    hasFile: false
  });
  const { user } = useContext(UserContext);
  const { fleetProduct, setFleetProduct } = useContext(UserContext);
  const match = { params: { id: fleetProduct } };
  const { value, fileDone, fileResult, saving, product, helpFiles } =
    useSelector(
      (state: RootState) => ({
        value: state.fleet.details.file,
        fileDone: state.fleet.details.fileDone,
        fileResult: state.fleet.details.fileResult,
        saving: state.fleet.details.saving,
        product: state.fleet.details.product,
        helpFiles: state.fleet.details.helpFiles,
      }),
      shallowEqual
    );
  const { accounts, accessToken } = useContext(UserContext);

  const dispatch = useDispatch();
  const callSetFile = (ev: any): void => {
    ev.preventDefault();
    if (ev && ev.target.files[0]) {
      dispatch(AddHelpFiles(ev.target.files[0]));
    }
  };
  const callSubmitFile = (ev, id: string): void => {
    ev.preventDefault();
    dispatch(submitFile(user.accessToken || "", id));
  };

  function changeLabel(event) {
    // eslint-disable-next-line no-undef
    console.log(helpFiles);

    if(event.target.files && event.target.files.length) {
      
      var fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if(fileSize > 3 || !(/\.(pbf|png|jpg|jpeg)$/i).test(event.target.value)){
        setFileHasError(true);
      }
      else{
        setFileHasError(false);
        setField({
          uploadField: event.target.files[0].name,
          dropzoneField: event.target.files[0].name,
          hasFile: true
        });
      }
    }    
  }

  return (
    <div className="form-wrapper">
      <div className="field-upload">
        <label className="field-upload-label">{uploadLabel}</label>
        
        <div className="field-upload-wrapper">
          <label
            className="field-upload-btn d-lg-none"
            htmlFor="field-upload"
            onChange={(ev) => {
              changeLabel(ev);
              callSetFile(ev);
            }}
            id="field-upload-label"
          >
            {field.uploadField}
            <input type="file" id="field-upload" />
          </label>

          <label
            className="field-drop-btn d-none d-lg-flex"
            htmlFor="field-upload-dropzone"
            onChange={(ev) => {
              changeLabel(ev);
              callSetFile(ev);
            }}
            id="field-upload-dropzone-label">

            <div>
              <img src={uploadImage} alt={uploadAlt} />
            </div>
            {field.dropzoneField}
            <input
              type="file"
              id="field-upload-dropzone"
              draggable
              className="field-upload-dropzone"
            />
          </label>
          <div className="field field-submit">
            {/* <button
              type="submit"
              name="fieldsubmit"
              disabled={!field.hasFile || saving}
              onClick={(e): void => callSubmitFile(e, match.params.id)}
            >
              {saving ? (
                <div className="loader btn-loader"></div>
              ) : (
                uploadBtnText
              )}
            </button> */}
          </div>
        </div>
        <label className={fileHasError? "field-error" : ""}>{uploadNote}</label>
      </div>

      {fileDone && fileResult ? (
        <div className="alert-wrapper upload-alert-wrapper">
          <div>
            {fileResult.severity === "0" ? (
              <img src={uploadSuccessAlertImg} alt={uploadSuccessAlertImgAlt} />
            ) : (
              <img src={uploadErrorAlertImg} alt={uploadErrorAlertImgAlt} />
            )}
          </div>
          <p>{fileResult.message}</p>
        </div>
      ) : null}
    </div>
  );
};
