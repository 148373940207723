import React, { useState, useContext } from "react";
import {
  CmsComponent,
  CmsDataCache,
  CmsField,
  CmsFieldTypes,
} from "crownpeak-dxm-react-sdk";
import { Link } from "react-router-dom";
import FieldSelect from "../form/field-select";
import UserContext from "../../providers/user-provider";
import { ContactRequest } from "../../types";
import UserService from "../../services/user-service";
import config from "../../configuration";

export default class ContactForm extends CmsComponent {
  image: any;
  alt: any;
  header: any;
  description: any;
  firstTextFieldLabel: any;
  secondTextFieldLabel: any;
  emailFieldLabel: any;
  dropdownFieldLabel: any;
  thirdTextFieldLabel: any;
  textAreaFieldLabel: any;
  formId: any;
  optionsList: any;
  submitCtaText: any;
  recepientEmail: any;
  successAlertImg: any;
  successAlertImgAlt: any;
  successAlertText: any;
  requiredFieldError: any;
  emailInvalidError: any;
  dropdownDefaultText: any;
  telInvalidError: any;
  telFieldLabel: any;
  constructor(props) {
    super(props);
    this.cmsFolder = "Section";
    this.cmsDisableDragDrop = false;
    this.cmsZones = ["droppable", "DragDropZone"];

    this.image = new CmsField(
      "Image",
      CmsFieldTypes.IMAGE,
      props && props.data ? props.data.Image : null
    );
    this.alt = new CmsField(
      "Alt",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Alt : null
    );
    this.header = new CmsField(
      "Header",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Header : null
    );
    this.description = new CmsField(
      "Description",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Description : null
    );

    this.firstTextFieldLabel = new CmsField(
      "First_Text_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.First_Text_Field_Label : null
    );
    this.secondTextFieldLabel = new CmsField(
      "Second_Text_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Second_Text_Field_Label : null
    );
    this.emailFieldLabel = new CmsField(
      "Email_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Email_Field_Label : null
    );
    this.dropdownFieldLabel = new CmsField(
      "Dropdown_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Dropdown_Field_Label : null
    );
    this.thirdTextFieldLabel = new CmsField(
      "Third_Text_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Third_Text_Field_Label : null
    );
    this.textAreaFieldLabel = new CmsField(
      "Text_Area_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Text_Area_Field_Label : null
    );
    this.formId = new CmsField(
      "Form_ID",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Form_ID : null
    );
    this.recepientEmail = new CmsField(
      "Recepient_Email",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Recepient_Email : null
    );
    this.submitCtaText = new CmsField(
      "Submit_Cta_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Submit_Cta_Text : null
    );
    this.optionsList = new CmsField(
      "Options_List",
      "Options_List_Item",
      CmsDataCache.get(CmsDataCache.cmsAssetId).ContactForm?.OptionsList ||
        CmsDataCache.get(CmsDataCache.cmsAssetId).DropZones.droppable[0]
          ?.ContactForm?.OptionsList ||
        CmsDataCache.get(CmsDataCache.cmsAssetId).DropZones.DragDropZone[0]
          ?.ContactForm?.OptionsList ||
        []
    );
    this.successAlertText = new CmsField(
      "Success_Alert_Text",
      CmsFieldTypes.WYSIWYG,
      props && props.data ? props.data.Success_Alert_Text : null
    );
    this.successAlertImg = new CmsField(
      "Success_Alert_Image",
      CmsFieldTypes.IMAGE,
      props && props.data ? props.data.Success_Alert_Image : null
    );
    this.successAlertImgAlt = new CmsField(
      "Success_Alert_Image_Alt",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Success_Alert_Image_Alt : null
    );

    this.requiredFieldError = new CmsField(
      "Required_Field_Error_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Required_Field_Error_Text : null
    );
    this.emailInvalidError = new CmsField(
      "Email_Invalid_Error_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Email_Invalid_Error_Text : null
    );
    this.dropdownDefaultText = new CmsField(
      "Dropdown_Default_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Dropdown_Default_Text : null
    );

    this.telFieldLabel = new CmsField(
      "Telephone_Field_Label",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Telephone_Field_Label : null
    );
    this.telInvalidError = new CmsField(
      "Telephone_Invalid_Error_Text",
      CmsFieldTypes.TEXT,
      props && props.data ? props.data.Telephone_Invalid_Error_Text : null
    );
  }

  render() {
    return (
      <div className="contact">
        {/* cp-scaffold 
                    {First_Text_Field_Label:Text}
                    {Second_Text_Field_Label:Text}
                    {Email_Field_Label:Text}
                    {Dropdown_Field_Label:Text}
                    {Third_Text_Field_Label:Text}
                    {Text_Area_Field_Label:Text}
                    {Submit_Cta_Text:Text}
                    {Form_ID:Text}
                    {Recepient_Email:Text}
                    {Success_Alert_Text:Text}
                    {Success_Alert_Image:Src}
                    {Success_Alert_Image_Alt:Text}
                    {Required_Field_Error_Text:Text}
                    {Email_Invalid_Error_Text:Text}
                    {Dropdown_Default_Text:Text}
                    {Telephone_Field_Label:Text}
                    {Telephone_Invalid_Error_Text:Text}
                /cp-scaffold */}
        <div className="large-card-main-content">
          <div>
            <img src={this.image} alt={this.alt} />
          </div>
          <div className="large-card-text">
            <p className="large-card-text-header">{this.header}</p>
            <p className="large-card-text-desc">{this.description}</p>
          </div>
        </div>

        {/* <List name="OptionsList" type="OptionsListItem"> */}
        <Form
          firstTextFieldLabel={this.firstTextFieldLabel}
          secondTextFieldLabel={this.secondTextFieldLabel}
          emailFieldLabel={this.emailFieldLabel}
          dropdownFieldLabel={this.dropdownFieldLabel}
          thirdTextFieldLabel={this.thirdTextFieldLabel}
          textAreaFieldLabel={this.textAreaFieldLabel}
          formId={this.formId}
          recepientEmail={this.recepientEmail}
          optionsList={this.optionsList}
          submitCtaText={this.submitCtaText}
          dropdownDefaultText={this.dropdownDefaultText}
          successAlertImg={this.successAlertImg}
          successAlertImgAlt={this.successAlertImgAlt}
          successAlertText={this.successAlertText}
          requiredFieldError={this.requiredFieldError}
          emailInvalidError={this.emailInvalidError}
          telFieldLabel={this.telFieldLabel}
          telInvalidError={this.telInvalidError}
          header={this.header}
        />
        {/* </List> */}
      </div>
    );
  }
}

function Form({
  firstTextFieldLabel,
  secondTextFieldLabel,
  emailFieldLabel,
  dropdownFieldLabel,
  thirdTextFieldLabel,
  textAreaFieldLabel,
  formId,
  recepientEmail,
  submitCtaText,
  optionsList,
  dropdownDefaultText,
  successAlertImg,
  successAlertImgAlt,
  successAlertText,
  requiredFieldError,
  emailInvalidError,
  telFieldLabel,
  telInvalidError,
  header,
}) {
  const { accounts, accessToken } = useContext(UserContext);
  const tokenClaims = accounts.length > 0 ? accounts[0]["idTokenClaims"] : null;

  const [state, setState] = useState({
    fields: {
      Email: tokenClaims != null ? tokenClaims["preferred_username"] : "",
      FirstName: tokenClaims != null ? tokenClaims["given_name"] : "",
      LastName: tokenClaims != null ? tokenClaims["family_name"] : null,
    },
    errors: {
      FirstName: "",
      LastName: "",
      Email: "",
      dropdown: "",
      SubjectLine: "",
      Message: "",
      Tel: "",
    },
  });

  const [formDone, setFormDone] = useState(false);
  const [buttondisable, setbuttondisable] = useState(false);

  function handleChange(e, fieldId, field) {
    let fields = state.fields;
    if (field === "dropdown") {
      fields["dropdown"] = e.target.innerText;
    } else {
      fields[field] = e.target.value;
    }

    setState({
      ...state,
      fields,
    });
  }

  function handleValidation(e) {
    let fields = state.fields;
    let errors = {
      FirstName: "",
      LastName: "",
      Email: "",
      Tel: "",
      dropdown: "",
      SubjectLine: "",
      Message: "",
    };
    let formIsValid = true;

    //Checkboxes
    if (!fields["FirstName"]) {
      formIsValid = false;
      errors["FirstName"] = requiredFieldError;
    }

    if (!fields["LastName"]) {
      formIsValid = false;
      errors["LastName"] = requiredFieldError;
    }

    if (!fields["Tel"]) {
      formIsValid = false;
      errors["Tel"] = requiredFieldError;
    }

    if (!fields["dropdown"] || fields["dropdown"] === dropdownDefaultText) {
      formIsValid = false;
      errors["dropdown"] = requiredFieldError;
    }
    if (!fields["SubjectLine"]) {
      formIsValid = false;
      errors["SubjectLine"] = requiredFieldError;
    }
    if (!fields["Message"]) {
      formIsValid = false;
      errors["Message"] = requiredFieldError;
    }

    //Email
    if (!fields["Email"]) {
      formIsValid = false;
      errors["Email"] = requiredFieldError;
    }
    if (typeof fields["Email"] !== "undefined") {
      let lastAtPos = fields["Email"].lastIndexOf("@");
      let lastDotPos = fields["Email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["Email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["Email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["Email"] = emailInvalidError;
      }
    }

    //Tel
    if (typeof fields["Tel"] !== "undefined") {
      var pattern = new RegExp(/[^+]{1}[0-9\b]+$/);

      if (!pattern.test(fields["Tel"])) {
        formIsValid = false;

        errors["Tel"] = telInvalidError;
      }
    }

    setState({
      ...state,
      errors: errors,
    });

    return formIsValid;
  }
  function objectifyForm(formArray) {
    //serialize data function
    var formdata = new FormData();

    for (var i = 0; i < formArray.length; i++) {
      if (formArray[i]["name"] !== "fieldsubmit") {
        formdata.append(formArray[i]["name"], formArray[i]["value"]);
      }
    }
    formdata.append(
      "Country",
      document.querySelector(".contact-form .field-select .selected-text")!
        .textContent!
    );

    return formdata;
  }
  function handleSubmit(e) {
    e.preventDefault();
    if (handleValidation(e)) {
      var form = document.querySelector("#contactForm");
      let formData = objectifyForm(form) as BodyInit;

      var requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow" as RequestRedirect,
      };

      // fetch("https://snippet.omm.crownpeak.com/p/17792bf3-8ee0-46a0-8aa7-5b013cf230b4", requestOptions)
      //     .then(response => {
      //         response.text();
      //         if (response.status === 200) {
      //             setFormDone(true)
      //         } else {
      //             setFormDone(false)
      //         }
      //     })
      //     .then(result => { })
      //     .catch(error => { });

      console.log(recepientEmail);
      let fields = state.fields;

      var userservice = new UserService(accessToken);

      var root = document.getElementById("root");
      setbuttondisable(true);
      var x: ContactRequest = {
        firstName: fields["FirstName"],
        lastName: fields["LastName"],
        email: fields["Email"],
        phone: fields["Tel"],
        country: fields["dropdown"],
        subject: fields["SubjectLine"],
        message: fields["Message"],
        language: window.location.pathname.split("/")[1],
        recepientEmail: recepientEmail["value"].slice(2, -2),
        header: header.value,
        source:
          root == null
            ? "Kverneland"
            : root.getAttribute("data-cms-wrapper-name"),
      };
      console.log("XXXXX", x);
      fetch(`${config.runtime.urls.apiBaseUrl}user/contact`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "Application/Json",
        },
        body: JSON.stringify(x),
      })
        .then((x) => {
          if (x.status === 200) {
            setFormDone(true);
          }
        })

        .catch((e) => setFormDone(false));
    }
  }

  return !formDone ? (
    <form className="contact-form" id="contactForm">
      <div className="grid-fields">
        <div className="field field-text">
          <label htmlFor="FirstName">
            {firstTextFieldLabel}
            {state.errors.FirstName ? (
              <span className="field-error">{state.errors.FirstName}</span>
            ) : null}
          </label>
          <input
            type="text"
            value={state.fields.FirstName}
            name="FirstName"
            onChange={(e) => {
              handleChange(e, e.target.id, "FirstName");
            }}
          />
        </div>

        <div className="field field-text">
          <label htmlFor="LastName">
            {secondTextFieldLabel}
            {state.errors.LastName ? (
              <span className="field-error">{state.errors.LastName}</span>
            ) : null}
          </label>
          <input
            type="text"
            name="LastName"
            value={state.fields.LastName}
            onChange={(e) => {
              handleChange(e, e.target.id, "LastName");
            }}
          />
        </div>
      </div>

      <div className="grid-fields">
        <div className="field field-email">
          <label htmlFor="Email">
            {emailFieldLabel}
            {state.errors.Email ? (
              <span className="field-error">{state.errors.Email}</span>
            ) : null}
          </label>
          <input
            type="email"
            name="Email"
            value={state.fields.Email}
            onChange={(e) => {
              handleChange(e, e.target.id, "Email");
            }}
          />
        </div>
        <div className="field field-tel">
          <label htmlFor="Tel">
            {telFieldLabel}
            {state.errors.Tel ? (
              <span className="field-error">{state.errors.Tel}</span>
            ) : null}
          </label>
          <input
            type="tel"
            name="Tel"
            onChange={(e) => {
              handleChange(e, e.target.id, "Tel");
            }}
          />
        </div>
      </div>
      <div className="field field-select">
        <label htmlFor="dropdown">
          {dropdownFieldLabel}
          {state.errors.dropdown ? (
            <span className="field-error">{state.errors.dropdown}</span>
          ) : null}
        </label>
        <FieldSelect
          defaultText={dropdownDefaultText}
          optionsList={optionsList.value}
          onChange={handleChange}
        />
      </div>
      <div className="field field-text">
        <label htmlFor="SubjectLine">
          {thirdTextFieldLabel}
          {state.errors.SubjectLine ? (
            <span className="field-error">{state.errors.SubjectLine}</span>
          ) : null}
        </label>
        <input
          type="text"
          name="SubjectLine"
          onChange={(e) => {
            handleChange(e, e.target.id, "SubjectLine");
          }}
        />
      </div>

      <div className="field field-textarea">
        <label htmlFor="Message">
          {textAreaFieldLabel}
          {state.errors.Message ? (
            <span className="field-error">{state.errors.Message}</span>
          ) : null}
        </label>
        <textarea
          name="Message"
          onChange={(e) => {
            handleChange(e, e.target.id, "Message");
          }}
        ></textarea>
      </div>
      <div className="field field-submit">
        <input
          id="EmailRecipients"
          name="EmailRecipients"
          type="hidden"
          value={recepientEmail}
        />
        <input id="WcoFormId" name="WcoFormId" type="hidden" value={formId} />
        <button
          type="submit"
          disabled={buttondisable}
          name="fieldsubmit"
          onClick={handleSubmit}
        >
          {submitCtaText}
        </button>
      </div>
    </form>
  ) : (
    <div className="alert-wrapper register-alert-wrapper">
      <div className="contact-form-success">
        <img src={successAlertImg} alt={successAlertImgAlt} />
      </div>
      <p dangerouslySetInnerHTML={{ __html: successAlertText }}></p>
    </div>
  );
}
