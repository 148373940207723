import * as ActionTypes from "./actions";
import { FleetDetailState, FleetDetailAction } from "./types";

const createInitialState = (): FleetDetailState => ({
  product: null,
  failed: false,
  file: null,
  helpFiles: [],
  saving: false,
  fileDone: false,
  fileResult: null,
  loading: true,
});

export default function (
  state = createInitialState(),
  action: FleetDetailAction
): FleetDetailState {
  switch (action.type) {
    case ActionTypes.PRODUCT_LOADED: {
      return { ...state, product: action.product, loading: false };
    }
    case ActionTypes.PRODUCT_LOADING: {
      return { ...createInitialState(), loading: true };
    }
    case ActionTypes.PRODUCT_LOAD_FAILED: {
      return { ...state, failed: true, loading: false };
    }
    case ActionTypes.PRODUCT_FILE_SET: {
      return { ...state, file: action.file, fileDone: false };
    }
    case ActionTypes.PRODUCT_HELP_FILES: {
      var x = state["helpFiles"];
      return { ...state, helpFiles: [...state["helpFiles"], action.file] };
    }
    case ActionTypes.PRODUCT_FILE_SUBMIT: {
      return { ...state, saving: true, fileDone: false };
    }
    case ActionTypes.PRODUCT_FILE_SUBMITTED: {
      return {
        ...state,
        saving: false,
        fileDone: true,
        fileResult: action.result,
      };
    }
    default: {
      return state;
    }
  }
}
