import { CardService } from '../../../services';
import { AppThunk } from '../../../controllers/store';

export const MY_FLEET_LOADING = 'MY_FLEET_LOADING';
export const MY_FLEET_LOADED = 'MY_FLEET_LOADED';

export const load = (bearerToken: string, lang: string): AppThunk => {
  return async (dispatch: any): Promise<void> => {
    dispatch({ type: MY_FLEET_LOADING });
    const cardService = new CardService(bearerToken);

    const cards = await cardService.getMyProducts(lang);

    dispatch({
      type: MY_FLEET_LOADED,
      cards
    });
  };
};
