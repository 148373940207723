import { combineReducers } from 'redux';

import register from '../pages/register/reducer';
import fleet from '../pages/fleet/reducer';
import global from '../global-reducer';
import events from '../pages/events/reducer';
import parts from '../pages/parts/reducer';
import contact from '../pages/contact/reducer';

const reducers = combineReducers({
  contact,
  events,
  parts,
  fleet,
  global,
  register
});

export default reducers;
