import React, { useContext } from 'react'
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
import UserContext, { UserProvider } from '../../providers/user-provider';
import { click } from '@testing-library/user-event/dist/click';

export default class WYSIWYGSectionHeader extends CmsComponent {
    image: any;
    alt: any;
    header: any;
    description: any;
    hasEditProfile: boolean;
    isProfileEdited: boolean;
    
    componentDidMount() {
        
        if(window.location.href.indexOf("terrako-user") !== -1){
            $("a[href='#terrako_b2c-edit-profile']").each((i, element)=> {
                element.onclick = function (e) { e.preventDefault(); $("#terrako-user_b2c-edit-profile")[0].click(); }    
            });

            // if(this.isProfileEdited){
            //     $("#b2c-log-out")[0].click();
            // }
        }
    }

    constructor(props) {
        super(props);
        this.cmsFolder = "Section";
        this.cmsDisableDragDrop = false;
        this.cmsZones = ["droppable", "DragDropZone"];

        this.image = new CmsField("Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Image : null);
        this.alt = new CmsField("Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Alt : null);
        this.header = new CmsField("Header", CmsFieldTypes.WYSIWYG, props && props.data ? props.data.Header : null);
        this.description = new CmsField("Description", CmsFieldTypes.WYSIWYG, props && props.data ? props.data.Description : null);

        this.hasEditProfile = this.description.value.indexOf("#terrako_b2c-edit-profile") != -1;
        this.isProfileEdited = sessionStorage["isterrakoEditProfile"] == "Clicked";
    }

    render() {
        return (
            <div className="register-product">
                {/* cp-scaffold
                    <div className="large-card-main-content">
                        <div>
                            <img src="{Image:Src}" alt="{Alt:Text}" />
                        </div>
                        <div className="large-card-text">
                            <p className="large-card-text-header">{Header:Wysiwyg}</p>
                            <p className="large-card-text-desc">{Description:Wysiwyg}</p>
                        </div>
                    </div>
                /cp-scaffold */}
            
                <div className="large-card-main-content">
                    <div>
                        <img src={this.image} alt={this.alt} />
                    </div>
                    <div className="large-card-text">
                        <p className="large-card-text-header" dangerouslySetInnerHTML={{ __html: this.header }}></p>
                        <p className="large-card-text-desc" dangerouslySetInnerHTML={{ __html: this.description }}></p>
                    </div>
                </div>

                { this.hasEditProfile && <EditProfile /> }
                { this.isProfileEdited && <ProfileEdited /> }
            </div>
        )
    }
}

function EditProfile(){
    const { user } = useContext(UserContext);
    return (
        <a id='terrako-user_b2c-edit-profile' onClick={(e) => { e.preventDefault(); user.terrakoEditProfile(window.localStorage.lang); }}></a> 
    );
}

function ProfileEdited(){
    const { user } = useContext(UserContext);
    sessionStorage.removeItem("isterrakoEditProfile");
    //user.signIn(window.localStorage.lang);
    user.logout();
    return (
        <a></a>
    );
}