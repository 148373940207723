import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import RenderRoutes from "./renderRoutes";
import KvgHomePage from "../pages/kvgHomePage";
import ViconHomePage from "../pages/viconHomePage";
import KvgSidebarPage from "../pages/kvgSidebarPage";
import ViconSidebarPage from "../pages/viconSidebarPage";
import KvgUserHomePage from "../pages/kvgUserHomePage";
import KvgPageBuilder from "../pages/kvgPageBuilder";
import ViconUserHomePage from "../pages/viconUserHomePage";
import UserContext, { UserProvider } from "../providers/user-provider";
import config from "../configuration";
import { JsConfig, JsMsalConfig } from "../types";
import "../controllers/vendor";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "date-input-polyfill-react";
import { Provider } from "react-redux";
import { closeCookieWarning, initApp, updateRoute } from "../actions";
import { store } from "../controllers/store";
import { cache, getCache } from "../utils/cache-data";
import KvgPageNotFound from "../pages/kvgPageNotFound";
import ViconPageNotFound from "../pages/viconPageNotFound";
import ViconPageBuilder from "../pages/viconPageBuilder";
import KvgNotificationPage from "../pages/kvgNotificationPage";
import ViconNotificationPage from "../pages/viconNotificationPage";

import { initAnalytics } from "../controllers/analytics";
import initClickDimension from "../controllers/click-dimensions";


// MSAL Auth Integration
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../configuration";

const msalInstance = new PublicClientApplication(msalConfig);
let urlLang =
  localStorage.getItem("lang") ||
  window.location.pathname.split("/")[1] ||
  "en-ww";

export default class Routing {
  static _routes: any;
  static _languages: any;
  static set routes(r) {
    this._routes = r;
  }
  static set languages(l) {
    this._languages = l;
  }

  static get routes() {
    return this._routes || [];
  }
  static get languages() {
    return this._languages || [];
  }

  static config1 = config as JsConfig;
  static oidcMsal = Routing.config1.oidc as JsMsalConfig;

  static getCmsAssetId(path: any) {
    return (Routing.routes.find((r: any) => r.path === path) || {}).cmsassetid;
  }
  static getCmsAssetIdNotFound(path: any, lang: any) {
    return (
      Routing.routes.find((r: any) => r.path === path && r.lang === lang) || {}
    ).cmsassetid;
  }

  static processRoutes(routeFile: any) {
    const componentRegistry: any = {
      KvgHomePage: {
        component: <KvgHomePage />,
      },
      ViconHomePage: {
        component: <ViconHomePage />,
      },
      KvgSidebarPage: {
        component: <KvgSidebarPage />,
      },
      ViconSidebarPage: {
        component: <ViconSidebarPage />,
      },
      ViconUserHomePage: {
        component: <ViconUserHomePage />,
      },
      KvgUserHomePage: {
        component: <KvgUserHomePage />,
      },
      KvgPageBuilder: {
        component: <KvgPageBuilder />,
      },
      KvgNotificationPage: {
        component: <KvgNotificationPage />,
      },
      ViconNotificationPage: {
        component: <ViconNotificationPage />,
      },
      KvgPageNotFound: {
        component: <KvgPageNotFound />,
      },
      ViconPageNotFound: {
        component: <ViconPageNotFound />,
      },
      ViconPageBuilder: {
        component: <ViconPageBuilder />,
      },
    };

    fetch(routeFile)
      .then((res) => res.json())
      .then((routeData) => {
        const route = {
          path: "/",
          exact: true,
          element: CheckRootPath(),
        };
        Routing.routes.push(route);
        Object.keys(routeData).forEach(function (key) {
          if (!routeData[key].path.includes("404")) {
            const route = {
              path: routeData[key].path,
              exact: routeData[key].exact === true,
              element:
                routeData[key].path === `/${urlLang}/` ? (
                  componentRegistry[routeData[key].component].component
                ) : (
                  <MSALRoute>
                    {componentRegistry[routeData[key].component].component}
                  </MSALRoute>
                ),
              cmsassetid: routeData[key].cmsassetid,
            };
            if (
              routeData[key].path.split("/").filter((item) => item).length === 1
            ) {
              const subRoute = {
                path: `/${routeData[key].path.split("/")[1]}`,
                exact: routeData[key].exact === true,
                element:
                  routeData[key].path === `/${urlLang}/` ? (
                    componentRegistry[routeData[key].component].component
                  ) : (
                    <MSALRoute>
                      {componentRegistry[routeData[key].component].component}
                    </MSALRoute>
                  ),
                cmsassetid: routeData[key].cmsassetid,
              };
              Routing.routes.push(subRoute);
            }

            Routing.routes.push(route);
          }
        });

        for (const key in routeData) {
          if (routeData[key].path.includes("404")) {
            const route = {
              path: "*",
              exact: routeData[key].exact === true,
              element: componentRegistry[routeData[key].component].component,
              cmsassetid: routeData[key].cmsassetid,
              lang: routeData[key].path.split("/")[1],
            };
            Routing.routes.push(route);
          }
          const language = routeData[key].path.split("/")[1];
          Routing.languages.push(language);
        }
        Routing.languages = [...new Set(Routing.languages)];
        ReactDOM.render(
          <Provider store={store}>
            <MsalProvider instance={msalInstance}>
              <UserProvider languages={Routing.languages}>
                <BrowserRouter>
                  <LayoutRedirection RoutingRoutes={Routing.routes} />
                </BrowserRouter>
              </UserProvider>
            </MsalProvider>
          </Provider>,
          document.getElementById("root")
        );
      })
      .catch(console.log);
  }
}

Routing.routes = [];
Routing.languages = [];

function LayoutRedirection({ RoutingRoutes }): any {
  let location = useLocation();
  let { dealer } = getCache();
  const { site, cookieClosed, lang } = getCache();

  if (!lang) {
    cache("lang", config.defaultLanguage);
  }

  // initClickDimension();

  useEffect(() => {
    initAnalytics(site);
  }, [location]);

  if (cookieClosed) {
    store.dispatch(closeCookieWarning());
  }
  if (dealer === "null" || dealer === "undefined") {
    dealer = null;
  }

  initApp(site, lang || window.localStorage.lang || config.defaultLanguage)(
    store.dispatch,
    () => store.getState(),
    undefined
  );

  return <RenderRoutes routes={RoutingRoutes} />;
}
function CheckRootPath() {
  return (
    <div>
      <AuthenticatedTemplate>
        <Navigate to={`/${urlLang}/my-account`} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to={`/${urlLang}/`} />
      </UnauthenticatedTemplate>
    </div>
  );
}
function MSALRoute({ children }) {
  const { accounts, user } = React.useContext(UserContext);

  useEffect(() => {
    if (accounts.length && accounts[0]["idTokenClaims"]) {
      const isEmployee =
        accounts[0]["idTokenClaims"]?.preferred_username.split("@")[1] ===
        "kvernelandgroup.com";

      if (!isEmployee) {
        const allowMyKvgSignUpComplete = accounts[0]["idTokenClaims"]?.allowMyKvgSignUpComplete;
        
        // if(window.location.href.indexOf("terrako-user") !== -1 && localStorage["isterrakoEditProfile"] == "Clicked"){
        //     var lang =  localStorage["lang"];
        //     localStorage.clear();
        //     localStorage["lang"]  = lang;
            
        //     user.signIn(lang);
        // }
        // else 

        if (window.location.href.indexOf("terrako-user") === -1 && allowMyKvgSignUpComplete) {
          window.location.href = `/${urlLang}/terrako-user`;
        }
        else if (window.location.href.indexOf("terrako-user") !== -1 && !allowMyKvgSignUpComplete) {
          window.location.href = `/${urlLang}/my-account`;
        }
      }
    }
  });

  return (
    <div>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to={`/${urlLang}/`} />
      </UnauthenticatedTemplate>
    </div>
  );
}
