import React from 'react';
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
import { Link } from 'react-router-dom';
import UserContext from '../../providers/user-provider';

export default class HorizontalCard extends CmsComponent {
    image: any;
    alt: any;
    header: any;
    description: any;
    cta_text: any;
    cta_link: any;
    state = {
        user: {} as any
    }
    constructor(props) {
        super(props);
        this.cmsFolder = "Card";
        this.cmsZones = ["droppable", "DragDropZone"];
        this.cmsDisableDragDrop = false;

        this.image = new CmsField("Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Image : null);
        this.alt = new CmsField("Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Alt : null);
        this.header = new CmsField("Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Header : null);
        this.description = new CmsField("Description", CmsFieldTypes.TEXT, props && props.data ? props.data.Description : null);
        this.cta_text = new CmsField("CTA_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.CTA_Text : null);
        this.cta_link = new CmsField("CTA_Link", CmsFieldTypes.HREF, props && props.data ? props.data.CTA_Link : null);
    }
    static contextType = UserContext;
    componentDidMount() {
        const { user } = this.context;
        this.setState({ user: user })
    }
    render() {
        return (
            <React.Fragment>
              
                {/* cp-scaffold
                    <a href="{CTA_Link:Href}" className="horizontal-card-link-wrapper" target="_blank" >
                        <div className="horizontal-card-wrapper">   
                            <div className="horizontal-card-content">
                                <div>
                                    <img src="{Image:Src}" alt="{Alt:Text}" />
                                </div>
                                <div className="horizontal-card-text">
                                    <p className="horizontal-card-text-header">{Header:Text}</p>
                                    <p className="horizontal-card-text-desc">{Description:Text}</p>
                                </div>
                            </div>

                            <div className="horizontal-card-link">

                                <span>{CTA_Text:Text} <span className="round-arrow"><i className="arrow right border-white"></i></span></span>

                            </div>
                        </div>   
                    </a>
                else */}
                    {this.cta_link.value.includes('https') ?

                        <a
                            href={this.cta_link} className="horizontal-card-link-wrapper"
                            onClick={(e) => {
                                if (this.cta_link.value.includes('#b2c-login')) {
                                    e.preventDefault();
                                    this.state.user.signIn(window.localStorage.lang);
                                } else if (this.cta_link.value.includes('#b2c-register')) {
                                    e.preventDefault();
                                    this.state.user.signUp(window.localStorage.lang);
                                }
                            }}
                            target="_blank" rel="noreferrer" 
                        >
                            <div className="horizontal-card-wrapper">
                                <div className="horizontal-card-content">
                                    <div className='icon-svg'>
                                        <img src={this.image} alt={this.alt} />
                                    </div>
                                    <div className="horizontal-card-text">
                                        <p className="horizontal-card-text-header">{this.header}</p>
                                        <p className="horizontal-card-text-desc">{this.description}</p>
                                    </div>
                                </div>

                                <div className="horizontal-card-link">

                                    <span>{this.cta_text} <span className="round-arrow"><i className="arrow right border-white"></i></span></span>

                                </div>
                            </div>
                        </a>
                        :
                        <Link to={this.cta_link.value} className="horizontal-card-link-wrapper"
                            onClick={(e) => {
                                if (this.cta_link.value.includes('#b2c-login')) {
                                    e.preventDefault();
                                    this.state.user.signIn(window.localStorage.lang);
                                } else if (this.cta_link.value.includes('#b2c-register')) {
                                    e.preventDefault();
                                    this.state.user.signUp(window.localStorage.lang);
                                }
                            }}
                        >
                            <div className="horizontal-card-wrapper">
                                <div className="horizontal-card-content">
                                    <div className='icon-svg'>
                                        <img src={this.image} alt={this.alt} />
                                    </div>
                                    <div className="horizontal-card-text">
                                        <p className="horizontal-card-text-header">{this.header}</p>
                                        <p className="horizontal-card-text-desc">{this.description}</p>
                                    </div>
                                </div>

                                <div className="horizontal-card-link">
                                <span>{this.cta_text} <span className="round-arrow"><i className="arrow right border-white"></i></span></span>
                                </div>
                            </div>
                        </Link>
                    }
                {/* /cp-scaffold */}
            </React.Fragment>

        )
    }
}
