import * as ActionTypes from './actions';
import { EventsState, EventsAction } from './types';

const createInitialState = (): EventsState => ({
  loading: true,
  entries: []
});

export default function (
  state = createInitialState(),
  action: EventsAction
): EventsState {
  switch (action.type) {
    default: {
      return state;
    }
    case ActionTypes.EVENTS_ENTRIES_LOADING: {
      return { ...state, loading: true };
    }
    case ActionTypes.EVENTS_ENTRIES_LOADED: {
      return { ...state, loading: false, entries: action.entries };
    }
  }
}
