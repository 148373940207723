"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var cmsPage_1 = require("./cmsPage");
var crownpeak_dxm_sdk_core_1 = require("crownpeak-dxm-sdk-core");
var CmsStaticPage = /** @class */ (function (_super) {
    __extends(CmsStaticPage, _super);
    function CmsStaticPage(props) {
        var _this = _super.call(this, props) || this;
        _this.cmsDataProvider = new crownpeak_dxm_sdk_core_1.CmsStaticDataProvider();
        return _this;
    }
    CmsStaticPage.load = function (assetId, useState, useEffect, timeout, loadedCallback, errorCallback, beforeLoadingCallback) {
        return cmsPage_1.default.loadForProvider(new crownpeak_dxm_sdk_core_1.CmsStaticDataProvider(), assetId, useState, useEffect, timeout, loadedCallback, errorCallback, beforeLoadingCallback);
    };
    CmsStaticPage.loadSync = function (assetId, beforeLoadingCallback) {
        cmsPage_1.default.loadForProviderSync(new crownpeak_dxm_sdk_core_1.CmsStaticDataProvider(), assetId, beforeLoadingCallback);
    };
    return CmsStaticPage;
}(cmsPage_1.default));
exports.default = CmsStaticPage;
