import React from 'react'
import { CmsDynamicPage, CmsStaticPage } from 'crownpeak-dxm-react-sdk';
import Routing from "../core/routing";
import DropZone from "../components/functional/dropZone";
import HeaderNavigation from '../components/global/header-navigation';
import FooterNavigation from '../components/global/footer-navigation';
import BackgroundImage from '../components/global/backgroundImage';
import NotFound from '../components/global/not-found';


export default class ViconPageNotFound extends CmsStaticPage {
    constructor(props: any) {
        super(props);
        this.cmsSuppressFolder = true
        this.cmsUseTmf = true;
        this.cmsWrapper = "Vicon";
        this.cmsUseMetadata = true;
        this.cmsAssetId = Routing.getCmsAssetIdNotFound(`*`, localStorage.getItem('lang') || Routing.languages.filter(item => item === window.location.pathname.split('/')[1])[0] || 'en-ww');
    }

    render() {
        super.render();
        return (
            this.state.isLoaded &&
            <div className={window.localStorage.lang}>

                <header className="vicon-header header">
                    <div className='vicon-upper-header'></div>
                    <HeaderNavigation dataProvider={this.cmsDataProvider} />
                </header>

                <main id="main-content" className="main-content" role="main" aria-label="main content">
                    <div className="cp-main-container vicon-wrapper vicon-not-found">
                        <BackgroundImage dataProvider={this.cmsDataProvider} assetId={this.cmsAssetId} />
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <NotFound dataProvider={this.cmsDataProvider} assetId={this.cmsAssetId} />
                                </div>
                            </div>
                        </div>

                    </div>


                </main>


                <div className="vicon-footer">
                    <FooterNavigation dataProvider={this.cmsDataProvider} />
                </div>

            </div>
        )
    }
}
