"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var cmsCore_1 = require("../common/cmsCore");
var crownpeak_dxm_sdk_core_1 = require("crownpeak-dxm-sdk-core");
var CmsPage = /** @class */ (function (_super) {
    __extends(CmsPage, _super);
    function CmsPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cmsUseTmf = false;
        _this.cmsUseMetadata = false;
        _this.cmsSuppressFolder = false;
        _this.cmsSuppressModel = false;
        _this.state = { isLoaded: false };
        return _this;
    }
    CmsPage.loadForProvider = function (provider, assetId, useState, useEffect, timeout, loadedCallback, errorCallback, beforeLoadingCallback) {
        var _a = useState(false), isLoaded = _a[0], setIsLoaded = _a[1];
        useEffect(function () {
            var isError = false;
            provider.setPreLoad(beforeLoadingCallback);
            provider.getSingleAsset(assetId, timeout).catch(function (ex) {
                isError = true;
                if (errorCallback)
                    errorCallback(ex, assetId);
                else
                    console.error(ex);
            }).then(function () {
                if (!isError) {
                    if (loadedCallback)
                        crownpeak_dxm_sdk_core_1.CmsDataCache.set(assetId, loadedCallback(crownpeak_dxm_sdk_core_1.CmsDataCache.get(assetId), assetId) || crownpeak_dxm_sdk_core_1.CmsDataCache.get(assetId));
                    setIsLoaded(true);
                }
            });
        }, [assetId, timeout, loadedCallback, errorCallback]);
        crownpeak_dxm_sdk_core_1.CmsDataCache.cmsAssetId = assetId;
        return isLoaded;
    };
    CmsPage.loadForProviderSync = function (provider, assetId, beforeLoadingCallback) {
        provider.setPreLoad(beforeLoadingCallback);
        provider.getSingleAssetSync(assetId);
        crownpeak_dxm_sdk_core_1.CmsDataCache.cmsAssetId = assetId;
    };
    CmsPage.prototype.render = function () {
        if (this.state.isLoaded)
            return null;
        var that = this;
        var isError = false;
        this.cmsDataProvider.setPreLoad(this.cmsBeforeLoadingData);
        this.cmsDataProvider.getSingleAsset(this.cmsAssetId, this.cmsLoadDataTimeout).catch(function (ex) {
            isError = true;
            if (that.cmsDataError)
                that.cmsDataError(ex, that.cmsAssetId);
            else
                console.error(ex);
        }).then(function () {
            if (!isError) {
                if (that.cmsDataLoaded)
                    crownpeak_dxm_sdk_core_1.CmsDataCache.set(that.cmsAssetId, that.cmsDataLoaded(crownpeak_dxm_sdk_core_1.CmsDataCache.get(that.cmsAssetId), that.cmsAssetId) || crownpeak_dxm_sdk_core_1.CmsDataCache.get(that.cmsAssetId));
                that.setState({ isLoaded: true });
            }
        });
        crownpeak_dxm_sdk_core_1.CmsDataCache.cmsAssetId = this.cmsAssetId;
        return null;
    };
    return CmsPage;
}(cmsCore_1.default));
exports.default = CmsPage;
