import { RegisterService } from "../../services";
import { CardService } from "../../services";
import { gtag } from "../../controllers/analytics";
import { AppThunk } from "../../controllers/store";

export const LOAD_CARDS = "LOAD_CARDS";
export const LOAD_CARDS_SUCCESS = "LOAD_CARDS_SUCCESS";
export const LOAD_CARDS_FAILED = "LOAD_CARDS_FAILED";
export const REGISTER_DELIVERY_DATE_UPDATED = "REGISTER_DELIVERY_DATE_UPDATED";
export const REGISTER_APPROVE_TOGGLED = "REGISTER_APPROVE_TOGGLED";
export const REGISTER_CARD_SET = "REGISTER_CARD_SET";
export const REGISTER_SAVING = "REGISTER_SAVING";
export const REGISTER_COMPLETED = "REGISTER_COMPLETED";
export const REGISTER_INIT = "REGISTER_INIT";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const REGISTER_FILE_SET = "REGISTER_FILE_SET";
export const NO_CARDS_FOUND = "NO_CARDS_FOUND";

export const loadCards = (
  bearerToken,
  search,
  lang
): AppThunk<Promise<void>> => {
  gtag("event", "search_pin", {
    value: search,

    event_label: `Search pin: ${search}`,
  });

  return async (dispatch: any): Promise<void> => {
    dispatch({ type: LOAD_CARDS });

    try {
      const cardService = new CardService(bearerToken);

      const cards = await cardService.searchCards(search, lang);
      if (cards.length === 0) {
        dispatch({ type: NO_CARDS_FOUND });
      } else {
        dispatch({ type: LOAD_CARDS_SUCCESS, cards });
      }
    } catch (err) {
      dispatch({ type: LOAD_CARDS_FAILED, err });
    }
  };
};

export const setDeliveryDate = (
  value: string
): { type: typeof REGISTER_DELIVERY_DATE_UPDATED; value: string } => {
  return {
    type: REGISTER_DELIVERY_DATE_UPDATED,
    value,
  };
};

export const toggleApprove = (
  field: string
): { type: typeof REGISTER_APPROVE_TOGGLED; field: string } => {
  return {
    type: REGISTER_APPROVE_TOGGLED,
    field,
  };
};

export const setCard = (
  id: string
): { type: typeof REGISTER_CARD_SET; id: string } => {
  return {
    type: REGISTER_CARD_SET,
    id,
  };
};

export const setFile = (
  file: File
): { type: typeof REGISTER_FILE_SET; file: File } => {
  return {
    type: REGISTER_FILE_SET,
    file,
  };
};

export const submit = (bearerToken: any): AppThunk<Promise<void>> => {
  return async (dispatch: any, getState: any): Promise<void> => {
    dispatch({ type: REGISTER_SAVING });
    const { deliveryDate, cardId, file, serialNumber, materialNumber } =
      getState().register;
    try {
      const registerService = new RegisterService(bearerToken);

      await registerService.addProduct({
        deliveryDate,
        id: cardId,
        serialNumber,
        materialNumber,
        file,
      });
      dispatch({ type: REGISTER_COMPLETED });
    } catch (err: any) {
      dispatch({ type: REGISTER_FAILED, err: err.data.serverError });
    }
  };
};

export const load = (): { type: typeof REGISTER_INIT } => {
  return { type: REGISTER_INIT };
};
