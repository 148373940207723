import React from "react";
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';

export default class SectionHeader extends CmsComponent {
    image: any;
    alt: any;
    header: any;
    description: any;

    constructor(props) {
        super(props);
        this.cmsFolder = "Section";
        this.cmsDisableDragDrop = false;
        this.cmsZones = ["droppable", "DragDropZone"];


        this.image = new CmsField("Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Image : null);
        this.alt = new CmsField("Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Alt : null);
        this.header = new CmsField("Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Header : null);
        this.description = new CmsField("Description", CmsFieldTypes.TEXT, props && props.data ? props.data.Description : null);
    }

    render() {
        return (
            <div className="large-card-main-content">
                <div>
                    <img src={this.image} alt={this.alt} />
                </div>
                <div className="large-card-text">
                    <p className="large-card-text-header">{this.header}</p>
                    <p className="large-card-text-desc">{this.description}</p>
                </div>
            </div>
        )
    }
}