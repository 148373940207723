import AbstractService from './abstractservice';
import config from '../configuration';

export default class RegisterService extends AbstractService {
  constructor(bearerToken: string) {
    super(config.runtime.urls.apiBaseUrl, 'application/json', bearerToken);
  }

  addProduct({ file, ...product }: any): Promise<unknown> {
    return this.postfile('register', null, product, file);
  }

  unregister(id: any): Promise<unknown> {
    return this.delete(`register/${id}`, undefined);
  }
}
