import React from 'react';
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
 
import DetailsCardRow from './details-card-row';


export default class DetailsCard extends CmsComponent {
    firstHeader: any;
    secondHeader: any;
    unregisterBtn: any;
    modalHeader: any;
    modalText: any;
    cancelBtn: any;
    confirmBtn: any;
    constructor(props) {
        super(props);
        this.cmsFolder = "Card";
        this.cmsZones = ["droppable", "DragDropZone"];
        this.cmsDisableDragDrop = false;

        this.firstHeader = new CmsField("First_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.First_Header : null);
        this.secondHeader = new CmsField("Second_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Second_Header : null);
        this.unregisterBtn = new CmsField("Unregister_Button", CmsFieldTypes.TEXT, props && props.data ? props.data.Unregister_Button : null);
        this.modalHeader = new CmsField("Modal_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Modal_Header : null);
        this.modalText = new CmsField("Modal_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Modal_Text : null);
        this.cancelBtn = new CmsField("Cancel_Button", CmsFieldTypes.TEXT, props && props.data ? props.data.Cancel_Button : null);
        this.confirmBtn = new CmsField("Confirm_Button", CmsFieldTypes.TEXT, props && props.data ? props.data.Confirm_Button : null);


    }

    render() {
        
        return (
            <div className="details-card-wrapper">
                <div className="details-card">
                    <p className="details-card-header">{this.firstHeader}</p>
                    <DetailsCardRow />
                    <DetailsCardRow />
                    <DetailsCardRow />
                    <DetailsCardRow />
                </div>
                <div className="details-card">
                    <p className="details-card-header">{this.secondHeader}</p>
                    <DetailsCardRow />
                    <DetailsCardRow />
                    <DetailsCardRow />
                    <DetailsCardRow />
                    <DetailsCardRow />

                    <div className="basic-btn unregister-btn">
                        <div className="btn-component-wrapper">
                            <button type="button" className="btn-component" data-toggle="modal" data-target="#unregisterModal">
                                {this.unregisterBtn}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="modal fade unregister-modal" id="unregisterModal" tab-index="-1" role="dialog" aria-labelledby="unregisterModal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">


                            <div className="modal-body">
                                <p className='unregister-modal-header'>{this.modalHeader}</p>
                                <p className='unregister-modal-text'>{this.modalText}</p>
                                <div className='unregister-modal-btn'>
                                    <div className='grey-btn'>
                                        <div className="btn-component-wrapper">
                                            <button type='button' className="btn-component" data-dismiss="modal" aria-label="Close">
                                                {this.cancelBtn}
                                            </button>
                                        </div>
                                    </div>
                                    <div className='basic-btn'>
                                        <div className="btn-component-wrapper">
                                            <button type='button' className="btn-component">
                                                {this.confirmBtn}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
