import { Location } from 'history';
import { AppThunk } from './controllers/store';
import { GlobalAppInitAction } from './global-types';
import config from './configuration';
import { gtag } from './controllers/analytics';
import { CommonWebService, UserService } from './services';
import { cache } from './utils/cache-data';

export const APP_INITIATED = 'APP_INITIATED';
export const APP_FAILED = 'APP_FAILED';
export const COOKIE_CLOSED = 'COOKIE_CLOSED';
export const ROUTE_CHANGED = '@ROUTE_CHANGED';
export const SET_SITE = 'SET_SITE';
export const USER_SYNCED = 'USER_SYNCED';

export const initApp = (
  site: string | null,
  lang: string
): AppThunk<Promise<unknown>> => async (dispatch): Promise<void> => {
  const { runMode } = config;
  const app = runMode === 'kvg' ? 'mykverneland' : 'myvicon';
  const brand = runMode === 'kvg' ? 'kverneland' : 'vicon';

  const commonWebService = new CommonWebService();

  try {
    // const [sites, menu, settings, footer] = await Promise.all([
    //   commonWebService.getSites(`my-${runMode}`),
    //   commonWebService.getSection(`${app}-menu`, site),
    //   commonWebService.getSection(`${app}-settings`, site),
    //   commonWebService.getSection(`${brand}-footer`, site)
    // ]);

    const action: GlobalAppInitAction = {
      type: APP_INITIATED,
      site,
      // sites,
      lang,
      // menu,
      // settings,
      // footer
    };

    dispatch(action);
  } catch (err) {
    dispatch({
      type: APP_FAILED
    });
  }
};

export const closeCookieWarning = (): { type: typeof COOKIE_CLOSED } => {
  cache('cookieClosed', true);

  return {
    type: COOKIE_CLOSED
  };
};

export const setSite = (
  site,
  lang
): { type: typeof SET_SITE; site: string; lang: string } => {
  cache('site', site);
  cache('lang', lang);

  return {
    type: SET_SITE,
    site,
    lang
  };
};

export const updateUser = (bearerToken): AppThunk<Promise<void>> => {
  return async (dispatch): Promise<void> => {
    try {
      const userService = new UserService(bearerToken);

      await userService.updateUser();
      dispatch({ type: 'USER_SYNCED' });
    } catch (err) {
      dispatch({ type: APP_FAILED });
    }
  };
};

export const updateRoute = (
  location: Location,
  site: string
): AppThunk<void> => {
  return (dispatch): void => {
    const clientIds = config.ga;
    const conf = clientIds[config.runMode];
    const { routeBase } = config;

    let { pathname } = location;

    if (routeBase.length > 1) {
      pathname = pathname.replace(routeBase, '');
    }

    const pathpieces = pathname.split('/').filter((t) => t);

    if (pathpieces.length > 1 && pathpieces[0] === 'fleet') {
      pathname = `/${pathpieces[0]}/:id`;
    }
    if (site) {
      pathname = `/${site}${pathname}`;
    }
 
    gtag('config', conf, { page_path: pathname });

    dispatch({
      type: ROUTE_CHANGED,
      location
    });
  };
};
