import React, { useContext } from 'react';
import { CmsComponent, CmsDynamicDataProvider, CmsField, CmsFieldTypes, CmsStaticDataProvider } from 'crownpeak-dxm-react-sdk';
import { useIsAuthenticated } from '@azure/msal-react';
import UserContext, { UserProvider } from '../../providers/user-provider';


export default class NotFound extends CmsComponent {
    header: any;
    description: any;
    jsonData: any;
    constructor(props: any) {
        super(props);
        this.cmsFolder = "Global";
        this.cmsDisableDragDrop = true;

        if (this.props.dataProvider instanceof CmsStaticDataProvider) {
            this.jsonData = new CmsStaticDataProvider()?.getCustomDataSync(`${this.props.assetId}.json`).NotFound;
        } else {
            this.jsonData = new CmsDynamicDataProvider()?.getDynamicQuerySync(`q=${this.props.assetId}&fl=custom_t_json:[json]`).response.docs[0]?.custom_t_json.NotFound;
        }
    }
    render() {
        return (
            <>
                <Logout />
                <div className='not-found-wrapper'>
                    {/* cp-scaffold 
                        {Header:Text}
                        {Description:Text}
                    /cp-scaffold */}
                    <div className='not-found-header'>{this.jsonData.Header}</div>
                    <div className='not-found-description'>{this.jsonData.Description}</div>
                </div>
            </>
        )
    }
}

function Logout() {
    const isAuthenticated = useIsAuthenticated();
    const { user } = useContext(UserContext);

    let isLogout = window.location.href.endsWith("/logout") || window.location.href.endsWith("/logout/");
    if(isAuthenticated && isLogout){
        user.logout();
    }
    return (null);
}