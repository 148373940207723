import { AppThunk } from '../../controllers/store';
import { CommonWebService } from '../../services';
import config from '../../configuration';
export const PARTS_ENTRIES_LOADED = 'PARTS_ENTRIES_LOADED';
export const PARTS_ENTRIES_LOADING = 'PARTS_ENTRIES_LOADING';

// export const load = (site): AppThunk<Promise<void>> => {
//   return async (dispatch): Promise<void> => {
//     dispatch({ type: PARTS_ENTRIES_LOADING });
//     const { runMode } = config;
//     const app = runMode === 'kvg' ? 'mykverneland' : 'myvicon';

//     const commonWebService = new CommonWebService();

//     const entries = await commonWebService.getSection(`${app}-parts`, site);
//     dispatch({ type: PARTS_ENTRIES_LOADED, entries });
//   };
// };
