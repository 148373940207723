import React from 'react';
import Routing from './core/routing';
import HeaderRouting from './core/headerRouting';
import { CmsCore } from 'crownpeak-dxm-react-sdk';
import FooterRouting from './core/footerRouting';
import SidebarRouting from './core/sidebarRouting';


CmsCore.init('/content/json/', '//searchg2.crownpeak.net/kverneland_portal_dev/select/?wt=json');
Routing.processRoutes('/content/json/routes.json');
HeaderRouting.processHeaderRoutes('/content/json/headerRoutes.json');
FooterRouting.processFooterRoutes('/content/json/footerRoutes.json');
SidebarRouting.processSidebarRoutes('/content/json/sidebarRoutes.json');