import React from 'react'
import { CmsComponent, CmsDynamicDataProvider, CmsStaticDataProvider } from 'crownpeak-dxm-react-sdk';
import SidebarRouting from '../../core/sidebarRouting';
import { Link } from 'react-router-dom';

export default class SidebarNavigation extends CmsComponent {
    nav_items: any[];
    assetId: any;
    constructor(props: any) {
        super(props);
        this.cmsFolder = "Global";
        this.cmsDisableDragDrop = true;
        this.assetId = SidebarRouting.getLanguage(window.location.pathname.split('/')[1]);
        this.nav_items = [];


        if (this.props.dataProvider instanceof CmsStaticDataProvider)
            this.nav_items = new CmsStaticDataProvider()?.getCustomDataSync(`${this.assetId}.json`).SidebarNavigationAdmin.NavItems;
        else if (this.props.dataProvider instanceof CmsDynamicDataProvider)
            this.nav_items = new CmsDynamicDataProvider()?.getDynamicQuerySync(`q=${this.assetId}&fl=custom_t_json:[json]`).response.docs[0].custom_t_json.SidebarNavigationAdmin.NavItems;
    }

    render() {
        let i = 0;
        return (

            <div>
                {/* cp-scaffold
                        {sidebarNavigationPreview:SidebarNavigationPreview}
                    else */}
                {this.nav_items.map((item, index) => {
                    return (
                        item.Link.Url.includes('https') ?
                            <a href={item.Link.Url} role="menuitem" target="_blank" key={'sidebar_nav_items_' + index}>{item.Link.Title}</a>
                            : <Link to={item.Link.Url} className={window.location.pathname.includes(item.Link.Url)? 'sidebar-active-link' : ''} role="menuitem" key={'sidebar_nav_items_' + index}>{item.Link.Title}</Link>
                    )
                })}
                {/* /cp-scaffold */}
            </div>


        )
    }
}
