import React, { useEffect } from 'react';
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
import { loadCards, load } from '../../pages/register/actions';
import UserContext from '../../providers/user-provider';
import FieldSelect from '../form/field-select';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { RootState } from '../../controllers/store';
import { RegisterFormController, RegisterSearchController } from '../../pages/register/register-controller'



export default class RegisterProductCard extends CmsComponent {
    image: any;
    alt: any;
    header: any;
    description: any;
    registerLabel: any;
    registerCtaText: any;
    registerCtaLink: any;
    textFieldLabel: any;
    dateFieldLabel: any;
    selectFieldLabel: any;
    firstCheckboxLabel: any;
    secondCheckboxLabel: any;
    thirdCheckboxLabel: any;
    fourthCheckboxLabel: any;
    submitBtnText: any;
    contactCtaText: any;
    contactCtaLink: any;
    contactLabel: any;
    state: any;
    successAlertImg: any;
    successAlertImgAlt: any;
    successAlertText: any;
    formErrorImg: any;
    formErrorImgAlt: any;
    formErrorText: any;
    registerErrorImg: any;
    registerErrorImgAlt: any;
    registerErrorText: any;
    allData: any;
    registerError: any;
    formError: any;
    successAlert: any;
    contactData: any;
    formData: any;
    formApprove: any;
    requiredFieldError: any;
    registerInputPlaceholder: any;
    constructor(props) {
        super(props);
        this.cmsFolder = "Section";
        this.cmsDisableDragDrop = false;
        this.cmsZones = ["droppable", "DragDropZone"];
        this.image = new CmsField("Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Image : null);
        this.alt = new CmsField("Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Alt : null);
        this.header = new CmsField("Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Header : null);
        this.description = new CmsField("Description", CmsFieldTypes.TEXT, props && props.data ? props.data.Description : null);

        this.registerLabel = new CmsField("Register_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Register_Label : null);
        this.registerCtaText = new CmsField("Register_CTA_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Register_CTA_Text : null);
        this.registerInputPlaceholder = new CmsField("Register_Input_Placeholder", CmsFieldTypes.TEXT, props && props.data ? props.data.Register_Input_Placeholder : null);

        this.textFieldLabel = new CmsField("Text_Field_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Text_Field_Label : null);
        this.dateFieldLabel = new CmsField("Date_Field_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Date_Field_Label : null);
        this.selectFieldLabel = new CmsField("Select_Field_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Select_Field_Label : null);
        this.firstCheckboxLabel = new CmsField("First_Checkbox_Label", CmsFieldTypes.WYSIWYG, props && props.data ? props.data.First_Checkbox_Label : null);
        this.secondCheckboxLabel = new CmsField("Second_Checkbox_Label", CmsFieldTypes.WYSIWYG, props && props.data ? props.data.Second_Checkbox_Label : null);
        this.thirdCheckboxLabel = new CmsField("Third_Checkbox_Label", CmsFieldTypes.WYSIWYG, props && props.data ? props.data.Third_Checkbox_Label : null);
        this.fourthCheckboxLabel = new CmsField("Fourth_Checkbox_Label", CmsFieldTypes.WYSIWYG, props && props.data ? props.data.Fourth_Checkbox_Label : null);
        this.submitBtnText = new CmsField("Submit_BTN_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Submit_BTN_Text : null);


        this.contactCtaText = new CmsField("Contact_CTA_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Contact_CTA_Text : null);
        this.contactCtaLink = new CmsField("Contact_CTA_Link", CmsFieldTypes.HREF, props && props.data ? props.data.Contact_CTA_Link : null);
        this.contactLabel = new CmsField("Contact_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Contact_Label : null);


        this.successAlertText = new CmsField("Success_Alert_Text", CmsFieldTypes.WYSIWYG, props && props.data ? props.data.Success_Alert_Text : null);
        this.successAlertImg = new CmsField("Success_Alert_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Success_Alert_Image : null);
        this.successAlertImgAlt = new CmsField("Success_Alert_Image_Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Success_Alert_Image_Alt : null);

        this.formErrorText = new CmsField("Form_Error_Text", CmsFieldTypes.WYSIWYG, props && props.data ? props.data.Form_Error_Text : null);
        this.formErrorImg = new CmsField("Form_Error_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Form_Error_Image : null);
        this.formErrorImgAlt = new CmsField("Form_Error_Image_Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Form_Error_Image_Alt : null);

        this.registerErrorText = new CmsField("Register_Error_Text", CmsFieldTypes.WYSIWYG, props && props.data ? props.data.Register_Error_Text : null);
        this.registerErrorImg = new CmsField("Register_Error_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Register_Error_Image : null);
        this.registerErrorImgAlt = new CmsField("Register_Error_Image_Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Register_Error_Image_Alt : null);

        this.requiredFieldError = new CmsField("Required_Field_Error_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Required_Field_Error_Text : null);

        this.state = {
            search: '',
            registerCtaText: this.registerCtaText,
            registerCtaLink: this.registerCtaLink
        }
        this.registerError = {
            registerErrorText: this.registerErrorText,
            registerErrorImg: this.registerErrorImg,
            registerErrorImgAlt: this.registerErrorImgAlt
        }
        this.successAlert = {
            successAlertText: this.successAlertText,
            successAlertImg: this.successAlertImg,
            successAlertImgAlt: this.successAlertImgAlt,
        }

        this.contactData = {
            contactCtaText: this.contactCtaText,
            contactCtaLink: this.contactCtaLink,
            contactLabel: this.contactLabel
        }

        this.formApprove = {
            dateFieldLabel: this.dateFieldLabel,
            firstCheckboxLabel: this.firstCheckboxLabel,
            secondCheckboxLabel: this.secondCheckboxLabel,
            thirdCheckboxLabel: this.thirdCheckboxLabel,
            fourthCheckboxLabel: this.fourthCheckboxLabel,
            submitBtnText: this.submitBtnText,
            requiredFieldError: this.requiredFieldError,
            textFieldLabel: this.textFieldLabel,
            selectFieldLabel: this.selectFieldLabel,
            formErrorText: this.formErrorText,
            formErrorImg: this.formErrorImg,
            formErrorImgAlt: this.formErrorImgAlt
        }

        this.allData = [
            this.state, this.registerError, this.formError, this.successAlert, this.contactData, this.formData, this.formApprove
        ]
    }





    render() {

        return (

            <div className="register-product">
                {/* cp-scaffold 
              
                    <div className="large-card-main-content">
                    <div>
                        <img src="{Image:Src}" alt="{Alt:Text}" />
                    </div>
                    <div className="large-card-text">
                        <p className="large-card-text-header">{Header:Text}</p>
                        <p className="large-card-text-desc">{Description:Text}</p>
                    </div>
                </div>

                  <form className="form-wrapper">
                    <div className="field field-with-button">
                        <label htmlFor="fieldwithbutton">{Register_Label:Text}</label>
                        <div className="input-btn">
                            <input type="text" name="fieldwithbutton" value="" placeholder="{Register_Input_Placeholder:Text}"/>
                            <div className="basic-btn">
                                <div className="btn-component-wrapper">
                                    <button type='button' className="btn-component">
                                        {Register_CTA_Text:Text}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="alert-wrapper register-alert-wrapper error-alert">
                    <div>
                        <img src=" {Register_Error_Image:Src}" alt="{Register_Error_Image_Alt:Text}"} />
                    </div>
                    <p>{Register_Error_Text:Wysiwyg}</p>
                </div>
        

                <form>
                    <div className="alert-wrapper register-alert-wrapper error-alert">
                        <div>
                            <img src="{Form_Error_Image:Src}" alt="{Form_Error_Image_Alt:Text}" />
                        </div>
                        <p>{Form_Error_Text:Wysiwyg}</p>
                    </div>
  
                    <div className="field field-select">
                        <label htmlFor="fieldselect">{Select_Field_Label:Text}</label>

                    </div>
                    <div className="field field-text">
                        <label htmlFor="fieldtext">{Text_Field_Label:Text}</label>
                        <input type="text" name="fieldtext" disabled value=""/>
                    </div>
                    <div className="field field-date">
                        <label htmlFor="fielddate"> {Date_Field_Label:Text}
                            <span className='field-error'>{Required_Field_Error_Text:Text}</span>
                        </label>
                        <input type="date" name="fielddate" required value=""/>
                    </div>
                    <div className="field field-checkbox">
                        <label>
                            <span>{First_Checkbox_Label:Wysiwyg}</span>
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="field field-checkbox">
                         <label>
                            <span>{Second_Checkbox_Label:Wysiwyg}</span>
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="field field-checkbox">
                        <label>
                            <span>{Third_Checkbox_Label:Wysiwyg}</span>
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="field field-checkbox">
                        <label>
                            <span>{Fourth_Checkbox_Label:Wysiwyg}</span>
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="field field-submit">
                        <button type="submit" name="fieldsubmit">
                            {Submit_BTN_Text:Text}
                        </button>
                    </div>
                </form>
 
                <div className="alert-wrapper register-alert-wrapper">
                    <div>
                        <img src="{Success_Alert_Image:Src}" alt="{Success_Alert_Image_Alt:Text}" />
                    </div>
                    <p>{Success_Alert_Text:Wysiwyg}</p>
                </div>
 
                <div className="alert-wrapper contact-alert-wrapper">
                    <div className='grey-btn'>
                        <div className="btn-component-wrapper">
                            <a href="{Contact_CTA_Link:Href}"className="btn-component">
                                {Contact_CTA_Text:Text}
                            </a>
                        </div>
                    </div>
                        <p>{Contact_Label:Text}</p>
                </div>
                 else */}
                <div className="large-card-main-content">
                    <div>
                        <img src={this.image} alt={this.alt} />
                    </div>
                    <div className="large-card-text">
                        <p className="large-card-text-header">{this.header}</p>
                        <p className="large-card-text-desc">{this.description}</p>
                    </div>
                </div>


                {/* REGISTER DEFAULT STATE */}
                <form className="form-wrapper">
                    <div className="field field-with-button">
                        <label htmlFor="fieldwithbutton">{this.registerLabel}</label>
                        <div className="input-btn">
                            <input type="text" name="fieldwithbutton" value={this.state.search} onChange={(ev): void => this.setState({ search: ev.target.value })} placeholder={this.registerInputPlaceholder} />
                            <RegisterSearchController
                                data={this.state}
                            />
                        </div>
                    </div>
                </form>

                {/* REGISTER FORM STATE */}

                 <RegisterFormController 
                    registerError = {this.registerError}
                    formApprove = {this.formApprove}
                    successAlert = {this.successAlert}
                    contactData = {this.contactData}
                 />
             
    {/* /cp-scaffold */}
            </div>
        )
    }
}

