import React, { useContext } from "react";
import { CmsComponent, CmsField, CmsDataCache, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
import UserContext, { UserProvider } from '../../providers/user-provider';
import { useMsal } from "@azure/msal-react";
import { click } from "@testing-library/user-event/dist/click";

export default class NotificationsList extends CmsComponent {
    notification_items: any;
    
    toogleNotificationsList(){
        let sidebarColumn = document.querySelector('.notifications-list .list');
        let cardCol = document.querySelector('.notifications-list .details');
        let button = document.querySelector('.notifications-list .toggler');
        let buttonIcon = document.querySelector('.notifications-list .toggler i');
        

        sidebarColumn?.classList.toggle('d-lg-block');

        cardCol?.classList.toggle('col-lg-8');
        cardCol?.classList.toggle('col-lg-12');

        button?.classList.toggle('right');

        buttonIcon?.classList.toggle('left');
        buttonIcon?.classList.toggle('right');
    }
    constructor(props) {
        super(props);
        this.cmsFolder = "Section";
        this.cmsDisableDragDrop = false;
        this.cmsZones = ["droppable", "DragDropZone"];
        
        // this.notification_items = new CmsField(
        //     "Notification_Items", "",
        //     CmsDataCache.get(CmsDataCache.cmsAssetId).Notification_Items ||
        //       CmsDataCache.get(CmsDataCache.cmsAssetId).DropZones.droppable[0]?.NotificationsList.Notification_Items ||
        //       CmsDataCache.get(CmsDataCache.cmsAssetId).DropZones.DragDropZone[0]?.NotificationsList.Notification_Items ||
        //       []
        //   );

        // this.notification_items = props && props.data && props.data.NotificationItems;
    }
 
    render() {
        return (
            <>
                {/* <List name="NotificationItems" type="NotificationItem"> */}
                {/* </List> */}
                <div className="notifications-list">
                    <div className="row">
                        <div className="col-12 col-lg-4 d-none d-lg-block">
                            <button className="toggler right" type="button"
                                onClick={(e) => { this.toogleNotificationsList(); } }>
                                <i className="arrow left"></i>
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        
                        <div className="col-12 col-lg-4 d-none d-lg-block list">
                            <MyNotificationList /> 
                        </div>

                        <div className="col-12 col-lg-8 details">
                            <MyNotificationItem />
                        </div>
                    </div>
                </div> 
            </>
        )
    }
}


function MyNotificationList() {
    const { accounts } = useMsal();
    let accountID = accounts?.map(o=> o.localAccountId)?.pop();

    const { notifications, selectedNotification, setSelectedNotification } = useContext(UserContext);
    const urlParams = new URLSearchParams(window.location.search);
    

    function readNotification(accountID, notificationID){
        if(accountID && notificationID) {
            var myHeaders = new Headers();
            myHeaders.append("X-API-KEY", "Murat");
            fetch(`https://mykvg-notif-dev-app.azurewebsites.net/api/v1/UserNotification/read/${notificationID}/${accountID}`, {
                method: 'put',    
                headers: myHeaders
            })
            .then(response => response.text())
            .then(response => {
                setSelectedNotification(notificationID);
                // if(notificationID) {
                //     var url = new URL(window.location.href);
                //     var search_params = url.searchParams;
                //     search_params.delete('ID');
                //     url.search = search_params.toString();
    
                //     //window.location.href = url.toString();
                // }
            })
            .catch(error => console.log('error', error));
        }
    }

    let _notificationID = urlParams.get('ID');
    if(_notificationID) { readNotification(accountID, _notificationID); };

    return (
        <>
            {notifications.notifications.map(notification => {
                // onClick={(e) => { readNotification(accountID, notification["id"]); } }
                return  <a href={"?ID=" + notification["id"] } >
                    <div className={'notification-item' + (selectedNotification == notification["id"]? ' selected' : '') + (notification["isRead"]? '' : ' notseen') }>
                        <p>{notification["header"]}</p>
                        <span>{notification["DisplyTime"]}</span>
                    </div>
                </a>
            })}
        </>
    );
}

function MyNotificationItem() {
    const { notifications, selectedNotification  } = useContext(UserContext);
    return (
        <>
            {notifications.notifications.filter(o=> o.id == selectedNotification).map(notification => {
                return<div className="row">
                    <div className="col-12" dangerouslySetInnerHTML={{ __html: notification["content"] }}>
                    </div>
                </div>
            })}
        </>
    );
}