/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import config from '../configuration';

const clientIds = config.ga;
const conf = clientIds[config.runMode];

(window as any).dataLayer = (window as any).dataLayer || [];

function gtaginner(i: any, s: any, o?: any): void {
  // rest operator does not work for unknown reasons.
  (window as any).dataLayer.push(arguments);
}
export const gtag = gtaginner;

export const initAnalytics = (site: any): void => {
  gtag('js', new Date());

  // resets the path initially to avoid sending private params like id's as part of the url

  gtag('config', conf, {
    page_title: document.title,
    page_location: window.location.href,
    page_path: window.location.pathname
  });
};
