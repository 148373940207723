import { CmsDropZoneComponent } from 'crownpeak-dxm-react-sdk';
import HeroHeader from '../hero/hero-header';
import HorizontalCard from '../card/horizontal-card';
import DetailsCard from '../card/details-card';
import DetailsCardRow from '../card/details-card-row';
import FleetCard from '../section/fleet';
import RegisterProductCard from '../section/register-product';
import PreRegisterProduct from '../section/preregister-product';
import ColoredHorizontalCard from '../hero/colored-horizontal-card';
import VerticalCard from '../card/vertical-card';
import SectionHeader from '../section/section-header';
import WYSIWYGSectionHeader from '../section/wysiwyg-section-header';
import ContactForm from '../section/contact-form';
import RedeemForm from '../section/redeem-form';
import ProductRegisterHelpForm from '../section/product-register-help-form';
import NotificationsList from '../section/notifications-list';
import NotificationItem from '../section/notification-item';
import SmartSearch from '../section/smart-search';

export default class DropZone extends CmsDropZoneComponent {
    constructor(props)
    {
        super(props);
        this.components = {
            HeroHeader,
            HorizontalCard,
            DetailsCard,
            DetailsCardRow,
            FleetCard,
            RegisterProductCard,
            PreRegisterProduct,
            ColoredHorizontalCard,
            VerticalCard,
            SectionHeader,
            WYSIWYGSectionHeader,
            ContactForm,
            RedeemForm,
            ProductRegisterHelpForm,
            NotificationsList,
            NotificationItem,
            SmartSearch
        };
    }
}
