import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";


export default class SidebarRouting {
    static _routes: any;
    static set routes(r) {
        this._routes = r;
    }

    static get routes() {
        return this._routes || [];
    }


    static getLanguage(language) {
        return (SidebarRouting.routes.find(asset => asset.language === language) || {}).cmsassetid;
    }

    static processSidebarRoutes(routeFile) {
        fetch(routeFile)
            .then(res => res.json())
            .then((routeData) => {

                Object.keys(routeData).forEach(function (key) {
                    const route = {
                        "language": routeData[key].language,
                        "cmsassetid": routeData[key].cmsassetid
                    };
                    SidebarRouting.routes.push(route);
                });
            })
            .catch(console.log);
    }
}

SidebarRouting.routes = [];
