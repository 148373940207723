import React from 'react';
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
 

export default class HeroHeader extends CmsComponent {
    header: any;
    description: any;
    constructor(props) {
        super(props);
        this.cmsFolder = "Hero";
        this.cmsZones = ["droppable", "DragDropZone"];
        this.cmsDisableDragDrop = false;

        this.header = new CmsField("Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Header : null);
        this.description = new CmsField("Description", CmsFieldTypes.TEXT, props && props.data ? props.data.Description : null);
    }

    render() {
        return (
           <div className="hero-header">
               <h1 className="hero-header-title">{this.header}</h1>
               <p className="hero-header-subtitle">{this.description}</p>
           </div>
        )
    }
}
