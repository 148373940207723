import React from 'react';
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';


export const Pagination = ({ state, setState, rows, tablePaginationFieldPage, tablePaginationFieldOf }) => {
    function handleClick(event) {


        if (event.currentTarget.id === 'prevBtn') {
            if (state.currentPage === 1) {
                setState({
                    ...state,
                    currentPage: 1
                });
            } else {
                setState({
                    ...state,
                    currentPage: Number(state.currentPage - 1)
                });
            }
        } else {
            if (state.currentPage === Math.ceil(rows.length / state.rowsPerPage)) {
                setState({
                    ...state,
                    currentPage: Math.ceil(rows.length / state.rowsPerPage)
                });
            } else {
                setState({
                    ...state,
                    currentPage: Number(state.currentPage + 1)
                });
            }

        }
    }

    return (
        rows.length > 0? <div id="page-numbers" className="pagination">

            <button
                type="button"
                id="prevBtn"
                onClick={handleClick}
            >
                <span className="pagination-arrow"><i className="arrow left"></i></span>
            </button>

            <p>{tablePaginationFieldPage.value} {` ${state.currentPage} `} {tablePaginationFieldOf.value} {` ${Math.ceil(rows.length / state.rowsPerPage)} `}</p>

            <button
                type="button"
                id="nextBtn"
                onClick={handleClick}
            >
                <span className="pagination-arrow"><i className="arrow right"></i></span>
            </button>
        </div> : null
    )

}

export default Pagination