import React, { useState, useEffect, useContext } from "react";
import { RootState } from "../../../controllers/store";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { load, unregister, setFile, submitFile } from "./actions";
import { History } from "history";
import { useNavigate, useParams, Navigate, Link } from "react-router-dom";
import { CmsComponent } from "crownpeak-dxm-react-sdk";
import DetailsCardRow from "../../../components/card/details-card-row";
import UserContext from "../../../providers/user-provider";
import { gtag } from "../../../controllers/analytics";
import Pagination from "../../../components/global/pagination";

export class FleetProductClass extends CmsComponent {
  detailsFirstHeader: any;
  detailsSecondHeader: any;
  unregisterBtn: any;
  modalHeader: any;
  modalText: any;
  cancelBtn: any;
  confirmBtn: any;
  uploadLabel: any;
  uploadAreaDesktopText: any;
  uploadAreaMobileText: any;
  uploadImage: any;
  uploadAlt: any;
  uploadBtnText: any;

  uploadSuccessAlertImg: any;
  uploadSuccessAlertImgAlt: any;
  uploadErrorAlertImg: any;
  uploadErrorAlertImgAlt: any;

  tableHeader: any;
  changeLabel: any;
  state: any;
  productCardSerialNumberLabel: any;
  productCardModelNameLabel: any;
  productCardBrandLabel: any;
  productCardCategoryLabel: any;

  warrantyCardStatusLabel: any;
  warrantyCardDeliveryDateLabel: any;
  warrantyCardExpLabourLabel: any;
  warrantyCardExpSpareLabel: any;
  warrantyCardInspectionLabel: any;
  warrantyEndDateLabel: any;

  documentsTableDescription: any;
  documentsTableFirstColumnHeader: any;
  documentsTableSecondColumnHeader: any;
  documentsTableThirdColumnHeader: any;
  documentsTableFourthColumnHeader: any;
  documentsTableFifthColumnHeader: any;

  tablePaginationFieldPage: any;
  tablePaginationFieldOf: any;
  backCtaText: any;
  constructor(props) {
    super(props);
    this.cmsDisableDragDrop = true;
    this.detailsFirstHeader =
      props && props.detailsFirstHeader ? props.detailsFirstHeader : null;
    this.detailsSecondHeader =
      props && props.detailsSecondHeader ? props.detailsSecondHeader : null;
    this.unregisterBtn =
      props && props.unregisterBtn ? props.unregisterBtn : null;
    this.modalHeader = props && props.modalHeader ? props.modalHeader : null;
    this.modalText = props && props.modalText ? props.modalText : null;
    this.cancelBtn = props && props.cancelBtn ? props.cancelBtn : null;
    this.confirmBtn = props && props.confirmBtn ? props.confirmBtn : null;
    this.uploadLabel = props && props.uploadLabel ? props.uploadLabel : null;
    this.uploadAreaDesktopText =
      props && props.uploadAreaDesktopText ? props.uploadAreaDesktopText : null;
    this.uploadAreaMobileText =
      props && props.uploadAreaMobileText ? props.uploadAreaMobileText : null;
    this.uploadImage = props && props.uploadImage ? props.uploadImage : null;
    this.uploadAlt = props && props.uploadAlt ? props.uploadAlt : null;
    this.uploadBtnText =
      props && props.uploadBtnText ? props.uploadBtnText : null;
    this.uploadSuccessAlertImg =
      props && props.uploadSuccessAlertImg ? props.uploadSuccessAlertImg : null;
    this.uploadSuccessAlertImgAlt =
      props && props.uploadSuccessAlertImgAlt
        ? props.uploadSuccessAlertImgAlt
        : null;
    this.uploadErrorAlertImg =
      props && props.uploadErrorAlertImg ? props.uploadErrorAlertImg : null;
    this.uploadErrorAlertImgAlt =
      props && props.uploadErrorAlertImgAlt
        ? props.uploadErrorAlertImgAlt
        : null;
    this.changeLabel = props && props.changeLabel ? props.changeLabel : null;
    this.state = props && props.state ? props.state : null;
    this.tableHeader = props && props.tableHeader ? props.tableHeader : null;

    this.productCardSerialNumberLabel =
      props && props.productCardSerialNumberLabel
        ? props.productCardSerialNumberLabel
        : null;
    this.productCardModelNameLabel =
      props && props.productCardModelNameLabel
        ? props.productCardModelNameLabel
        : null;
    this.productCardBrandLabel =
      props && props.productCardBrandLabel ? props.productCardBrandLabel : null;
    this.productCardCategoryLabel =
      props && props.productCardCategoryLabel
        ? props.productCardCategoryLabel
        : null;

    this.warrantyCardStatusLabel =
      props && props.warrantyCardStatusLabel
        ? props.warrantyCardStatusLabel
        : null;
    this.warrantyCardDeliveryDateLabel =
      props && props.warrantyCardDeliveryDateLabel
        ? props.warrantyCardDeliveryDateLabel
        : null;
        

    this.warrantyCardExpLabourLabel =
      props && props.warrantyCardExpLabourLabel
        ? props.warrantyCardExpLabourLabel
        : null;
    this.warrantyCardExpSpareLabel =
      props && props.warrantyCardExpSpareLabel
        ? props.warrantyCardExpSpareLabel
        : null;
    this.warrantyCardInspectionLabel =
      props && props.warrantyCardInspectionLabel
        ? props.warrantyCardInspectionLabel
        : null;
    this.warrantyEndDateLabel =
        props && props.warrantyEndDateLabel
          ? props.warrantyEndDateLabel
          : null;

    this.documentsTableDescription =
      props && props.documentsTableDescription
        ? props.documentsTableDescription
        : null;
    this.documentsTableFirstColumnHeader =
      props && props.documentsTableFirstColumnHeader
        ? props.documentsTableFirstColumnHeader
        : null;
    this.documentsTableSecondColumnHeader =
      props && props.documentsTableSecondColumnHeader
        ? props.documentsTableSecondColumnHeader
        : null;
    this.documentsTableThirdColumnHeader =
      props && props.documentsTableThirdColumnHeader
        ? props.documentsTableThirdColumnHeader
        : null;
    this.documentsTableFourthColumnHeader =
      props && props.documentsTableFourthColumnHeader
        ? props.documentsTableFourthColumnHeader
        : null;
    this.documentsTableFifthColumnHeader =
      props && props.documentsTableFifthColumnHeader
        ? props.documentsTableFifthColumnHeader
        : null;

    this.tablePaginationFieldPage =
      props && props.tablePaginationFieldPage
        ? props.tablePaginationFieldPage
        : null;
    this.tablePaginationFieldOf =
      props && props.tablePaginationFieldOf
        ? props.tablePaginationFieldOf
        : null;

    this.backCtaText = props && props.backCtaText ? props.backCtaText : null;
  }
  render() {
    return (
      <React.Fragment>
        <FleetProduct
          detailsFirstHeader={this.detailsFirstHeader}
          detailsSecondHeader={this.detailsSecondHeader}
          unregisterBtn={this.unregisterBtn}
          modalHeader={this.modalHeader}
          modalText={this.modalText}
          cancelBtn={this.cancelBtn}
          confirmBtn={this.confirmBtn}
          productCardSerialNumberLabel={this.productCardSerialNumberLabel}
          productCardModelNameLabel={this.productCardModelNameLabel}
          productCardBrandLabel={this.productCardBrandLabel}
          productCardCategoryLabel={this.productCardCategoryLabel}
          warrantyCardStatusLabel={this.warrantyCardStatusLabel}
          warrantyCardDeliveryDateLabel={this.warrantyCardDeliveryDateLabel}
          warrantyCardExpLabourLabel={this.warrantyCardExpLabourLabel}
          warrantyCardExpSpareLabel={this.warrantyCardExpSpareLabel}
          warrantyCardInspectionLabel={this.warrantyCardInspectionLabel}
          warrantyEndDateLabel={this.warrantyEndDateLabel}
          backCtaText={this.backCtaText}
        />

        <FleetDocuments
          tableHeader={this.tableHeader}
          documentsTableDescription={this.documentsTableDescription}
          documentsTableFirstColumnHeader={this.documentsTableFirstColumnHeader}
          documentsTableSecondColumnHeader={
            this.documentsTableSecondColumnHeader
          }
          documentsTableThirdColumnHeader={this.documentsTableThirdColumnHeader}
          documentsTableFourthColumnHeader={
            this.documentsTableFourthColumnHeader
          }
          documentsTableFifthColumnHeader={this.documentsTableFifthColumnHeader}
          tablePaginationFieldPage={this.tablePaginationFieldPage}
          tablePaginationFieldOf={this.tablePaginationFieldOf}
        />
        {/* 
                <FleetUpload
                    uploadLabel={this.uploadLabel}
                    uploadImage={this.uploadImage}
                    uploadAlt={this.uploadAlt}
                    uploadBtnText={this.uploadBtnText}
                    uploadSuccessAlertImg={this.uploadSuccessAlertImg}
                    uploadSuccessAlertImgAlt={this.uploadSuccessAlertImgAlt}
                    uploadErrorAlertImg={this.uploadErrorAlertImg}
                    uploadErrorAlertImgAlt={this.uploadErrorAlertImgAlt}
                    uploadAreaDesktopText={this.uploadAreaDesktopText}
                    uploadAreaMobileText={this.uploadAreaMobileText}

                /> */}
      </React.Fragment>
    );
  }
}

export const FleetProduct = ({
  detailsFirstHeader,
  detailsSecondHeader,
  unregisterBtn,
  modalHeader,
  modalText,
  cancelBtn,
  confirmBtn,
  productCardSerialNumberLabel,
  productCardModelNameLabel,
  productCardBrandLabel,
  productCardCategoryLabel,
  warrantyCardStatusLabel,
  warrantyCardDeliveryDateLabel,
  warrantyCardExpLabourLabel,
  warrantyCardExpSpareLabel,
  warrantyCardInspectionLabel,
  warrantyEndDateLabel,
  backCtaText,
}) => {
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const { fleetProduct, setFleetProduct, loadFleetTable } =
    useContext(UserContext);
  const [viewModal, setViewModal] = useState(false);

  const { product, loading, failed, lang } = useSelector(
    (state: RootState) => ({
      product: state.fleet.details.product,
      loading: state.fleet.details.loading,
      failed: state.fleet.details.failed,
      lang: state.global.lang,
    }),
    shallowEqual
  );

  const unReg = (id: string): void => {
    setFleetProduct(false);
    loadFleetTable(user.accessToken, window.localStorage.lang);
    dispatch(unregister(user.accessToken || "", id));
  };

  const callLoad = (id: string, l: string): void => {
    dispatch(load(user.accessToken || "", id, window.localStorage.lang));
  };

  useEffect(() => {
    if (fleetProduct) {
      callLoad(fleetProduct, window.localStorage.lang);
    }
  }, [fleetProduct]);
  if (fleetProduct && loading) return <div className="loader"></div>;
  if (!product || !fleetProduct) return null;

  if (failed) {
    return <div>404 - Wooops, Page not found</div>;
  }

  return (
    <div className="fleet-product">
      <button
        className="back-btn"
        onClick={() => {
          setFleetProduct(false);
        }}
      >
        <i className="arrow left"></i>
        {backCtaText}
      </button>
      <div className="details-card-wrapper">
        <div className="details-card">
          <p className="details-card-header">{detailsFirstHeader}</p>

          <DetailsCardRow
            title={productCardSerialNumberLabel}
            text={product.serialNumber}
          />
          <DetailsCardRow
            title={productCardModelNameLabel}
            text={product.modelName}
          />
          <DetailsCardRow title={productCardBrandLabel} text={product.brand} />
          <DetailsCardRow
            title={productCardCategoryLabel}
            text={product.category}
          />
        </div>
        <div className="details-card">
          <p className="details-card-header">{detailsSecondHeader}</p>
          {/* <DetailsCardRow
            title={warrantyCardStatusLabel}
            text={product.warrantyStatus}
          /> */}
          <DetailsCardRow
            title={warrantyCardDeliveryDateLabel}
            text={product.warrantyDeliveryDate}
          />
          {/* <DetailsCardRow
                        title={warrantyCardExpLabourLabel}
                        text={product.warrantyExpirationLabour}
                    />
                    <DetailsCardRow
                        title={warrantyCardExpSpareLabel}
                        text={product.warrantyExpirationSpareParts}
                    /> */}
          <DetailsCardRow
            title={warrantyCardInspectionLabel}
            text={product.oneYearInspectionDate}
          />
          <DetailsCardRow
            title={warrantyEndDateLabel}
            text={product.warrantyEndDate}
          />
        </div>
        {viewModal ? (
          <div
            className="modal fade unregister-modal"
            id="unregisterModal"
            tab-index="-1"
            role="dialog"
            aria-labelledby="unregisterModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <p className="unregister-modal-header">{modalHeader}</p>
                  <p className="unregister-modal-text">{modalText}</p>
                  <div className="unregister-modal-btn">
                    <div className="grey-btn">
                      <div className="btn-component-wrapper">
                        <button
                          type="button"
                          className="btn-component"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setViewModal(true);
                            document
                              .querySelector(".modal-backdrop.fade.show")
                              ?.remove();
                            document
                              .querySelector("body")
                              ?.classList.remove("modal-open");
                          }}
                        >
                          {cancelBtn}
                        </button>
                      </div>
                    </div>
                    <div className="basic-btn">
                      <div className="btn-component-wrapper">
                        <button
                          type="button"
                          className="btn-component"
                          onClick={() => {
                            unReg(product.serialNumber);
                            setViewModal(false);
                            document
                              .querySelector(".modal-backdrop.fade.show")
                              ?.remove();
                            document
                              .querySelector("body")
                              ?.classList.remove("modal-open");
                          }}
                        >
                          {confirmBtn}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="basic-btn">
        <div className="btn-component-wrapper">
          <button
            type="button"
            className="btn-component"
            data-toggle="modal"
            data-target="#unregisterModal"
            onClick={() => {
              setViewModal(true);
              document.querySelector(".modal-backdrop.fade.show")?.remove();
              document.querySelector("body")?.classList.remove("modal-open");
            }}
          >
            {unregisterBtn}
          </button>
        </div>
      </div>
    </div>
  );
};

export const FleetUpload = ({
  uploadLabel,
  uploadImage,
  uploadAlt,
  uploadBtnText,
  uploadAreaDesktopText,
  uploadAreaMobileText,
  uploadSuccessAlertImg,
  uploadSuccessAlertImgAlt,
  uploadErrorAlertImg,
  uploadErrorAlertImgAlt,
}) => {
  const [field, setField] = useState({
    uploadField: uploadAreaMobileText,
    dropzoneField: uploadAreaDesktopText,
    hasFile: false,
  });
  const { user } = useContext(UserContext);
  const { fleetProduct, setFleetProduct } = useContext(UserContext);
  const match = { params: { id: fleetProduct } };
  const { value, fileDone, fileResult, saving, product } = useSelector(
    (state: RootState) => ({
      value: state.fleet.details.file,
      fileDone: state.fleet.details.fileDone,
      fileResult: state.fleet.details.fileResult,
      saving: state.fleet.details.saving,
      product: state.fleet.details.product,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const callSetFile = (ev: any): void => {
    ev.preventDefault();
    if (ev && ev.target.files[0]) {
      dispatch(setFile(ev.target.files[0]));
    }
  };
  const callSubmitFile = (ev, id: string): void => {
    ev.preventDefault();
    dispatch(submitFile(user.accessToken || "", id));
  };

  function changeLabel(event) {
    // eslint-disable-next-line no-undef

    setField({
      uploadField: event.target.files[0].name,
      dropzoneField: event.target.files[0].name,
      hasFile: true,
    });
  }

  if (!product || !fleetProduct) return null;
  return (
    <form className="form-wrapper">
      <div className="field-upload">
        <label className="field-upload-label">{uploadLabel}</label>
        <div className="field-upload-wrapper">
          <label
            className="field-upload-btn d-lg-none"
            htmlFor="field-upload"
            onChange={(ev) => {
              changeLabel(ev);
              callSetFile(ev);
            }}
            id="field-upload-label"
          >
            {field.uploadField}
            <input type="file" id="field-upload" />
          </label>

          <label
            className="field-drop-btn d-none d-lg-flex"
            htmlFor="field-upload-dropzone"
            onChange={(ev) => {
              changeLabel(ev);
              callSetFile(ev);
            }}
            id="field-upload-dropzone-label"
          >
            <div>
              <img src={uploadImage} alt={uploadAlt} />
            </div>
            {field.dropzoneField}
            <input
              type="file"
              id="field-upload-dropzone"
              draggable
              className="field-upload-dropzone"
            />
          </label>
          <div className="field field-submit">
            <button
              type="submit"
              name="fieldsubmit"
              disabled={!field.hasFile || saving}
              onClick={(e): void => callSubmitFile(e, match.params.id)}
            >
              {saving ? (
                <div className="loader btn-loader"></div>
              ) : (
                uploadBtnText
              )}
            </button>
          </div>
        </div>
      </div>
      {fileDone && fileResult ? (
        <div className="alert-wrapper upload-alert-wrapper">
          <div>
            {fileResult.severity === "0" ? (
              <img src={uploadSuccessAlertImg} alt={uploadSuccessAlertImgAlt} />
            ) : (
              <img src={uploadErrorAlertImg} alt={uploadErrorAlertImgAlt} />
            )}
          </div>
          <p>{fileResult.message}</p>
        </div>
      ) : null}
    </form>
  );
};

const openPage = (id: string, label: string, url: string): void => {
  gtag("event", "open_file", {
    value: `${id}`,
    event_label: `${label}`,
  });
  const win = window.open(
    `${url}${global.runMode === "kvg" ? "Kverneland" : "Vicon"}`,
    "_blank"
  );
  if (win) win.focus();
};

const intercept = (id: string, label: string): void => {
  gtag("event", "open_file", {
    value: `${id}`,

    event_label: `${label}`,
  });
};

export const FleetDocuments = ({
  tableHeader,
  documentsTableDescription,
  documentsTableFirstColumnHeader,
  documentsTableSecondColumnHeader,
  documentsTableThirdColumnHeader,
  documentsTableFourthColumnHeader,
  documentsTableFifthColumnHeader,
  tablePaginationFieldPage,
  tablePaginationFieldOf,
}) => {
  var runMode = process.env.REACT_APP_runMode?.includes("kvg")
    ? "Kverneland"
    : "Vicon";
  const { fleetProduct } = useContext(UserContext);
  const { product, loading, failed, lang } = useSelector(
    (state: RootState) => ({
      product: state.fleet.details.product,
      loading: state.fleet.details.loading,
      failed: state.fleet.details.failed,
      lang: state.global.lang,
    }),
    shallowEqual
  );
  const [state, setState] = useState({
    currentPage: 1,
    rowsPerPage: 10,
  });
  let documentsList = product?.documents;
  if (!documentsList) documentsList = [];

  const indexOfLastRow = state.currentPage * state.rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - state.rowsPerPage;
  const currentRows = documentsList.slice(indexOfFirstRow, indexOfLastRow);
  const renderRows = currentRows.map((row, index) => {
    return (
      <div className="document-card" key={index}>
        <a
          className="document-card-header"
          href={row.url + runMode}
          onClick={(e) => {
            intercept(row.id, row.title);
          }}
          target="_blank"
          rel="noreferrer"
        >
          {row.title}
        </a>
        <div className="d-flex">
          <div>
            <div className="document-card-row">
              <p className="title">{documentsTableSecondColumnHeader}: </p>
              <p>{row.type}</p>
            </div>
            <div className="document-card-row">
              <p className="title">{documentsTableThirdColumnHeader}: </p>
              <p>{row.category}</p>
            </div>
            <div className="document-card-row">
              <p className="title">{documentsTableFourthColumnHeader}: </p>
              <p>{row.priority}</p>
            </div>
            <div className="document-card-row">
              <p className="title">{documentsTableFifthColumnHeader}: </p>
              <p>{row.date}</p>
            </div>
          </div>
          <div className="d-flex-vertical">
            <a
              className="document-details"
              target="_blank"
              href={row.url + runMode}
              rel="noreferrer"
            >
              More Details
            </a>
          </div>
        </div>
      </div>
    );
  });

  const renderRowsMob = currentRows.map((row, index) => {
    return (
      <div className="mobile-table-card">
        <div className="mobile-table-card-row">
          <p className="mobile-table-card-row-header">
            {documentsTableFirstColumnHeader}
          </p>
          <a
            href={row.url + runMode}
            className="mobile-table-card-row-link"
            onClick={(e) => {
              openPage(row.id, row.title, row.url);
            }}
            target="_blank"
            rel="noreferrer"
          >
            {row.title}
          </a>
        </div>
        <div className="mobile-table-card-row">
          <p className="mobile-table-card-row-header">
            {documentsTableSecondColumnHeader}
          </p>
          <p className="mobile-table-card-row-text">{row.type}</p>
        </div>
        <div className="mobile-table-card-row">
          <p className="mobile-table-card-row-header">
            {documentsTableThirdColumnHeader}
          </p>
          <p className="mobile-table-card-row-text">{row.category}</p>
        </div>
        <div className="mobile-table-card-row">
          <p className="mobile-table-card-row-header">
            {documentsTableFourthColumnHeader}
          </p>
          <p className="mobile-table-card-row-text">{row.priority}</p>
        </div>
        <div className="mobile-table-card-row">
          <p className="mobile-table-card-row-header">
            {documentsTableFifthColumnHeader}
          </p>
          <p className="mobile-table-card-row-text">{row.date}</p>
        </div>
      </div>
    );
  });

  if (documentsList.length === 0 || !product || !fleetProduct) return null;
  return (
    <React.Fragment>
      <p className="table-header">{tableHeader}</p>
      <desc className="table-desc">{documentsTableDescription}</desc>
      <div className="m-t20">{renderRows}</div>

      <div className="mobile-table d-lg-none">{renderRowsMob}</div>

      <Pagination
        state={state}
        setState={setState}
        rows={documentsList}
        tablePaginationFieldPage={tablePaginationFieldPage}
        tablePaginationFieldOf={tablePaginationFieldOf}
      />
    </React.Fragment>
  );
};
