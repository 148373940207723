import React from 'react';
import { CmsComponent, CmsDynamicDataProvider, CmsField, CmsFieldTypes, CmsStaticDataProvider } from 'crownpeak-dxm-react-sdk';

export default class BackgroundImage extends CmsComponent {
    backgroundImg: any;
    jsonData: any;
    constructor(props: any) {
        super(props);
        this.cmsFolder = "Global";
        this.cmsDisableDragDrop = true;

        this.backgroundImg = new CmsField("Background_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Background_Image : null);

        if (this.props.dataProvider instanceof CmsStaticDataProvider) {
            this.jsonData = new CmsStaticDataProvider()?.getCustomDataSync(`${this.props.assetId}.json`).BackgroundImage;
        } else {
            this.jsonData = new CmsDynamicDataProvider()?.getDynamicQuerySync(`q=${this.props.assetId}&fl=custom_t_json:[json]`).response.docs[0]?.custom_t_json.BackgroundImage;
        }
    }
    render() {
     
        return (
            <React.Fragment>
                {/* cp-scaffold 
                    <div className='backgroundImage' style="background-image: url({Background_Image:Src})"></div>
                else */}
                <div className='backgroundImage' style={{ backgroundImage: `url(${this.jsonData?.Background_Image})`}}></div>
                {/* /cp-scaffold */}
            </React.Fragment>
        )
    }
}
