import React from "react";
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
import NotificationId from "./notification-id";

export default class NotificationItem extends CmsComponent {
    id: any;
    header: any;
    description: any;

    constructor(props) {
        super(props);
        this.cmsFolder = "Section";
        this.cmsDisableDragDrop = false;
        this.cmsZones = ["droppable", "DragDropZone"];

        this.id = new CmsField("Id", CmsFieldTypes.TEXT, props && props.data ? props.data.Id : null, );
        this.header = new CmsField("Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Header : null);
        this.description = new CmsField("Description", CmsFieldTypes.WYSIWYG, props && props.data ? props.data.Description : null);
    }

    render() {
        return (
            <>
            {/* cp-scaffold
                {Id:NotificationId}
                {Header:Text}
                {Description:Wysiwyg}
            /cp-scaffold */}

            <div className="large-card-main-content">
                <div className="large-card-text">
                    <p className="large-card-text-header">{this.header}</p>
                    <p className="large-card-text-desc" dangerouslySetInnerHTML={{ __html: this.description }}></p>
                </div>
            </div>
            </>
        )
    }
}