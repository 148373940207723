import React from 'react'
import { CmsDynamicPage, CmsStaticPage } from 'crownpeak-dxm-react-sdk';
import DropZone from "../components/functional/dropZone";
import Routing from "../core/routing";
import FooterNavigation from '../components/global/footer-navigation';
import HeaderNavigation from '../components/global/header-navigation';
import BackgroundImage from '../components/global/backgroundImage';
import CountryList from '../components/global/country-list';

export default class KvgUserHomePage extends CmsStaticPage {
    constructor(props: any) {
        super(props);
        this.cmsSuppressFolder = true;
        this.cmsUseTmf = true;
        this.cmsUseMetadata = true;
        this.cmsWrapper = "Kverneland";
        this.cmsAssetId = Routing.getCmsAssetId(window.location.pathname);
        this.cmsUseMetadata = true;
    }

    render() {
        super.render();

        return (
            this.state.isLoaded &&
            <div className={window.localStorage.lang}>
                <div className='kvg-modal'>
                    <CountryList dataProvider={this.cmsDataProvider} />
                </div>
                <header className="kvg-header header">
                    <HeaderNavigation dataProvider={this.cmsDataProvider} />
                </header>
                <main id="main-content" className="main-content" role="main" aria-label="main content">
                    <div className="cp-main-container kvg-wrapper kvg-user-homepage" >

                        <BackgroundImage dataProvider={this.cmsDataProvider} assetId={this.cmsAssetId}/>

                        <div className="container">
                            <div className="row">
                                <div className="col-12 user-home-dropzone-flex">
                                    <DropZone name="DragDropZone" />

                                </div>
                                <div className='col-12 user-home-grid'>
                                    <DropZone name="droppable" />

                                </div>

                            </div>
                        </div>       

                    </div>


                </main>


                <div className="kvg-footer">
                    <FooterNavigation dataProvider={this.cmsDataProvider} />
                </div>

            </div>
        )
    }
}
