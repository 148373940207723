import React from 'react';
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
import { Link } from 'react-router-dom';


export default class VerticalCard extends CmsComponent {
    image: any;
    alt: any;
    header: any;
    description: any;
    cta_text: any;
    cta_link: any;
    hideOn: any;
    constructor(props) {
        super(props);
        this.cmsFolder = "Card";
        this.cmsZones = ["droppable", "DragDropZone"];
        this.cmsDisableDragDrop = false;

        this.image = new CmsField("Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Image : null);
        this.alt = new CmsField("Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Alt : null);
        this.header = new CmsField("Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Header : null);
        this.description = new CmsField("Description", CmsFieldTypes.TEXT, props && props.data ? props.data.Description : null);
        this.cta_link = new CmsField("CTA_Link", CmsFieldTypes.HREF, props && props.data ? props.data.CTA_Link : null);
        this.hideOn = new CmsField("Hide_On", CmsFieldTypes.DATE, props && props.data ? props.data.Hide_On : null);
    }

    render() {
        return (
            <React.Fragment>

                {/* cp-scaffold
                    
                    <a href="{CTA_Link:Href}" target="_blank" className='vertical-card-link-wrapper'>
                        <div className="vertical-card-wrapper">
                            <div className="vertical-card-content">
                                <div>
                                    <img src="{Image:Src}" alt="{Alt:Text}" />
                                </div>
                                <div className="vertical-card-text">
                                    <p className="vertical-card-text-header">{Header:Text}</p>
                                    <p className="vertical-card-text-desc">{Description:Text}</p>
                                </div>
                            </div>

                            <div className="vertical-card-link">
                                <span className="round-arrow"><i className="arrow right border-white"></i></span>
                            </div>
                        </div>
                    </a>
                    <p>{Hide_On:Date}</p>
                    else */}
                {
                (this.hideOn && new Date(this.hideOn) < new Date())? <></>
                :
                this.cta_link.value.includes('https') ?
                    <a href={this.cta_link} target="_blank" className='vertical-card-link-wrapper'>
                        <div className="vertical-card-wrapper">
                            <div className="vertical-card-content">
                                <div>
                                    <img src={this.image} alt={this.alt} />
                                </div>
                                <div className="vertical-card-text">
                                    <p className="vertical-card-text-header">{this.header}</p>
                                    <p className="vertical-card-text-desc">{this.description}</p>
                                </div>
                            </div>

                            <div className="vertical-card-link">
                                <span className="round-arrow"><i className="arrow right border-white"></i></span>
                            </div>
                        </div>
                    </a>
                    :
                    <Link to={this.cta_link.value} className='vertical-card-link-wrapper'>
                        <div className="vertical-card-wrapper">
                            <div className="vertical-card-content">
                                <div>
                                    <img src={this.image} alt={this.alt} />
                                </div>
                                <div className="vertical-card-text">
                                    <p className="vertical-card-text-header">{this.header}</p>
                                    <p className="vertical-card-text-desc">{this.description}</p>
                                </div>
                            </div>

                            <div className="vertical-card-link">
                                <span className="round-arrow"><i className="arrow right border-white"></i></span>
                            </div>
                        </div>
                    </Link>
                }
                {/* /cp-scaffold */}
            </React.Fragment>

        )
    }
}
