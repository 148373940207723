import React, { useState, useEffect, useContext } from "react";
import { load } from "./actions";
import { RootState } from "../../../controllers/store";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Pagination from "../../../components/global/pagination";
import { CmsComponent } from "crownpeak-dxm-react-sdk";
import UserContext from "../../../providers/user-provider";
import { Link } from "react-router-dom";

export class FleetTableClass extends CmsComponent {
  cta_text: any;
  cta_link: any;
  fleetTableDescription: any;
  fleetTableFirstColumnHeader: any;
  fleetTableSecondColumnHeader: any;
  fleetTableThirdColumnHeader: any;
  fleetTableFourthColumnHeader: any;
  fleetTableFifthColumnHeader: any;
  tablePaginationFieldPage: any;
  tablePaginationFieldOf: any;
  noProductsLabel: any;
  constructor(props) {
    super(props);
    this.cmsDisableDragDrop = true;
    this.cta_text = props && props.cta_link ? props.cta_text : null;
    this.cta_link = props && props.cta_link ? props.cta_link : null;
    this.fleetTableDescription =
      props && props.fleetTableDescription ? props.fleetTableDescription : null;
    this.fleetTableFirstColumnHeader =
      props && props.fleetTableFirstColumnHeader
        ? props.fleetTableFirstColumnHeader
        : null;
    this.fleetTableSecondColumnHeader =
      props && props.fleetTableSecondColumnHeader
        ? props.fleetTableSecondColumnHeader
        : null;
    this.fleetTableThirdColumnHeader =
      props && props.fleetTableThirdColumnHeader
        ? props.fleetTableThirdColumnHeader
        : null;
    this.fleetTableFourthColumnHeader =
      props && props.fleetTableFourthColumnHeader
        ? props.fleetTableFourthColumnHeader
        : null;
    this.fleetTableFifthColumnHeader =
      props && props.fleetTableFifthColumnHeader
        ? props.fleetTableFifthColumnHeader
        : null;
    this.tablePaginationFieldPage =
      props && props.tablePaginationFieldPage
        ? props.tablePaginationFieldPage
        : null;
    this.tablePaginationFieldOf =
      props && props.tablePaginationFieldOf
        ? props.tablePaginationFieldOf
        : null;
    this.noProductsLabel =
      props && props.noProductsLabel ? props.noProductsLabel : null;
  }
  render() {
    return (
      <FleetTable
        cta_text={this.cta_text}
        cta_link={this.cta_link}
        fleetTableDescription={this.fleetTableDescription}
        fleetTableFirstColumnHeader={this.fleetTableFirstColumnHeader}
        fleetTableSecondColumnHeader={this.fleetTableSecondColumnHeader}
        fleetTableThirdColumnHeader={this.fleetTableThirdColumnHeader}
        fleetTableFourthColumnHeader={this.fleetTableFourthColumnHeader}
        fleetTableFifthColumnHeader={this.fleetTableFifthColumnHeader}
        tablePaginationFieldPage={this.tablePaginationFieldPage}
        tablePaginationFieldOf={this.tablePaginationFieldOf}
        noProductsLabel={this.noProductsLabel}
      />
    );
  }
}

const FleetTable = ({
  cta_text,
  cta_link,
  fleetTableDescription,
  fleetTableFirstColumnHeader,
  fleetTableSecondColumnHeader,
  fleetTableThirdColumnHeader,
  fleetTableFourthColumnHeader,
  fleetTableFifthColumnHeader,
  tablePaginationFieldPage,
  tablePaginationFieldOf,
  noProductsLabel,
}) => {
  const dispatch = useDispatch();

  const { user } = useContext(UserContext);
  const { fleetProduct, setFleetProduct } = useContext(UserContext);
  const { cards, loading, lang } = useSelector(
    (state: RootState) => ({
      cards: state.fleet.home.cards,
      loading: state.fleet.home.loading,
      lang: state.global.lang,
    }),
    shallowEqual
  );

  let cardsList = cards;
  if (!cardsList) cardsList = [];

  const callLoad = (): void => {
    dispatch(load(user.accessToken, window.localStorage.lang));
  };

  useEffect(() => {
    callLoad();
  }, [user]);
  const [state, setState] = useState({
    currentPage: 1,
    rowsPerPage: 10,
  });

  const indexOfLastRow = state.currentPage * state.rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - state.rowsPerPage;
  const currentRows = cards.slice(indexOfFirstRow, indexOfLastRow);
  const renderRows = currentRows.map((row, index) => {
    return (
      <tr key={index}>
        <th scope="row">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              console.log(row);
              setFleetProduct(row.id);
            }}
          >
            {row.serialNumber}
          </button>
        </th>
        <td>{row.brandName}</td>
        <td>{row.category}</td>
        <td>{row.model}</td>
        <td>{row.year}</td>
      </tr>
    );
  });

  const renderRowsMob = currentRows.map((row, index) => {
    return (
      <div className="mobile-table-card">
        <div className="mobile-table-card-row">
          <p className="mobile-table-card-row-header">
            {fleetTableFirstColumnHeader}
          </p>
          <button
            type="button"
            className="mobile-table-card-row-link"
            onClick={(e) => {
              e.preventDefault();
              setFleetProduct(row.id);
            }}
          >
            {row.serialNumber}
          </button>
        </div>
        <div className="mobile-table-card-row">
          <p className="mobile-table-card-row-header">
            {fleetTableSecondColumnHeader}
          </p>
          <p className="mobile-table-card-row-text">{row.brandName}</p>
        </div>
        <div className="mobile-table-card-row">
          <p className="mobile-table-card-row-header">
            {fleetTableThirdColumnHeader}
          </p>
          <p className="mobile-table-card-row-text">{row.category}</p>
        </div>
        <div className="mobile-table-card-row">
          <p className="mobile-table-card-row-header">
            {fleetTableFourthColumnHeader}
          </p>
          <p className="mobile-table-card-row-text">{row.model}</p>
        </div>
        <div className="mobile-table-card-row">
          <p className="mobile-table-card-row-header">
            {fleetTableFifthColumnHeader}
          </p>
          <p className="mobile-table-card-row-text">{row.year}</p>
        </div>
      </div>
    );
  });

  if (fleetProduct) return null;
  if (loading && !fleetProduct) return <div className="loader"></div>;
  return (
    <React.Fragment>
      <div className="custom-table d-none d-lg-block">
        <desc className="table-desc">{fleetTableDescription}</desc>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">{fleetTableFirstColumnHeader}</th>
              <th scope="col">{fleetTableSecondColumnHeader}</th>
              <th scope="col">{fleetTableThirdColumnHeader}</th>
              <th scope="col">{fleetTableFourthColumnHeader}</th>
              <th scope="col">{fleetTableFifthColumnHeader}</th>
            </tr>
          </thead>
          <tbody>
            {cards.length === 0 ? (
              <tr>
                <th>{noProductsLabel}</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              renderRows
            )}
          </tbody>
        </table>
      </div>
      <div className="mobile-table d-lg-none">
        {cards.length === 0 ? (
          <div className="mobile-table-card">{noProductsLabel}</div>
        ) : (
          renderRowsMob
        )}
      </div>

      <Pagination
        state={state}
        setState={setState}
        rows={cards}
        tablePaginationFieldPage={tablePaginationFieldPage}
        tablePaginationFieldOf={tablePaginationFieldOf}
      />
      <div className="basic-btn">
        <div className="btn-component-wrapper">
          <Link to={cta_link.value} className="btn-component">
            {cta_text}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};
