import * as ActionTypes from './actions';
import { FleetState, FleetAction } from './types';

const createInitialState = (): FleetState => ({
  cards: [],
  loading: false
});

export default function (
  state = createInitialState(),
  action: FleetAction
): FleetState {
  switch (action.type) {
    case ActionTypes.MY_FLEET_LOADING: {
      return { ...state, loading: true };
    }
    case ActionTypes.MY_FLEET_LOADED: {
      return { ...state, loading: false, cards: action.cards };
    }
    default: {
      return state;
    }
  }
}
