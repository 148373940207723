import { CacheSettings } from './types';

const env = global.runningEnvironment || 'dev';

export const getCache = (): CacheSettings => {
  const storedSettings = window.localStorage.getItem(env);
  let settings: CacheSettings = {
    cookieClosed: false,
    dealer: null,
    lang: null,
    site: null
  };

  if (storedSettings) {
    settings = JSON.parse(storedSettings) as CacheSettings;
  }

  return settings;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cache = (field: string, value: any): void => {
  const settings = getCache();

  settings[field] = value;
  localStorage.setItem(env, JSON.stringify(settings));
};
