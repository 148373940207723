import React from "react";
import { useRoutes } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import { Routes } from "react-router-dom";

export default function RenderRoutes({ routes }: any) {


   return <RenderRoutesApp renderRoutes={routes} />

}
const RenderRoutesApp = ({ renderRoutes }: any) => {
   let routes = useRoutes(renderRoutes);
   return routes;
};