import React from 'react'
import { CmsDynamicPage, CmsStaticPage } from 'crownpeak-dxm-react-sdk';
import DropZone from "../components/functional/dropZone";
import Routing from "../core/routing";
import SidebarTopHeader from '../components/global/sidebar-menu-header';
import SidebarTopImage from '../components/global/sidebar-menu-image';
import SidebarNavigation from '../components/global/sidebar-navigation';
import HeaderNavigation from '../components/global/header-navigation';
import FooterNavigation from '../components/global/footer-navigation';
import { Link, useLocation } from 'react-router-dom';
import ContactForm from '../components/section/contact-form';
import RedeemForm from '../components/section/redeem-form';

export default class KvgPageBuilder extends CmsStaticPage {
    state = {
        isLoaded: false,
        cmsAssetIdState: ''
    }

    constructor(props: any) {
        super(props);
        this.cmsSuppressFolder = true;
        this.cmsUseTmf = true;
        this.cmsWrapper = "Kverneland";
        this.cmsUseMetadata = true;
        this.cmsAssetId = Routing.getCmsAssetId(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState) {
        this.cmsAssetId = Routing.getCmsAssetId(window.location.pathname);

        if (prevState.isLoaded && prevState.cmsAssetIdState !== this.cmsAssetId) {
            this.setState({
                ...this.setState,
                isLoaded: false,
                cmsAssetIdState: this.cmsAssetId
            })
        }
    }

    render() {
        super.render();
        return (
            this.state.isLoaded &&
            <div className={window.localStorage.lang}>

                <header className="kvg-header header">
                    <HeaderNavigation dataProvider={this.cmsDataProvider} />
                </header>

                <main id="main-content" className="main-content" role="main" aria-label="main content">

                    <div className="cp-main-container kvg-wrapper">
                        <div className="container-fluid sidebar-wrapper">
                            <div className="row">
                                <div className="sidebar-wrapper-top-image">
                                    <SidebarTopImage dataProvider={this.cmsDataProvider} assetId={this.cmsAssetId} />
                                </div>
                                <div className="col-12 col-lg-3 d-none d-lg-block" id="sidebarCol">
                                    
                                </div>

                                <div className="col-12 col-lg-8" id="largeCardCol">
                                    <div className="sidebar-inner-content">
                                        <SidebarTopHeader dataProvider={this.cmsDataProvider} assetId={this.cmsAssetId} />
                                        <div className="large-card">
                                            <DropZone name="droppable" />
                                            <div className='grid-card-wrapper'>
                                                <DropZone name="DragDropZone" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>

                <div className="kvg-footer">
                    <FooterNavigation dataProvider={this.cmsDataProvider} />
                </div>

            </div>
        )
    }
}
