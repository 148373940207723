import AbstractService from './abstractservice';
import config from '../configuration';
import { Section, Site } from '../global-types';

export default class CommonWebService extends AbstractService {
  constructor() {
    super(config.runtime.urls.commonBaseUrl, 'application/json', undefined);
  }

  // getSites(appname: string): Promise<Site[]> {
  //   return this.get(`sites/${appname}`, undefined, undefined, undefined).then(
  //     (r) => r as unknown as Site[]
  //   );
  // }

  // getSection(code, lang): Promise<Section[]> {
  //   return this.get(
  //     `sections/${code}/${lang}`,
  //     undefined,
  //     undefined,
  //     undefined
  //   ).then((r) => r as unknown as Section[]);
  // }
}
