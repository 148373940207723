/* eslint-disable @typescript-eslint/no-explicit-any */
import * as ActionTypes from './actions';
import { GlobalState, GlobalAction } from './global-types';

const createInitialState = (): GlobalState => ({
  initiated: false,
  lang: 'EN',
  menu: [],
  settings: [],
  errorPage: false,
  location: null,
  cookieClosed: false,
  site: '',
  sites: [],
  footer: []
});

export default function (
  state = createInitialState(),
  action: GlobalAction
): GlobalState {
  switch (action.type) {
    case ActionTypes.COOKIE_CLOSED: {
      return { ...state, cookieClosed: true };
    }
    case ActionTypes.SET_SITE: {
      return { ...state, site: action.site, lang: action.lang };
    }
    case ActionTypes.ROUTE_CHANGED: {
      return { ...state, location: action.location };
    }
    case ActionTypes.APP_INITIATED: {
      return {
        ...state,
        site: action.site,
        initiated: true,
        // sites: action.sites,
        lang: action.lang,
        // menu: action.menu,
        // settings: action.settings,
        // footer: action.footer
      };
    }
    case ActionTypes.APP_FAILED: {
      return { ...state, errorPage: true, initiated: true };
    }
    default: {
      return state;
    }
  }
}
