import React from 'react';
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
 

export default class DetailsCardRow extends CmsComponent {
 
    constructor(props) {
        super(props);
        this.cmsFolder = "Card";
        this.cmsZones = ["droppable", "DragDropZone"];
        this.cmsDisableDragDrop = false;

     }

    render() {
        return (
            <div className="details-card-row">
                <p className="details-card-row-title">{this.props.title}</p>
                <p className="details-card-row-text">{this.props.text}</p>
            </div>
        )
    }
}
