import React from 'react';
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
import { setCard } from '../../pages/register/actions';

interface propTypes {
    name?: any,
    defaultText?: any,
    optionsText?: any,
    optionsList?: any
    onChange?: any
};

export default class FieldSelect extends React.Component<propTypes> {

    state: any;
    constructor(props: any) {
        super(props);
        // @showOptionList => Show / Hide List options
        this.state = {
            showOptionList: false
        };
    }
    componentDidMount() {
        this.setState({
        });
        // Add Event Listner to handle the click that happens outside
        // the Custom Select Container
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        // Remove the event listner on component unmounting
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    // This method handles the click that happens outside the
    // select text and list area
    handleClickOutside = e => {
        if (
            !e.target.classList.contains("custom-select-option") &&
            !e.target.classList.contains("selected-text")
        ) {
            this.setState({
                showOptionList: false
            });
        }
    };
    // This method handles the display of option list
    handleListDisplay = () => {
        this.setState((prevState: any) => {
            return {
                showOptionList: !prevState.showOptionList
            };
        });
    };

    // This method handles the setting of name in select text area
    // and list display on selection
    handleOptionClick = e => {
        
        this.setState({
            showOptionList: false
        });

        $(e.target).parents('.field-select').find('.selected-text').text(e.target.getAttribute("data-name"));
        this.props.onChange(e, e.target.id, this.props.name || "dropdown");
    };
    

    Object_byString = function(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

    render() {
        return (
            <div className="field field-select">
                <div className="custom-select-container">
                    <div
                        className={this.state.showOptionList ? "selected-text active" : "selected-text"}
                        onClick={this.handleListDisplay}>

                        {this.props.defaultText}
                    </div>
                    {this.state.showOptionList && (
                        <ul className="select-options">
                            
                            {this.props.optionsList.map((option: any, index: any) => {
                            
                                return (
                                    <li
                                        className="custom-select-option"
                                        data-name={this.props.optionsText? this.Object_byString(option, this.props.optionsText) : option.name? option.name : option.OptionsListItem?.name || ''}
                                        key={option.id? option.id : index}
                                        id={option.id? option.id : index}
                                        onClick={this.handleOptionClick}
                                    >
                                        {this.props.optionsText? this.Object_byString(option, this.props.optionsText) : option.name? option.name : option.OptionsListItem?.name || ''}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
                <a onClick={this.handleListDisplay}>
                    <i className="arrow down kvg-border"></i>
                </a>
            </div>
        )
    }
}
