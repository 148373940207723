import AbstractService from "./abstractservice";
import config from "../configuration";
import { ContactRequest, RequestHelpFormDto } from "../types";

export default class UserService extends AbstractService {
  constructor(bearerToken: string) {
    super(config.runtime.urls.apiBaseUrl, "application/json", bearerToken);
  }

  updateUser(): Promise<unknown> {
    return this.putNoResponse("user", undefined, undefined);
  }

  contactForm(data: ContactRequest): Promise<any> {
    return this.post("user/contact", undefined, data, undefined);
  }

  requestHelpForm(data): Promise<any> {
    return this.postfile("user/RegisterHelpForm", undefined, data, undefined);
  }
}
