import React from 'react';
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';

import { FleetTableClass } from '../../pages/fleet/home/fleet-controller';
import { FleetProductClass } from '../../pages/fleet/details/fleet-detail-controller';


export default class FleetCard extends CmsComponent {
    state: any;
    image: any;
    alt: any;
    header: any;
    description: any;
    cta_text: any;
    cta_link: any;
    uploadLabel: any;
    uploadAreaDesktopText: any;
    uploadAreaMobileText: any;
    uploadBtnText: any;
    uploadImage: any;
    uploadAlt: any;

    uploadSuccessAlertImg: any;
    uploadSuccessAlertImgAlt: any;
    uploadErrorAlertImg: any;
    uploadErrorAlertImgAlt: any;

    label: any;
    btnText: any;
    alertImg: any;
    alertText: any;
    detailsFirstHeader: any;
    detailsSecondHeader: any;
    unregisterBtn: any;
    modalHeader: any;
    modalText: any;
    cancelBtn: any;
    confirmBtn: any;
    firstHeader: any;
    secondHeader: any;
    tableHeader: any;
    ctaData: any;
    fleetTableDescription: any;
    fleetTableFirstColumnHeader: any;
    fleetTableSecondColumnHeader: any;
    fleetTableThirdColumnHeader: any;
    fleetTableFourthColumnHeader: any;
    fleetTableFifthColumnHeader: any;
    productCardSerialNumberLabel: any;
    productCardModelNameLabel: any;
    productCardBrandLabel: any;
    productCardCategoryLabel: any;

    warrantyCardStatusLabel: any;
    warrantyCardDeliveryDateLabel: any;
    warrantyCardExpLabourLabel: any;
    warrantyCardExpSpareLabel: any;
    warrantyCardInspectionLabel: any;
    warrantyEndDateLabel: any;

    documentsTableDescription: any;
    documentsTableFirstColumnHeader: any;
    documentsTableSecondColumnHeader: any;
    documentsTableThirdColumnHeader: any;
    documentsTableFourthColumnHeader: any;
    documentsTableFifthColumnHeader: any;
    tablePaginationFieldPage: any;
    tablePaginationFieldOf: any;
    backCtaText: any;
    noProductsLabel: any;

    constructor(props) {
        super(props);
        this.cmsFolder = "Section";
        this.cmsDisableDragDrop = false;
        this.cmsZones = ["droppable", "DragDropZone"];



        this.image = new CmsField("Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Image : null);
        this.alt = new CmsField("Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Alt : null);
        this.header = new CmsField("Fleet_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Fleet_Header : null);
        this.description = new CmsField("Description", CmsFieldTypes.TEXT, props && props.data ? props.data.Description : null);
        this.cta_text = new CmsField("CTA_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.CTA_Text : null);
        this.cta_link = new CmsField("CTA_Link", CmsFieldTypes.HREF, props && props.data ? props.data.CTA_Link : null);


        this.uploadLabel = new CmsField("Upload_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Label : null);
        this.uploadAreaDesktopText = new CmsField("Upload_Area_Desktop_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Area_Desktop_Text : null);
        this.uploadAreaMobileText = new CmsField("Upload_Area_Mobile_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Area_Mobile_Text : null);
        this.uploadBtnText = new CmsField("Upload_Button_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Button_Text : null);
        this.uploadImage = new CmsField("Upload_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Upload_Image : null);
        this.uploadAlt = new CmsField("Upload_Image_Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Image_Alt : null);

        this.uploadSuccessAlertImg = new CmsField("Upload_Success_Alert_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Upload_Success_Alert_Image : null);
        this.uploadSuccessAlertImgAlt = new CmsField("Upload_Success_Alert_Image_Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Success_Alert_Image_Alt : null);
        this.uploadErrorAlertImg = new CmsField("Upload_Error_Alert_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Upload_Error_Alert_Image : null);
        this.uploadErrorAlertImgAlt = new CmsField("Upload_Error_Alert_Image_Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Error_Alert_Image_Alt : null);


        this.detailsFirstHeader = new CmsField("Details_First_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Details_First_Header : null);
        this.detailsSecondHeader = new CmsField("Details_Second_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Details_Second_Header : null);
        this.unregisterBtn = new CmsField("Unregister_Button", CmsFieldTypes.TEXT, props && props.data ? props.data.Unregister_Button : null);
        this.modalHeader = new CmsField("Modal_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Modal_Header : null);
        this.modalText = new CmsField("Modal_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Modal_Text : null);
        this.cancelBtn = new CmsField("Cancel_Button", CmsFieldTypes.TEXT, props && props.data ? props.data.Cancel_Button : null);
        this.confirmBtn = new CmsField("Confirm_Button", CmsFieldTypes.TEXT, props && props.data ? props.data.Confirm_Button : null);

        this.tableHeader = new CmsField("Table_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Table_Header : null);


        
        this.fleetTableDescription = new CmsField("Fleet_Table_Description", CmsFieldTypes.TEXT, props && props.data ? props.data.Fleet_Table_Description : null);
        this.fleetTableFirstColumnHeader = new CmsField("Fleet_Table_First_Column_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Fleet_Table_First_Column_Header : null);
        this.fleetTableSecondColumnHeader = new CmsField("Fleet_Table_Second_Column_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Fleet_Table_Second_Column_Header : null);
        this.fleetTableThirdColumnHeader = new CmsField("Fleet_Table_Third_Column_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Fleet_Table_Third_Column_Header : null);
        this.fleetTableFourthColumnHeader = new CmsField("Fleet_Table_Fourth_Column_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Fleet_Table_Fourth_Column_Header : null);
        this.fleetTableFifthColumnHeader = new CmsField("Fleet_Table_Fifth_Column_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Fleet_Table_Fifth_Column_Header : null);

        this.productCardSerialNumberLabel = new CmsField("Product_Card_Serial_Number_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Product_Card_Serial_Number_Label : null);
        this.productCardModelNameLabel = new CmsField("Product_Card_Model_Name_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Product_Card_Model_Name_Label : null);
        this.productCardBrandLabel = new CmsField("Product_Card_Brand_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Product_Card_Brand_Label : null);
        this.productCardCategoryLabel = new CmsField("Product_Card_Category_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Product_Card_Category_Label : null);

        this.warrantyCardStatusLabel = new CmsField("Warranty_Card_Status_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Warranty_Card_Status_Label : null);
        this.warrantyCardDeliveryDateLabel = new CmsField("Warranty_Card_Delivery_Date_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Warranty_Card_Delivery_Date_Label : null);
        this.warrantyCardExpLabourLabel = new CmsField("Warranty_Card_Exp_Labour_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Warranty_Card_Exp_Labour_Label : null);
        this.warrantyCardExpSpareLabel = new CmsField("Warranty_Card_Exp_Spare_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Warranty_Card_Exp_Spare_Label : null);
        this.warrantyCardInspectionLabel = new CmsField("Warranty_Card_Inspection_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Warranty_Card_Inspection_Label : null);
        this.warrantyEndDateLabel = new CmsField("Warranty_EndDate_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Warranty_EndDate_Label : null);

        this.documentsTableDescription = new CmsField("Documents_Table_Description", CmsFieldTypes.TEXT, props && props.data ? props.data.Documents_Table_Description : null);
        this.documentsTableFirstColumnHeader = new CmsField("Documents_Table_First_Column_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Documents_Table_First_Column_Header : null);
        this.documentsTableSecondColumnHeader = new CmsField("Documents_Table_Second_Column_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Documents_Table_Second_Column_Header : null);
        this.documentsTableThirdColumnHeader = new CmsField("Documents_Table_Third_Column_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Documents_Table_Third_Column_Header : null);
        this.documentsTableFourthColumnHeader = new CmsField("Documents_Table_Fourth_Column_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Documents_Table_Fourth_Column_Header : null);
        this.documentsTableFifthColumnHeader = new CmsField("Documents_Table_Fifth_Column_Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Documents_Table_Fifth_Column_Header : null);

        this.tablePaginationFieldPage = new CmsField("Table_Pagination_Field_Page", CmsFieldTypes.TEXT, props && props.data ? props.data.Table_Pagination_Field_Page : null);
        this.tablePaginationFieldOf = new CmsField("Table_Pagination_Field_Of", CmsFieldTypes.TEXT, props && props.data ? props.data.Table_Pagination_Field_Of : null);
        this.backCtaText = new CmsField("Back_Cta_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Back_Cta_Text : null);

        this.noProductsLabel = new CmsField("No_Products_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.No_Products_Label : null);

    }


    render() {



        return (
            <div className="fleet">
                {/* cp-scaffold 
                <div className="large-card-main-content">
                    <div>
                        <img src="{Image:Src}" alt="{Alt:Text}" />
                    </div>
                    <div className="large-card-text">
                        <p className="large-card-text-header">{Fleet_Header:Text}</p>
                        <p className="large-card-text-desc">{Description:Text}</p>
                    </div>
                </div>
                    <desc>{Fleet_Table_Description}</desc>
                    <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">{Fleet_Table_First_Column_Header:Text}</th>
                            <th scope="col">{Fleet_Table_Second_Column_Header:Text}</th>
                            <th scope="col">{Fleet_Table_Third_Column_Header:Text}</th>
                            <th scope="col">{Fleet_Table_Fourth_Column_Header:Text}</th>
                            <th scope="col">{Fleet_Table_Fifth_Column_Header:Text}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                {No_Products_Label:Text}
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <button type="button" id="prevBtn">
                    <span className="pagination-arrow"><i className="arrow left"></i></span>
                </button>

                <p>{Table_Pagination_Field_Page:Text} {Table_Pagination_Field_Of:Text}</p>

                <button type="button" id="nextBtn">
                    <span className="pagination-arrow"><i className="arrow right"></i></span>
                </button>

                <div className='basic-btn'>
                    <div className="btn-component-wrapper">
                        <a href="{CTA_Link:Href}" className="btn-component">
                            {CTA_Text:Text}
                        </a>
                    </div>
                </div>

                
                <form className='form-wrapper'>
                                
                    <div className="field-upload">
                        <label className='field-upload-label'>{Upload_Label:Text}</label>
                        <div className='field-upload-wrapper'>
                            <label className='field-upload-btn d-lg-none' htmlFor='field-upload' id="field-upload-label">
                                {Upload_Area_Mobile_Text:Text}
                                <input type="file" id="field-upload" />
                            </label>

                            <label className='field-drop-btn d-none d-lg-flex' htmlFor='field-upload-dropzone' id="field-upload-dropzone-label">
                                <div>
                                    <img src="{Upload_Image:Src}" alt="{Upload_Image_Alt:Text}" />
                                </div>
                                {Upload_Area_Desktop_Text:Text}
                                <input type="file" id="field-upload-dropzone" draggable className="field-upload-dropzone" />
                            </label>
                            <div className="field field-submit">
                                <button type="submit" name="fieldsubmit">
                                  {Upload_Button_Text:Text}
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="alert-wrapper upload-alert-wrapper">
                        <div>
                            <img src="{Upload_Success_Alert_Image:Src}" alt="{Upload_Success_Alert_Image_Alt:Text}" />
                            <img src="{Upload_Error_Alert_Image:Src}" alt="{Upload_Error_Alert_Image_Alt:Text}" />
                        </div>
                    </div
                </form>
                
                 <div className="fleet-product">
                        <button className="back-btn"><i className="arrow left"></i>{Back_Cta_Text:Text}</button>
                        <div className="details-card-wrapper">

                            <div className="details-card">
                                <p className="details-card-header">{Details_First_Header:Text}</p>

                               <div className="details-card-row">
                                    <p className="details-card-row-title">{Product_Card_Serial_Number_Label:Text}</p>
                                    <p className="details-card-row-text"></p>
                                </div>
                               <div className="details-card-row">
                                    <p className="details-card-row-title">{Product_Card_Model_Name_Label:Text}</p>
                                    <p className="details-card-row-text"></p>
                                </div>
                                <div className="details-card-row">
                                    <p className="details-card-row-title">{Product_Card_Brand_Label:Text}</p>
                                    <p className="details-card-row-text"></p>
                                </div>
                                <div className="details-card-row">
                                    <p className="details-card-row-title">{Product_Card_Category_Label:Text}</p>
                                    <p className="details-card-row-text"></p>
                                </div>
                            </div>

                            <div className="details-card">
                                <p className="details-card-header">{Details_Second_Header:Text}</p>
                                 <div className="details-card-row">
                                    <p className="details-card-row-title">{Warranty_Card_Status_Label:Text}</p>
                                    <p className="details-card-row-text"></p>
                                </div>
                                <div className="details-card-row">
                                    <p className="details-card-row-title">{Warranty_Card_Delivery_Date_Label:Text}</p>
                                    <p className="details-card-row-text"></p>
                                </div>
                                <div className="details-card-row">
                                    <p className="details-card-row-title">{Warranty_Card_Exp_Labour_Label:Text}</p>
                                    <p className="details-card-row-text"></p>
                                </div>
                                <div className="details-card-row">
                                    <p className="details-card-row-title">{Warranty_Card_Exp_Spare_Label:Text}</p>
                                    <p className="details-card-row-text">}</p>
                                </div>
                                <div className="details-card-row">
                                    <p className="details-card-row-title">{Warranty_Card_Inspection_Label:Text}</p>
                                    <p className="details-card-row-text"></p>
                                </div>
                                <div className="details-card-row">
                                    <p className="details-card-row-title">{Warranty_EndDate_Label:Text}</p>
                                    <p className="details-card-row-text"></p>
                                </div>
                            </div>
                            <div>
                                <div className="basic-btn unregister-btn">
                                    <div className="btn-component-wrapper">
                                        <button type="button" className="btn-component" data-toggle="modal" data-target="#unregisterModal">
                                           {Unregister_Button:Text}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade unregister-modal" id="unregisterModal" tab-index="-1" role="dialog" aria-labelledby="unregisterModal" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <p className='unregister-modal-header'>{Modal_Header:Text}</p>
                                            <p className='unregister-modal-text'>{Modal_Text:Text}</p>
                                            <div className='unregister-modal-btn'>
                                                <div className='grey-btn'>
                                                    <div className="btn-component-wrapper">
                                                        <button type='button' className="btn-component" data-dismiss="modal" aria-label="Close">
                                                            {Cancel_Button:Text}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='basic-btn'>
                                                    <div className="btn-component-wrapper">
                                                        <button type='button' className="btn-component">
                                                            {Confirm_Button:Text}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className='table-header'>{Table_Header:Text}</p>
                    <div className="custom-table">
                        <desc>{Documents_Table_Description}</desc>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">{Documents_Table_First_Column_Header:Text}</th>
                                    <th scope="col">{Documents_Table_Second_Column_Header:Text}</th>
                                    <th scope="col">{Documents_Table_Third_Column_Header:Text}</th>
                                    <th scope="col">{Documents_Table_Fourth_Column_Header:Text}</th>
                                    <th scope="col">{Documents_Table_Fifth_Column_Header:Text}</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>

                    </div>
            
                  else */}
                <div className="large-card-main-content">
                    <div>
                        <img src={this.image} alt={this.alt} />
                    </div>
                    <div className="large-card-text">
                        <p className="large-card-text-header">{this.header}</p>
                        <p className="large-card-text-desc">{this.description}</p>
                    </div>
                </div>




                <FleetTableClass
                    cta_text={this.cta_text}
                    cta_link={this.cta_link}
                    fleetTableDescription = {this.fleetTableDescription}
                    fleetTableFirstColumnHeader={this.fleetTableFirstColumnHeader}
                    fleetTableSecondColumnHeader={this.fleetTableSecondColumnHeader}
                    fleetTableThirdColumnHeader={this.fleetTableThirdColumnHeader}
                    fleetTableFourthColumnHeader={this.fleetTableFourthColumnHeader}
                    fleetTableFifthColumnHeader={this.fleetTableFifthColumnHeader}
                    tablePaginationFieldPage={this.tablePaginationFieldPage}
                    tablePaginationFieldOf={this.tablePaginationFieldOf}
                    noProductsLabel={this.noProductsLabel}
                />






                <FleetProductClass
                    detailsFirstHeader={this.detailsFirstHeader}
                    detailsSecondHeader={this.detailsSecondHeader}
                    unregisterBtn={this.unregisterBtn}
                    modalHeader={this.modalHeader}
                    modalText={this.modalText}
                    cancelBtn={this.cancelBtn}
                    confirmBtn={this.confirmBtn}
                    uploadLabel={this.uploadLabel}
                    uploadImage={this.uploadImage}
                    uploadAlt={this.uploadAlt}
                    uploadBtnText={this.uploadBtnText}

                    uploadSuccessAlertImg={this.uploadSuccessAlertImg}
                    uploadSuccessAlertImgAlt={this.uploadSuccessAlertImgAlt}
                    uploadErrorAlertImg={this.uploadErrorAlertImg}
                    uploadErrorAlertImgAlt={this.uploadErrorAlertImgAlt}

                    tableHeader={this.tableHeader}
                    uploadAreaDesktopText={this.uploadAreaDesktopText}
                    uploadAreaMobileText={this.uploadAreaMobileText}

                    productCardSerialNumberLabel={this.productCardSerialNumberLabel}
                    productCardModelNameLabel={this.productCardModelNameLabel}
                    productCardBrandLabel={this.productCardBrandLabel}
                    productCardCategoryLabel={this.productCardCategoryLabel}

                    warrantyCardStatusLabel={this.warrantyCardStatusLabel}
                    warrantyCardDeliveryDateLabel={this.warrantyCardDeliveryDateLabel}
                    warrantyCardExpLabourLabel={this.warrantyCardExpLabourLabel}
                    warrantyCardExpSpareLabel={this.warrantyCardExpSpareLabel}
                    warrantyCardInspectionLabel={this.warrantyCardInspectionLabel}
                    warrantyEndDateLabel = {this.warrantyEndDateLabel}

                    documentsTableDescription = {this.documentsTableDescription}
                    documentsTableFirstColumnHeader={this.documentsTableFirstColumnHeader}
                    documentsTableSecondColumnHeader={this.documentsTableSecondColumnHeader}
                    documentsTableThirdColumnHeader={this.documentsTableThirdColumnHeader}
                    documentsTableFourthColumnHeader={this.documentsTableFourthColumnHeader}
                    documentsTableFifthColumnHeader={this.documentsTableFifthColumnHeader}

                    tablePaginationFieldPage={this.tablePaginationFieldPage}
                    tablePaginationFieldOf={this.tablePaginationFieldOf}

                    backCtaText={this.backCtaText}
                />

                {/* /cp-scaffold */}
            </div>
        )
    }
}
