import { History } from "history";
import { FleetDetailAction } from "./types";
import { RegisterService, CardService } from "../../../services";
import { AppThunk } from "../../../controllers/store";

export const PRODUCT_LOADED = "PRODUCT_LOADED";
export const PRODUCT_LOADING = "PRODUCT_LOADING";
export const PRODUCT_LOAD_FAILED = "PRODUCT_LOAD_FAILED";
export const PRODUCT_FILE_SET = "PRODUCT_FILE_SET";
export const PRODUCT_FILE_SUBMIT = "PRODUCT_FILE_SUBMIT";
export const PRODUCT_FILE_SUBMITTED = "PRODUCT_FILE_SUBMITTED";
export const PRODUCT_UNREGISTERED = "PRODUCT_UNREGISTERED";
export const PRODUCT_HELP_FILES = "PRODUCT_HELP_FILES";

export const load = (
  bearerToken: string,
  id: string,
  lang: string
): AppThunk<Promise<void>> => {
  return async (dispatch): Promise<void> => {
    dispatch({ type: PRODUCT_LOADING });
    try {
      const cardService = new CardService(bearerToken);
      const product = await cardService.getCard(id, lang);
      dispatch({ type: PRODUCT_LOADED, product });
    } catch (err: any) {
      dispatch({ type: PRODUCT_LOAD_FAILED, err: err.data.serverError });
    }
  };
};

export const setFile = (file: File): FleetDetailAction => {
  return { type: PRODUCT_FILE_SET, file };
};

export const submitFile = (
  bearerToken: string,
  id: string
): AppThunk<Promise<void>> => {
  return async (dispatch, getState): Promise<void> => {
    dispatch({ type: PRODUCT_FILE_SUBMIT });
    const { file } = getState().fleet.details;
    try {
      const cardService = new CardService(bearerToken);
      const result = await cardService.updateCardFile(id, file);
      dispatch({ type: PRODUCT_FILE_SUBMITTED, result });
    } catch (err: any) {
      dispatch({ type: PRODUCT_FILE_SUBMITTED, result: err.data.serverError });
    }
  };
};

export const unregister = (
  bearerToken: string,
  id: string
  // close: () => void,
  // history: History
): AppThunk<Promise<void>> => {
  return async (dispatch): Promise<void> => {
    const registerService = new RegisterService(bearerToken);

    await registerService.unregister(id);
    dispatch({ type: PRODUCT_UNREGISTERED, id });
    // close();
    // history.push('/fleet');
  };
};
